var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.projectType
    ? _c(
        "div",
        [
          _c(
            "page-header",
            {
              staticClass: "pb-6",
              attrs: {
                "bread-crumbs": [
                  { to: "/home", label: "Home" },
                  { label: "Project management" },
                  { to: "/projects/settings", label: "Project types" }
                ]
              }
            },
            [_vm._v(" " + _vm._s(_vm.projectType.name) + " ")]
          ),
          _c(
            "div",
            { staticClass: "row-format mb-4 gap-3 flex-wrap" },
            [
              _vm._l(_vm.pages, function(page) {
                return _c(
                  "div",
                  {
                    key: page.value,
                    class:
                      "page-link " +
                      (_vm.currentView === page.value
                        ? "page-link-active"
                        : ""),
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.setView(page.value)
                      }
                    }
                  },
                  [_c("div", [_vm._v(_vm._s(page.label))])]
                )
              }),
              _vm.currentView !== "overview" &&
              _vm.currentView !== "client notifications"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-auto super-action",
                      on: {
                        click: function($event) {
                          return _vm.addNew()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { size: "20" } }, [_vm._v("add")]),
                      _vm._v(" Add " + _vm._s(_vm.currentView.toLowerCase()))
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "div",
            [
              _vm.currentView === "overview"
                ? _c(
                    "div",
                    { staticClass: "column-format gap-1 text-left font-14" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          "persistent-placeholder": "",
                          label: "Name",
                          outlined: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.saveProjectType()
                          }
                        },
                        model: {
                          value: _vm.projectType.name,
                          callback: function($$v) {
                            _vm.$set(_vm.projectType, "name", $$v)
                          },
                          expression: "projectType.name"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentView === "status"
                ? _c("deliverable-status", {
                    ref: "DeliverableStatus",
                    on: {
                      input: function($event) {
                        return _vm.saveProjectType()
                      }
                    },
                    model: {
                      value: _vm.projectType.statusList,
                      callback: function($$v) {
                        _vm.$set(_vm.projectType, "statusList", $$v)
                      },
                      expression: "projectType.statusList"
                    }
                  })
                : _vm._e(),
              _vm.currentView === "project field"
                ? _c("custom-field-table", {
                    ref: "ProjectFields",
                    attrs: { "max-per-card": 0 },
                    on: {
                      input: function($event) {
                        return _vm.saveProjectType()
                      }
                    },
                    model: {
                      value: _vm.projectType.projectFields,
                      callback: function($$v) {
                        _vm.$set(_vm.projectType, "projectFields", $$v)
                      },
                      expression: "projectType.projectFields"
                    }
                  })
                : _vm._e(),
              _vm.currentView === "task field"
                ? _c("custom-field-table", {
                    ref: "TaskFields",
                    attrs: { "max-per-card": 0 },
                    on: {
                      input: function($event) {
                        return _vm.saveProjectType()
                      }
                    },
                    model: {
                      value: _vm.projectType.deliverableFields,
                      callback: function($$v) {
                        _vm.$set(_vm.projectType, "deliverableFields", $$v)
                      },
                      expression: "projectType.deliverableFields"
                    }
                  })
                : _vm._e(),
              _vm.currentView === "client notifications"
                ? _c(
                    "div",
                    { staticClass: "column-format gap-3" },
                    [
                      _c("div", { staticClass: "text-left mt-5" }, [
                        _vm._v("Client notification email templates")
                      ]),
                      _vm.emailTemplates
                        ? _c("v-autocomplete", {
                            attrs: {
                              disabled: _vm.emailTemplates.length === 0,
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              label: "Task approval required",
                              items: _vm.emailTemplates,
                              "item-value": "id",
                              "item-text": "name",
                              clearable: ""
                            },
                            on: { change: _vm.saveProjectType },
                            model: {
                              value:
                                _vm.projectType.notifications
                                  .deliverableApproval,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.projectType.notifications,
                                  "deliverableApproval",
                                  $$v
                                )
                              },
                              expression:
                                "projectType.notifications.deliverableApproval"
                            }
                          })
                        : _vm._e(),
                      _vm.emailTemplates
                        ? _c("v-autocomplete", {
                            attrs: {
                              disabled: _vm.emailTemplates.length === 0,
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              label: "Task approval reminder",
                              items: _vm.emailTemplates,
                              "item-value": "id",
                              "item-text": "name",
                              clearable: ""
                            },
                            on: { change: _vm.saveProjectType },
                            model: {
                              value:
                                _vm.projectType.notifications
                                  .deliverableApprovalReminder,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.projectType.notifications,
                                  "deliverableApprovalReminder",
                                  $$v
                                )
                              },
                              expression:
                                "projectType.notifications.deliverableApprovalReminder"
                            }
                          })
                        : _vm._e(),
                      _vm.emailTemplates
                        ? _c("v-autocomplete", {
                            attrs: {
                              disabled: _vm.emailTemplates.length === 0,
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              label: "Task assigned",
                              items: _vm.emailTemplates,
                              "item-value": "id",
                              "item-text": "name",
                              clearable: ""
                            },
                            on: { change: _vm.saveProjectType },
                            model: {
                              value:
                                _vm.projectType.notifications
                                  .deliverableAssigned,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.projectType.notifications,
                                  "deliverableAssigned",
                                  $$v
                                )
                              },
                              expression:
                                "projectType.notifications.deliverableAssigned"
                            }
                          })
                        : _vm._e(),
                      _vm.emailTemplates
                        ? _c("v-autocomplete", {
                            attrs: {
                              disabled: _vm.emailTemplates.length === 0,
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              label: "Task comment",
                              items: _vm.emailTemplates,
                              "item-value": "id",
                              "item-text": "name",
                              clearable: ""
                            },
                            on: { change: _vm.saveProjectType },
                            model: {
                              value:
                                _vm.projectType.notifications
                                  .deliverableComment,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.projectType.notifications,
                                  "deliverableComment",
                                  $$v
                                )
                              },
                              expression:
                                "projectType.notifications.deliverableComment"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }