var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "nudge-bottom": 34,
            "close-on-content-click": false,
            "content-class": "filter-menu-class",
            transition: "slide-y-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "standard-action",
                        attrs: { elevation: "0", outlined: "", color: "" }
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          class:
                            "font-14 " +
                            (_vm.isFilterActive ? "f" : "font-gray_60") +
                            " brand-medium"
                        },
                        [_vm._v(_vm._s(_vm.filterLabel))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: _vm.isFilterActive ? "" : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "column-format gap-4 pa-3",
              staticStyle: { "min-width": "550px", "max-width": "550px" }
            },
            [
              _vm._l(_vm.filters, function(filter) {
                return _c(
                  "div",
                  {
                    key: filter.id,
                    staticClass: "row-format gap-2 align-center"
                  },
                  [
                    _c("v-autocomplete", {
                      staticStyle: {
                        "min-width": "140px",
                        "max-width": "140px"
                      },
                      attrs: {
                        items: _vm.fields,
                        "item-value": "mappingKey",
                        "item-text": "name",
                        "hide-details": "",
                        dense: ""
                      },
                      model: {
                        value: filter.mappingKey,
                        callback: function($$v) {
                          _vm.$set(filter, "mappingKey", $$v)
                        },
                        expression: "filter.mappingKey"
                      }
                    }),
                    _c("v-select", {
                      staticStyle: {
                        "min-width": "120px",
                        "max-width": "120px"
                      },
                      attrs: {
                        items: _vm.conditions,
                        "hide-details": "",
                        dense: ""
                      },
                      model: {
                        value: filter.condition,
                        callback: function($$v) {
                          _vm.$set(filter, "condition", $$v)
                        },
                        expression: "filter.condition"
                      }
                    }),
                    _c("v-text-field", {
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        disabled:
                          filter.condition === "Is set" ||
                          filter.condition === "Set"
                      },
                      model: {
                        value: filter.value,
                        callback: function($$v) {
                          _vm.$set(filter, "value", $$v)
                        },
                        expression: "filter.value"
                      }
                    }),
                    _c(
                      "v-icon",
                      {
                        staticClass: "material-symbols-rounded",
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            return _vm.deleteFilter(filter)
                          }
                        }
                      },
                      [_vm._v("delete")]
                    )
                  ],
                  1
                )
              }),
              _c(
                "div",
                {
                  staticClass: "row-format gap-4",
                  staticStyle: { "align-items": "end" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pointer brand-medium font-primary font-14",
                      on: {
                        click: function($event) {
                          return _vm.addFilter()
                        }
                      }
                    },
                    [_vm._v("+ Add filter")]
                  ),
                  _vm.filters.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "pointer brand-medium font-14",
                          on: {
                            click: function($event) {
                              return _vm.clearFilters()
                            }
                          }
                        },
                        [_vm._v("Clear filters")]
                      )
                    : _vm._e(),
                  _vm.filters.length > 1
                    ? _c("v-select", {
                        staticClass: "ml-auto mr-5",
                        staticStyle: {
                          "max-width": "75px",
                          "min-width": "75px"
                        },
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          items: ["And", "Or"]
                        },
                        model: {
                          value: _vm.operator,
                          callback: function($$v) {
                            _vm.operator = $$v
                          },
                          expression: "operator"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }