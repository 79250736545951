<template>
	<div class="panel-modal" style="width: 600px">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click.stop="$emit('result')">$chevronRight</v-icon>
				<div class="brand-medium font-18">Time worked</div>
				<div class="ml-auto row-format align-center gap-1">
					<pipeline-link-widget
						v-if="timerEventClone"
						:item-id="timerEventClone.id"
						item-type="TimerEvent"
						:client-id="timerEventClone.clientId"
					></pipeline-link-widget>
					<v-btn class="ml-2 super-action" @click="handleSave" :disabled="!isTimeRangeValid" v-if="!disabled">
						{{ $t('global.save') }}
					</v-btn>
					<v-btn @click="deleteTimeEntryDialog = true" icon v-if="!disabled">
						<v-icon size="20" class="material-symbols-rounded">delete</v-icon>
					</v-btn>
					<v-icon v-if="disabled">lock</v-icon>
				</div>
			</div>
		</div>

		<div class="panel-modal-body py-2 show-scrollbar">
			<div class="ma-4">
				<v-container v-if="timerEventClone" fluid class="ma-0 pa-0">
					<v-row dense>
						<v-col cols="12" align="left">
							<div class="brand-bold mb-4">Overview</div>
						</v-col>
					</v-row>
					<v-row align="center" class="pt-1" dense>
						<v-col cols="4" align="left" class="font-14 font-gray_70 row-format align-center">
							<v-icon size="20">event</v-icon>
							<div class="ml-2">Date</div>
						</v-col>
						<v-col cols="8" align="left">
							<date-selector
								class="date-selector"
								:micro="true"
								:show-year="true"
								micro-font="font-14"
								:date="startDate"
								label="--"
								:disabled="disabled"
								@change="startDateUpdated($event)"
							></date-selector>
						</v-col>
					</v-row>
					<v-row align="center" dense>
						<v-col cols="4" align="left" class="font-14 font-gray_70 row-format align-center">
							<v-icon size="20">schedule</v-icon>
							<div class="ml-2">Start</div>
						</v-col>
						<v-col cols="8" align="left">
							<vue-timepicker
								style="font-size: 14px"
								v-model="timeStartString"
								:format="format"
								:disabled="disabled"
								hide-clear-button
								auto-scroll
								manual-input
								hide-dropdown
								@change="startTimeUpdated"
							></vue-timepicker>
						</v-col>
					</v-row>
					<v-row align="center" dense>
						<v-col cols="4" align="left" class="font-14 font-gray_70 row-format align-center">
							<v-icon size="20">schedule</v-icon>
							<div class="ml-2">End</div>
						</v-col>
						<v-col cols="8" align="left" class="row-format align-center">
							<vue-timepicker
								style="font-size: 14px"
								v-model="timeEndString"
								:format="format"
								:disabled="disabled"
								:hide-clear-button="true"
								auto-scroll
								:manual-input="true"
								:hide-dropdown="true"
								drop-direction="up"
								@change="endTimeUpdated"
							></vue-timepicker>
							<div
								v-if="timerEventClone.pausedSeconds"
								class="text-left font-12 font-gray_70 mx-3 row-format align-center pointer"
								@click="confirmClearPause"
							>
								<v-icon size="16" class="material-symbols-rounded">pause</v-icon> {{ pausedTimeFormatted }}
								<v-icon size="16" class="material-symbols-rounded">clear</v-icon>
							</div>
						</v-col>
					</v-row>
					<v-row align="center" class="pt-1" dense>
						<v-col cols="4" align="left" class="font-14 font-gray_70 row-format align-center">
							<v-icon size="20">watch</v-icon>
							<div class="ml-2">Duration</div>
						</v-col>
						<v-col cols="8" align="left">
							<input
								maxlength="6"
								id="duration"
								:disabled="disabled"
								:style="
									`${
										!isTimeRangeValid ? 'color:var(--v-error-base)' : 'color: var(--v-black-base)'
									}; width:60px; font-weight: 600`
								"
								type="text"
								class="font-14 duration-input"
								@change="durationUpdated"
								v-model="durationString"
							/>
							<v-icon v-if="!isTimeRangeValid" color="warning" class="mt-1">$alert</v-icon>
						</v-col>
					</v-row>
					<v-row align="center" class="pt-1" dense>
						<v-col cols="4" align="left" class="font-14 font-gray_70 row-format align-center">
							<v-icon size="20">person</v-icon>
							<div class="ml-2">User</div>
						</v-col>
						<v-col cols="8" align="left">
							<v-menu
								:close-on-click="true"
								:close-on-content-click="true"
								offset-y
								v-model="userMenu"
								:disabled="disabled"
							>
								<template v-slot:activator="{ on }">
									<div v-on="on" class="pointer ml-2 font-14" style="font-weight: 600">
										{{ assignedUser.firstName }} {{ assignedUser.lastName }}
									</div>
								</template>
								<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
									<div
										v-for="user in userList"
										:key="user.userId"
										class="row-format align-center text-left py-1 pointer"
									>
										<assigned-user
											@click="
												timerEventClone.userId = user.userId;
												userMenu = false;
											"
											class="mx-2"
											v-if="user.userId"
											:assigned-to="user.userId"
											:show-name="true"
										></assigned-user>
									</div>
								</div>
							</v-menu>
						</v-col>
					</v-row>
					<v-row align="center" class="pt-1" dense>
						<v-col cols="4" align="left" class="font-14 font-gray_70 row-format align-center">
							<v-icon size="20">money</v-icon>
							<div class="ml-2">Billable</div>
						</v-col>
						<v-col cols="8" align="left">
							<v-switch
								v-model="timerEventClone.billable"
								hide-details
								dense
								label=""
								color="primary"
								class="mx-2 mt-0"
								:disabled="disabled"
							></v-switch>
						</v-col>
					</v-row>
					<v-row align="center" class="pt-1" dense v-if="disabled && timerEventClone.invoiceId">
						<v-col cols="4" align="left" class="font-14 font-gray_70 row-format align-center pt-2">
							<v-icon size="20">money</v-icon>
							<div class="ml-2">Invoiced</div>
						</v-col>
						<v-col cols="8" align="left" class="font-14 row-format align-center pt-2">
							<div class="brand-bold font-primary pl-2 pointer" @click="openInvoice()">
								{{ timerEventClone.invoiceNumber }}
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" align="left" class="pl-3">
							<div class="brand-bold mt-6 mb-4">Client info</div>
						</v-col>
					</v-row>
					<v-row align="center" class="pt-1" dense>
						<v-col cols="4" align="left" class="font-14 font-gray_70 row-format align-center">
							<v-icon size="20">work_outline</v-icon>
							<div class="ml-2">Client</div>
						</v-col>
						<v-col cols="8" align="left">
							<v-menu
								:close-on-click="true"
								:close-on-content-click="false"
								offset-y
								v-model="clientMenu"
								:disabled="disabled"
							>
								<template v-slot:activator="{ on }">
									<div v-on="on" class="pointer pl-2 font-14">
										<div v-if="selectedClient" class="row-format align-center">
											<client-avatar :client="selectedClient" x-small></client-avatar>
											<div class="ml-2">{{ selectedClient.name }}</div>
										</div>
										<div v-else>--</div>
									</div>
								</template>
								<div
									class="text-left column-format pt-1 show-scrollbar"
									style="
										background-color: var(--v-white-base);
										overflow-y: scroll;
										max-height: 400px;
										min-width: 300px;
									"
								>
									<div class="pa-2">
										<v-text-field
											hide-details
											dense
											placeholder="Filter..."
											v-model="clientFilter"
										></v-text-field>
									</div>

									<div
										@click="setClientId(client)"
										v-for="client in filteredClients"
										:key="client.id"
										class="row-format align-center px-3 py-2 pointer hover-gray"
										style="border-bottom: 1px solid var(--v-gray_30-base)"
									>
										<client-avatar :client="client" x-small v-if="client.id"></client-avatar>
										<div :class="`ml-2 brand-medium ${client.archive ? 'font-gray_70' : ''}`">
											{{ client.name }}
										</div>
									</div>
								</div>
							</v-menu>
						</v-col>
					</v-row>
					<v-row align="center" class="pt-1" dense v-if="showClientProjectSelect">
						<v-col cols="4" align="left" class="font-14 font-gray_70 row-format align-center">
							<v-icon size="20">folder</v-icon>
							<div class="ml-2">Project</div>
						</v-col>
						<v-col cols="8" align="left">
							<v-menu
								:close-on-click="true"
								:close-on-content-click="true"
								offset-y
								max-height="400"
								v-model="projectMenu"
								:disabled="disabled"
							>
								<template v-slot:activator="{ on }">
									<div v-on="on" class="pointer pl-2 font-14">
										<div v-if="projectId" class="row-format align-center">
											{{ projectName ? projectName : '[blank]' }}
										</div>
										<div v-else>--</div>
									</div>
								</template>
								<div
									class="text-left column-format pt-1"
									style="background-color: var(--v-white-base); overflow-y: scroll"
								>
									<div
										@click="setProjectId(project)"
										v-for="project in projectItems"
										:key="project.value"
										class="row-format align-center px-3 py-2 pointer hover-gray"
										style="border-bottom: 1px solid var(--v-gray_30-base)"
									>
										<div :class="`ml-2 brand-medium`">{{ project.text ? project.text : '[blank]' }}</div>
									</div>
									<div v-if="!projectItems.length" class="brand-medium font-gray_70 pa-2">[No projects]</div>
								</div>
							</v-menu>
						</v-col>
					</v-row>
					<v-row align="center" class="pt-1" dense v-if="showClientProjectSelect">
						<v-col cols="4" align="left" class="font-14 font-gray_70 row-format align-center">
							<v-icon size="20">inventory</v-icon>
							<div class="ml-2">Task</div>
						</v-col>
						<v-col cols="8" align="left">
							<v-menu
								:close-on-click="true"
								:close-on-content-click="true"
								offset-y
								max-height="400"
								v-model="deliverableMenu"
								:disabled="disabled"
							>
								<template v-slot:activator="{ on }">
									<div v-on="on" class="pointer pl-2 font-14">
										<div v-if="deliverableId" class="row-format align-center">
											{{ deliverableName ? deliverableName : '[blank]' }}
										</div>
										<div v-else>--</div>
									</div>
								</template>
								<div
									class="text-left column-format pt-1"
									style="background-color: var(--v-white-base); overflow-y: scroll"
								>
									<div
										@click="setDeliverableId(deliverable)"
										v-for="deliverable in deliverableItems"
										:key="deliverable.value"
										class="row-format align-center px-3 py-2 pointer hover-gray"
										style="border-bottom: 1px solid var(--v-gray_30-base)"
									>
										<div :class="`ml-2 brand-medium`">
											{{ deliverable.text ? deliverable.text : '[blank]' }}
										</div>
									</div>
									<div v-if="!deliverableItems.length" class="brand-medium font-gray_70 pa-2">
										[No deliverables]
									</div>
								</div>
							</v-menu>
						</v-col>
					</v-row>
					<v-row align="center" class="pt-1" dense v-if="showTicketSelect">
						<v-col cols="4" align="left" class="font-14 font-gray_70 row-format align-center">
							<v-icon size="20">local_activity</v-icon>
							<div class="ml-2">Ticket</div>
						</v-col>
						<v-col cols="8" align="left">
							<v-menu
								:close-on-click="true"
								:close-on-content-click="true"
								offset-y
								max-height="400"
								v-model="ticketMenu"
								:disabled="disabled"
							>
								<template v-slot:activator="{ on }">
									<div v-on="on" class="pointer pl-2 font-14">
										<div v-if="ticketId" class="row-format align-center">
											{{ ticketName ? ticketName : '[blank]' }}
										</div>
										<div v-else>--</div>
									</div>
								</template>
								<div
									class="text-left column-format pt-1"
									style="background-color: var(--v-white-base); overflow-y: scroll"
								>
									<div
										@click="setTicketId(ticket)"
										v-for="ticket in ticketItems"
										:key="ticket.value"
										class="row-format align-center px-3 py-2 pointer hover-gray"
										style="border-bottom: 1px solid var(--v-gray_30-base)"
									>
										<div :class="`ml-2 brand-medium`">{{ ticket.text ? ticket.text : '[blank]' }}</div>
									</div>
									<div v-if="!ticketItems.length" class="brand-medium font-gray_70 pa-2">[No open tickets]</div>
								</div>
							</v-menu>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" align="left" class="pl-3">
							<div class="brand-bold mt-6 mb-2">Notes</div>
						</v-col>
						<v-col cols="12">
							<v-textarea
								auto-grow
								hide-details
								persistent-placeholder
								v-model="timerEventClone.notes"
								:rows="1"
								:placeholder="$t('timetrack.current.notes-placeholder')"
								@keyup.enter.exact="handleSave"
								:disabled="disabled"
							/>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>

		<confirm-dialog
			:dialog="deleteTimeEntryDialog"
			heading-text="Delete?"
			:body-text="$t('timetrack.time-entry-delete-confirm')"
			@confirm="handleDelete"
			@close="deleteTimeEntryDialog = false"
		></confirm-dialog>
	</div>
</template>

<script>
	import TimeTrackService from './TimeTrackService';
	import ProjectService from '@/modules/projects/ProjectService';
	import DeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';
	import { cloneDeep, isEqual } from 'lodash';
	import TimeTrack from './TimeTrack';
	import AssignedUser from '@/components/AssignedUser';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import VueTimepicker from 'vue2-timepicker';
	import DateTime from '@/modules/utils/HDateTime';
	import 'vue2-timepicker/dist/VueTimepicker.css';
	import HDateTime from '@/modules/utils/HDateTime';
	import DateSelector from '@/components/DateSelector';
	import ClientAvatar from '@/components/ClientAvatar';
	import InvoiceDetails from '@/modules/invoices/InvoiceDetails';
	import { Duration } from 'luxon';
	import ConfirmModal from '@/components/ConfirmModal';
	import CommunicatorTicketService from '@/modules/communicator/CommunicatorTicketService';

	export default {
		name: 'TimeTrackEdit',
		components: {
			ConfirmDialog,
			AssignedUser,
			VueTimepicker,
			DateSelector,
			ClientAvatar,
			PipelineLinkWidget: () => import('@/modules/pipeline/PipelineLinkWidget'),
		},
		isRightModal: true,
		props: {
			timerEvent: { type: Object, required: false },
			maxWidth: { type: Number, required: false, default: null },
			client: { type: Object, required: false },
		},

		data() {
			return {
				test: null,
				timeTrackService: new TimeTrackService(),
				projectService: new ProjectService(),
				deliverableService: new DeliverableService(),
				ticketService: new CommunicatorTicketService(),
				timeTrack: new TimeTrack(),
				projectList: [],
				deliverableList: [],
				ticketList: [],
				running: false,
				disabled: !!(this.timerEvent && this.timerEvent.invoiceId),
				timerEventClone: null, // Deep clone of timerEvent for storing IDs and notes fields
				deleteTimeEntryDialog: false,
				timerStart: null,
				timerEnd: null,
				userMenu: false,

				clientId: null,
				projectId: null,
				deliverableId: null,
				ticketId: null,

				projectName: null,
				deliverableName: null,
				ticketName: null,

				timeStartString: null,
				timeEndString: null,
				durationString: null,
				startDate: null,
				refreshKey: 0,
				is12HourFormat: false,

				clientMenu: false,
				projectMenu: false,
				deliverableMenu: false,
				ticketMenu: false,
				clientFilter: null,
				projectFilter: null,
				deliverableFilter: null,
				ticketFilter: null,
			};
		},

		mounted() {
			this.getProjectList();
			this.getDeliverableList();
			this.getTicketList();

			// Setup inputs
			if (this.timerEvent) {
				this.timerEventClone = cloneDeep(this.timerEvent);
			} else {
				this.initTimerEventClone();
			}

			if (!Object.prototype.hasOwnProperty.call(this.timerEventClone, 'billable')) {
				this.timerEventClone.billable = true;
			}

			if (!Object.prototype.hasOwnProperty.call(this.timerEventClone, 'pausedSeconds')) {
				this.timerEventClone.pausedSeconds = 0;
			}

			this.setupData();

			if (this.timeStartString.includes('AM') || this.timeStartString.includes('PM')) {
				this.is12HourFormat = true;
			}

			this.$nextTick(() => {
				document.querySelector('#duration').onkeypress = function(e) {
					return '0123456789:'.indexOf(String.fromCharCode(e.which)) >= 0;
				};
			});
		},

		methods: {
			setupData: function() {
				this.timerStart = DateTime.fromISO(this.timerEventClone.timerStart);
				this.timerEnd = DateTime.fromISO(this.timerEventClone.timerEnd);
				this.clientId = this.timerEventClone.clientId;
				this.projectId = this.timerEventClone.projectId;
				this.deliverableId = this.timerEventClone.deliverableId;
				this.ticketId = this.timerEventClone.ticketId;

				this.projectName = this.timerEventClone.projectName;
				this.deliverableName = this.timerEventClone.deliverableName;
				this.ticketName = this.timerEventClone.ticketName;

				this.timeStartString = this.timerStart
					.toLocaleString(HDateTime.TIME_SIMPLE)
					.replace(/[.]/gi, '')
					.toUpperCase();
				this.timeEndString = this.timerEnd
					.toLocaleString(HDateTime.TIME_SIMPLE)
					.replace(/[.]/gi, '')
					.toUpperCase();
				this.startDate = this.timerStart.toISODate();

				//replace the narrow nbsp unicode character with an actual space
				this.timeStartString = this.timeStartString.replace(/[\u202f]/, ' ');
				this.timeEndString = this.timeEndString.replace(/[\u202f]/, ' ');
				this.setDuration();
			},

			setClientId: function(client) {
				this.clientMenu = false;
				this.clientId = client.id;
				this.sanitizeRelatedData('client');
			},

			setProjectId: function(project) {
				this.projectMenu = false;
				this.projectId = project.value;
				this.projectName = project.text;
				this.sanitizeRelatedData('project');
			},

			setDeliverableId: function(deliverable) {
				this.deliverableMenu = false;
				this.deliverableId = deliverable.value;
				this.deliverableName = deliverable.text;
				this.sanitizeRelatedData('deliverable');
			},

			setTicketId: function(ticket) {
				this.ticketMenu = false;
				this.ticketId = ticket.value;
				this.ticketName = ticket.text;
				this.sanitizeRelatedData('ticket');
			},

			startDateUpdated: function(start) {
				this.startDate = start;
				let duration = this.timerEnd.diff(this.timerStart, ['seconds']).seconds;
				this.timerStart = DateTime.fromISO(this.startDate + 'T' + this.timerStart.toISOTime());
				this.timerEnd = this.timerStart.plus({ seconds: duration });
				this.refreshKey++;
			},

			startTimeUpdated: function(val) {
				let newString = this.timerStart.toISODate() + 'T' + val.data.HH + ':' + val.data.mm + ':00';
				let newStart = DateTime.fromISO(newString);
				if (newStart.isValid) {
					this.timerStart = newStart;
					this.setDuration();
				}
			},

			endTimeUpdated: function(val) {
				let newString = this.timerEnd.toISODate() + 'T' + val.data.HH + ':' + val.data.mm + ':00';
				let newEnd = DateTime.fromISO(newString);
				if (newEnd.isValid) {
					this.timerEnd = newEnd;
					this.setDuration();
				}
			},

			setDuration: function() {
				const calculatedTimerStart = this.$DateTime
					.fromISO(this.timerStart)
					.plus({ seconds: this.timerEventClone.pausedSeconds });

				let seconds = this.timerEnd.diff(calculatedTimerStart, ['seconds']).seconds;
				let diff = this.timerEnd.diff(calculatedTimerStart, ['hours', 'minutes', 'seconds']);
				let uint = (number) => Math.sqrt(Math.pow(number, 2));
				let hours = uint(diff.hours);
				let minutes = uint(diff.minutes);

				this.durationString =
					(seconds < 0 ? '-' : '') + String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');
				this.refreshKey++;
			},

			durationUpdated: function() {
				let newDuration = this.durationString.replace(/[^0-9:]/g, '').split(':');
				let hours, minutes;

				if (newDuration.length === 1) {
					let value = parseInt(this.safeParse(newDuration[0]), 10);
					if (value < 10) {
						hours = value;
						minutes = 0;
					} else {
						hours = 0;
						minutes = value;
					}
				} else {
					hours = parseInt(this.safeParse(newDuration[0]), 10);
					minutes = parseInt(this.safeParse(newDuration[1]), 10);
				}

				let duration = hours * 60 + minutes;
				this.timerEnd = this.timerStart.plus({ minutes: duration + Math.ceil(this.timerEventClone.pausedSeconds / 60) });
				this.timeEndString = this.timerEnd.toFormat('t');
				this.setDuration();
			},

			safeParse: function(value) {
				return value ? value : '0';
			},

			initTimerEventClone() {
				let timerStart = this.$DateTime.local();
				let timerEnd = this.$DateTime.local();

				this.timerEventClone = {
					clientId: this.client ? this.client.id : null,
					clientName: this.client ? this.client.name : null,
					deliverableId: null,
					deliverableName: null,
					id: null,
					billable: true,
					userId: this.$store.getters.getLoggedInUserId,
					notes: null,
					projectId: null,
					projectName: null,
					ticketId: null,
					ticketName: null,
					timerStart: this.$DateTime
						.local()
						.minus({ minutes: 30 })
						.toISO(),
					timerEnd: this.$DateTime.local().toISO(),
					duration: timerEnd.diff(timerStart, ['hours', 'minutes', 'seconds', 'milliseconds']),
					pausedSeconds: 0,
				};
			},

			handleClose: function() {
				this.handleSave();
			},

			handleSave(close = true) {
				this.timerEventClone.timerStart = this.timerStart.toISO();
				this.timerEventClone.timerEnd = this.timerEnd.toISO();
				if (!this.timerEventClone.id) this.createTimer();
				else this.updateTimer(close);
			},

			createTimer() {
				this.$track.record('time-track create');
				const validatedEvent = this.timeTrack.validateEntry(this.timerEventClone, false);
				if (!validatedEvent) {
					return;
				}
				this.timeTrackService
					.createTimerEvent(validatedEvent)
					.then((res) => {
						this.$emit('result', res.data);
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('error', 'Error [' + status + '] ' + msg);
						this.$emit('error', err);
					});
			},

			sanitizeRelatedData(scope) {
				switch (scope) {
					case 'client': {
						let project = this.getProject(this.projectId);
						let deliverable = this.getDeliverable(this.deliverableId);
						let ticket = this.getTicket(this.ticketId);

						if (!this.clientId) {
							this.projectId = null;
							this.deliverableId = null;
							this.ticketId = null;
							this.projectName = null;
							this.deliverableName = null;
							this.ticketName = null;
						} else {
							if (!ticket || ticket.clientId !== this.clientId) {
								this.ticketId = null;
								this.ticketName = null;
							}
							if (!project || project.client.id !== this.clientId) {
								this.projectId = null;
								this.deliverableId = null;
								this.projectName = null;
								this.deliverableName = null;
							} else {
								if (!deliverable || deliverable.project.id !== this.projectId) {
									this.deliverableId = null;
									this.deliverableName = null;
								}
							}
						}

						break;
					}

					case 'ticket': {
						let ticket = this.getTicket(this.ticketId);
						if (ticket) {
							this.ticketName = (ticket.ticketNumber ? ticket.ticketNumber + ': ' : '') + ticket.subject;
							this.clientId = ticket.clientId;
							this.projectId = null;
							this.deliverableId = null;
							this.projectName = null;
							this.deliverableName = null;
							this.sanitizeRelatedData('client');
						} else {
							this.ticketId = null;
							this.ticketName = null;
						}
						break;
					}

					case 'project': {
						let project = this.getProject(this.projectId);
						if (project) {
							this.clientId = project.client.id;
							this.ticketId = null;
							this.ticketName = null;
							this.sanitizeRelatedData('client');
						} else {
							this.projectId = null;
							this.deliverableId = null;
							this.projectName = null;
							this.deliverableName = null;
						}
						break;
					}

					case 'deliverable': {
						let deliverable = this.getDeliverable(this.deliverableId);
						if (deliverable) {
							this.projectId = deliverable.project.id;
							this.ticketId = null;
							this.ticketName = null;
							this.sanitizeRelatedData('project');
						} else {
							this.deliverableId = null;
							this.deliverableName = null;
						}
						break;
					}
				}
			},

			updateTimer(close = true) {
				//this.getAllTimes();
				if (this.timerEvent.invoiceId) {
					return;
				}

				if (isEqual(this.timerEvent, this.timerEventClone) && close) {
					console.log('update', 'no changes, no update!');
					this.$emit('result');
					return;
				}

				const validatedEvent = this.timeTrack.validateEntry(this.timerEventClone);
				if (!validatedEvent) {
					return;
				}

				this.$store.commit('startLoading');

				this.timeTrackService
					.putTimerEvent(validatedEvent.id, validatedEvent)
					.then((res) => {
						if (close) {
							this.$emit('result', res.data);
						} else {
							this.timerEventClone = res.data;
							this.setupData();
						}
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('error', 'Error [' + status + '] ' + msg);
						this.$emit('error', err);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			handleDelete() {
				this.deleteTimer(this.timerEventClone.id); // Delete for realzies
				this.deleteConfirm = false;
			},

			handleCancel() {
				this.deleteConfirm = false;
			},

			deleteTimer(id) {
				if (!id) {
					this.$emit('result');
					return;
				}

				if (this.timerEvent && this.timerEvent.invoiceId) {
					return;
				}

				this.timeTrackService
					.deleteTimerEvent(id)
					.then(() => {
						this.$emit('result', { action: 'DELETED', id: id });
					})
					.catch((err) => {
						console.log(err);
						this.$emit('error', err);
					});
			},

			getProjectList() {
				this.projectService
					.getAllActiveProjects()
					.then((res) => {
						this.projectList.splice(0, this.projectList.length);
						this.projectList.push(...res.data);
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			getDeliverableList() {
				this.deliverableService
					.getDeliverablesForAccount(true, null, true)
					.then((res) => {
						this.deliverableList.splice(0, this.deliverableList.length);
						this.deliverableList.push(...res.data);
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			getTicketList() {
				if (this.$store.getters.hasFeatureAccess('tickets')) {
					this.ticketService
						.getTickets(null, null, true)
						.then((res) => {
							this.ticketList.splice(0, this.ticketList.length);
							this.ticketList.push(...res.data);
						})
						.catch((err) => {
							let msg = err.response.data.message;
							let status = err.response.status;
							this.$store.commit('warn', 'Error [' + status + '] ' + msg);
						});
				} else {
					this.ticketList.splice(0, this.ticketList.length);
				}
			},

			getProject(projectId) {
				if (!this.projectList) return;
				return this.projectList.find((v) => v.id === projectId);
			},

			getDeliverable(deliverableId) {
				if (!this.deliverableList) return;
				return this.deliverableList.find((v) => v.id === deliverableId);
			},

			getTicket(ticketId) {
				if (!this.ticketList) return;
				return this.ticketList.find((v) => v.id === ticketId);
			},

			openInvoice: function() {
				let binding = {
					clientId: this.clientId,
					id: this.timerEventClone.invoiceId,
					showDuplicate: false,
				};
				this.$store.state.globalModalController.openModal(InvoiceDetails, binding, true, true);
			},

			confirmClearPause: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Do you want to clear the time in paused status?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.timerEventClone.pausedSeconds = 0;
						this.handleSave(false);
					}
				});
			},
		},

		watch: {
			clientId: function(val) {
				this.timerEventClone.clientId = val;
			},

			projectId: function(val) {
				this.timerEventClone.projectId = val;
			},

			deliverableId: function(val) {
				this.timerEventClone.deliverableId = val;
			},

			ticketId: function(val) {
				this.timerEventClone.ticketId = val;
			},
		},

		computed: {
			showClientProjectSelect: function() {
				return !this.timerEventClone.ticketId;
			},

			showTicketSelect: function() {
				return !(this.timerEventClone.projectId || this.timerEventClone.deliverableId);
			},

			pausedTimeFormatted: function() {
				if (this.timerEventClone.pausedSeconds) {
					let dur = {
						seconds: this.timerEventClone.pausedSeconds,
					};
					return Duration.fromObject(dur).toFormat('h:mm:ss');
				} else {
					return null;
				}
			},

			selectedClient: function() {
				return this.$store.getters.getClientById(this.clientId);
			},

			filteredClients: function() {
				let clients = [...this.$store.state.clientCache.filter((c) => !c.archive)];
				let archived = [...this.$store.state.clientCache.filter((c) => c.archive)];

				clients.sort((a, b) => a.name.localeCompare(b.name));
				archived.sort((a, b) => a.name.localeCompare(b.name));

				clients.unshift({ id: null, name: '-- Unassigned --' });
				clients.push({ id: null, name: '-- Archived Clients --', archive: true });
				clients.push(...archived);

				if (this.clientFilter) {
					clients = clients.filter((c) => c.name && c.name.toLowerCase().includes(this.clientFilter.toLowerCase()));
				}

				return clients;
			},

			format: function() {
				if (this.is12HourFormat) {
					return 'h:mm A';
				} else {
					return 'HH:mm';
				}
			},

			isTimeRangeValid: function() {
				this.refreshKey;
				if (this.timerEnd && this.timerStart) {
					let diff = this.timerEnd.diff(this.timerStart, ['seconds']).seconds;
					if (diff >= 0) {
						return true;
					} else {
						return false;
					}
				} else {
					return true;
				}
			},

			startAndEndOnSameDay: function() {
				return this.timerStart.toISODate() === this.timerEnd.toISODate();
			},

			isReady: {
				get: function() {
					return true;
					//return !this.$validations.isEmpty(this.dateStart) && !this.$validations.isEmpty(this.timerStart);
				},
			},

			user() {
				return this.$store.state.loggedInUser;
			},

			userList: function() {
				if (this.$store.getters.isAccountCollaborator) {
					return [this.user];
				} else {
					return this.$store.state.usersForAccount;
				}
			},

			assignedUser: function() {
				return this.userList.find((u) => u.userId === this.timerEventClone.userId);
			},

			ticketItems() {
				if (!this.ticketList) return [];
				let tickets; // filtered or unfiltered
				if (this.clientId) {
					tickets = this.ticketList.filter((item) => {
						return item.clientId === this.clientId;
					});
				} else {
					tickets = this.ticketList;
				}
				const values = tickets.map((a) => a.id);
				const names = tickets.map((a) => (a.ticketNumber ? a.ticketNumber + ': ' : '') + a.subject);
				let items = [];

				for (let i = 0; i < names.length; i++) {
					items.push({ text: names[i], value: values[i], disabled: false });
				}
				items.sort(function(a, b) {
					return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
				});

				if (this.ticketId) {
					// Only show clear if a project is selected
					items.unshift({ text: '-- Clear --', value: null, disabled: false });
				}

				return items;
			},

			projectItems() {
				if (!this.projectList) return [];
				let projects; // filtered or unfiltered
				if (this.clientId) {
					projects = this.projectList.filter((item) => {
						// clientId filter
						return item.clientId === this.clientId && item.active;
					});
				} else {
					projects = this.projectList;
				}

				projects.forEach((p) => (p.name = p.name ? p.name : '[blank]'));

				const values = projects.map((a) => a.id);
				let items = [];

				for (let i = 0; i < values.length; i++) {
					let p = projects.find((p) => p.id === values[i]);
					items[i] = { text: p.name, value: p.id, disabled: false };
				}

				items.sort(function(a, b) {
					return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
				});

				if (this.timerEventClone.projectId) {
					// Only show clear if it's populated
					items.unshift({ text: '-- Clear --', value: null, disabled: false });
				}
				return items;
			},

			deliverableItems() {
				if (!this.deliverableList) return [];

				let deliverables = this.deliverableList
					.filter((item) => {
						if (item.archived === false) {
							return true;
						} else if (item.archived && item.id === this.timerEventClone.deliverableId) {
							return true;
						} else {
							return false;
						}
					})
					.filter((item) => {
						if (this.projectId && item.project && item.project.id === this.projectId) return true;
						if (!this.projectId && this.clientId && item.client && item.client.id === this.clientId) return true;
						if (!this.projectId && !this.clientId) return true;
						return false;
					});

				let items = [];

				deliverables.forEach((d) => {
					items.push({
						text: d.name ? d.name : '[blank]',
						value: d.id,
						disabled: false,
					});
				});

				items.sort(function(a, b) {
					return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
				});

				if (this.timerEventClone.deliverableId) {
					// Only show clear if it's populated
					items.unshift({ text: '-- Clear --', value: null, disabled: false });
				}
				return items;
			},

			footerClass() {
				if (this.deleteConfirm) return 'footer delete-confirm';
				return 'footer';
			},
		},
	};
</script>

<style scoped lang="scss"></style>

<style lang="scss">
	.date-selector {
		padding: 4px 6px;
		font-weight: 600;
		width: fit-content;
		border: 1px solid var(--v-white-base);
		&:hover {
			border: 1px solid var(--v-gray_30-base);
		}
	}

	.duration-input {
		outline: none !important;
		padding: 4px 6px;
		font-weight: 600;
		width: 180px;
		border: 1px solid var(--v-white-base);
		&:hover {
			border: 1px solid var(--v-gray_30-base);
		}
	}

	.vue__time-picker-dropdown ul li,
	.vue__time-picker .dropdown ul li {
		color: var(--v-black-base) !important;
	}

	.vue__time-picker input.display-time {
		border: 1px solid var(--v-white-base) !important;
		outline: none !important;
		color: var(--v-black-base) !important;
		font-family: Inter !important;
		font-weight: 600 !important;

		&:hover {
			border: 1px solid var(--v-gray_30-base) !important;
		}
	}

	.vue__time-picker-dropdown,
	.vue__time-picker .dropdown {
		font-family: Inter !important;
		background-color: var(--v-white-base) !important;
		color: var(--v-black-base) !important;
	}

	.vue__time-picker .dropdown ul li:not([disabled]).active {
		background: var(--v-primary-base);
		color: var(--v-black-base) !important;
		&:hover {
			background: var(--v-primary-base);
		}
	}

	.vue__time-picker-dropdown ul li:not([disabled]).active {
		background: var(--v-primary-base);
		&:hover {
			background: var(--v-primary-base);
		}
	}
</style>
