import { render, staticRenderFns } from "./InvoiceStyleConfig.vue?vue&type=template&id=e0b43efe&scoped=true&"
import script from "./InvoiceStyleConfig.vue?vue&type=script&lang=js&"
export * from "./InvoiceStyleConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0b43efe",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VBtn,VColorPicker,VMenu,VSlider})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src991023174/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e0b43efe')) {
      api.createRecord('e0b43efe', component.options)
    } else {
      api.reload('e0b43efe', component.options)
    }
    module.hot.accept("./InvoiceStyleConfig.vue?vue&type=template&id=e0b43efe&scoped=true&", function () {
      api.rerender('e0b43efe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/templates/invoices/config/InvoiceStyleConfig.vue"
export default component.exports