import { render, staticRenderFns } from "./NewEmail.vue?vue&type=template&id=971ffbbe&scoped=true&"
import script from "./NewEmail.vue?vue&type=script&lang=js&"
export * from "./NewEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "971ffbbe",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src991023174/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('971ffbbe')) {
      api.createRecord('971ffbbe', component.options)
    } else {
      api.reload('971ffbbe', component.options)
    }
    module.hot.accept("./NewEmail.vue?vue&type=template&id=971ffbbe&scoped=true&", function () {
      api.rerender('971ffbbe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/communicator/inbox/email/NewEmail.vue"
export default component.exports