var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", [
        _c("div", { staticClass: "row-format align-start" }, [
          _c("div", { staticClass: "column-format" }, [
            _c("div", { staticClass: "font-gray_80 mb-1" }, [
              _vm._v(_vm._s(_vm.$t("connected-apps.quickbooks.desc")))
            ]),
            _vm.paidAccount
              ? _c(
                  "div",
                  [
                    _vm.isConnected
                      ? _c("connected")
                      : _c(
                          "div",
                          {
                            staticClass: "font-primary brand-medium pointer",
                            on: { click: _vm.initializeOAuth }
                          },
                          [_vm._v("Connect Quickbooks Online")]
                        )
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "font-14 pointer font-primary",
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/subscription")
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "material-symbols-rounded" },
                        [_vm._v("lock")]
                      ),
                      _vm._v(
                        "This integration is not available on your plan. If you would like to connect your Quickbooks account, please upgrade to Pro or Teams. "
                      )
                    ],
                    1
                  )
                ])
          ]),
          _vm.isConnected
            ? _c(
                "div",
                {
                  staticClass: "ml-auto pointer",
                  on: {
                    click: function($event) {
                      _vm.expanded = !_vm.expanded
                    }
                  }
                },
                [
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.expanded ? "$chevronUp" : "$chevronDown"))
                  ])
                ],
                1
              )
            : _vm._e()
        ]),
        _vm.isConnected && _vm.expanded
          ? _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "div",
                  {
                    staticClass: "mb-6 font-14 py-2 px-2",
                    staticStyle: {
                      "background-color": "var(--v-white-base)",
                      "border-radius": "8px"
                    }
                  },
                  [
                    _c("div", { staticClass: "mb-2" }, [
                      _c("span", { staticClass: "brand-medium" }, [
                        _vm._v("Quickbooks company:")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.quickbooks.companyInfo.companyName) +
                          " "
                      )
                    ]),
                    _c("v-select", {
                      staticClass: "h-outline",
                      attrs: {
                        label: "How do you send invoices?",
                        "hide-details": "",
                        "persistent-placeholder": "",
                        items: _vm.forceUniqueNumbers,
                        "item-value": "value",
                        "item-text": "label"
                      },
                      on: { change: _vm.saveConfig },
                      model: {
                        value: _vm.forceUniqueInvoiceNumbers,
                        callback: function($$v) {
                          _vm.forceUniqueInvoiceNumbers = $$v
                        },
                        expression: "forceUniqueInvoiceNumbers"
                      }
                    }),
                    _c("v-autocomplete", {
                      staticClass: "h-outline",
                      attrs: {
                        "hide-details": "",
                        "persistent-placeholder": "",
                        label: "Income account",
                        items: _vm.revenueAccounts,
                        "item-text": "name",
                        "item-value": "id",
                        "return-object": ""
                      },
                      on: { change: _vm.saveConfig },
                      model: {
                        value: _vm.incomeAccount,
                        callback: function($$v) {
                          _vm.incomeAccount = $$v
                        },
                        expression: "incomeAccount"
                      }
                    }),
                    _c("v-autocomplete", {
                      staticClass: "h-outline",
                      attrs: {
                        "hide-details": "",
                        "persistent-placeholder": "",
                        label: "Payment processing expense account",
                        items: _vm.expenseAccounts,
                        "item-text": "name",
                        "item-value": "id",
                        "return-object": ""
                      },
                      on: { change: _vm.saveConfig },
                      model: {
                        value: _vm.expenseAccount,
                        callback: function($$v) {
                          _vm.expenseAccount = $$v
                        },
                        expression: "expenseAccount"
                      }
                    }),
                    _c("v-autocomplete", {
                      staticClass: "h-outline",
                      attrs: {
                        "hide-details": "",
                        "persistent-placeholder": "",
                        label: "Un-deposited funds account",
                        items: _vm.assetAccounts,
                        "item-text": "name",
                        "item-value": "id",
                        "return-object": ""
                      },
                      on: { change: _vm.saveConfig },
                      model: {
                        value: _vm.unDepositedFundsAccount,
                        callback: function($$v) {
                          _vm.unDepositedFundsAccount = $$v
                        },
                        expression: "unDepositedFundsAccount"
                      }
                    }),
                    _c("v-autocomplete", {
                      staticClass: "h-outline",
                      attrs: {
                        "hide-details": "",
                        "persistent-placeholder": "",
                        label: "Default sales tax account",
                        items: _vm.taxRates,
                        "item-text": "name",
                        "item-value": "id",
                        "return-object": ""
                      },
                      on: { change: _vm.saveConfig },
                      model: {
                        value: _vm.salesTaxAccount,
                        callback: function($$v) {
                          _vm.salesTaxAccount = $$v
                        },
                        expression: "salesTaxAccount"
                      }
                    }),
                    _c("v-autocomplete", {
                      staticClass: "h-outline",
                      attrs: {
                        "hide-details": "",
                        "persistent-placeholder": "",
                        label: "Exempt sales tax account",
                        items: _vm.taxRates,
                        "item-text": "name",
                        "item-value": "id",
                        "return-object": ""
                      },
                      on: { change: _vm.saveConfig },
                      model: {
                        value: _vm.exemptTaxAccount,
                        callback: function($$v) {
                          _vm.exemptTaxAccount = $$v
                        },
                        expression: "exemptTaxAccount"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "secondary-action mr-1",
                    on: { click: _vm.confirmDisconnect }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("$delete")]),
                    _vm._v("Disconnect")
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "secondary-action mr-1",
                    on: { click: _vm.confirmReset }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("lock_reset")
                    ]),
                    _vm._v("Reset integration")
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }