var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "solid-hr" }, [
    _vm.meeting.pageLayout.showLogo
      ? _c("div", { staticClass: "pb-6" }, [
          _c("img", {
            staticStyle: {
              display: "flex",
              "max-width": "80%",
              "max-height": "150px",
              "align-self": "start"
            },
            attrs: {
              alt: "Image not found",
              src: this.$store.getters.getAccountLogo
            }
          })
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "px-4 text-left form-content",
        on: {
          click: function($event) {
            return _vm.activateEditor(true)
          }
        }
      },
      [
        _vm.editorActive
          ? _c("editor", {
              ref: "SchedulerTextEditor",
              attrs: {
                "api-key": _vm.$store.getters.getTinyMceKey,
                inline: true,
                init: _vm.mceConfigText
              },
              on: {
                onblur: function($event) {
                  return _vm.activateEditor(false)
                }
              },
              model: {
                value: _vm.meeting.scheduleMeeting.schedulerInfo,
                callback: function($$v) {
                  _vm.$set(_vm.meeting.scheduleMeeting, "schedulerInfo", $$v)
                },
                expression: "meeting.scheduleMeeting.schedulerInfo"
              }
            })
          : _c("div", {
              staticStyle: { "line-height": "1.8" },
              domProps: { innerHTML: _vm._s(_vm.tokenizedText(_vm.meeting)) }
            })
      ],
      1
    ),
    _vm.meeting.locations.length > 1
      ? _c(
          "div",
          { staticClass: "px-4" },
          [
            _c(
              "v-radio-group",
              {
                model: {
                  value: _vm.selectedLocation,
                  callback: function($$v) {
                    _vm.selectedLocation = $$v
                  },
                  expression: "selectedLocation"
                }
              },
              _vm._l(_vm.meeting.locations, function(location, index) {
                return _c("v-radio", {
                  key: index,
                  attrs: { value: location },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "row-format align-center gap-2" },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "material-symbols-rounded" },
                                  [_vm._v(_vm._s(_vm.getIcon(location)))]
                                ),
                                _c("div", { staticClass: "brand-medium" }, [
                                  _vm._v(_vm._s(_vm.getLabel(location)))
                                ])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                })
              }),
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }