var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        (_vm.route ? "pointer" : "") +
        " row-format " +
        (_vm.expanded ? "align-center" : "align-center") +
        "  mx-2 " +
        (_vm.active ? "nav-link-active" : "") +
        " nav-link" +
        (_vm.expanded ? "-expanded" : ""),
      on: {
        click: function($event) {
          _vm.more ? _vm.$emit("click", $event) : _vm.handleClick($event)
        }
      }
    },
    [
      _vm.expanded
        ? _c(
            "div",
            {
              staticClass: "column-format",
              staticStyle: { "padding-left": "12px", width: "100%" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "row-format align-center gap-2 pr-2 hover-link",
                  staticStyle: { width: "100%" }
                },
                [
                  _c(
                    "v-icon",
                    {
                      class: !_vm.active
                        ? "material-symbols-rounded"
                        : "material-symbols-rounded",
                      attrs: {
                        color: _vm.active ? "primary" : "gray_90",
                        size: "20"
                      }
                    },
                    [_vm._v(_vm._s(_vm.icon))]
                  ),
                  _c(
                    "div",
                    { class: "nav-label  " + (_vm.active ? "active" : "") },
                    [_vm._v(_vm._s(_vm.label))]
                  )
                ],
                1
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass: "row-format centered",
              staticStyle: { "padding-left": "12px" }
            },
            [
              _c(
                "v-icon",
                {
                  class: !_vm.active
                    ? "material-symbols-rounded"
                    : "material-symbols-rounded",
                  attrs: {
                    color: _vm.active ? "primary" : "gray_90",
                    size: "20"
                  }
                },
                [_vm._v(_vm._s(_vm.icon))]
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }