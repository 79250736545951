var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.filter
      ? _c(
          "div",
          [
            _c(
              "v-menu",
              {
                staticStyle: { "overflow-y": "auto" },
                attrs: {
                  "max-height": "90vh",
                  "max-width": "250",
                  "nudge-bottom": "34",
                  "close-on-content-click": false,
                  "close-on-click": true,
                  "content-class": "filter-menu-class"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "standard-action",
                                attrs: {
                                  elevation: "0",
                                  outlined: "",
                                  color: ""
                                }
                              },
                              on
                            ),
                            [
                              _c("span", {
                                class:
                                  "font-14 " +
                                  (!_vm.isDefaultFilter ? "" : "font-gray_60") +
                                  " brand-medium",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.isDefaultFilter
                                      ? "Filter..."
                                      : _vm.filterDescription
                                  )
                                }
                              }),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mx-1",
                                  attrs: {
                                    "x-small": "",
                                    color: !_vm.isDefaultFilter ? "" : "gray_60"
                                  }
                                },
                                [_vm._v("$arrowDown")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  451036115
                ),
                model: {
                  value: _vm.menuOpen,
                  callback: function($$v) {
                    _vm.menuOpen = $$v
                  },
                  expression: "menuOpen"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "column-format open-menu" },
                  [
                    _c("v-text-field", {
                      staticClass: "font-16",
                      attrs: {
                        id: "search-input",
                        autofocus: "",
                        solo: "",
                        flat: "",
                        "hide-details": "",
                        dense: "",
                        placeholder: "Filter..."
                      },
                      on: {
                        change: _vm.updated,
                        keypress: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          ) {
                            return null
                          }
                          _vm.menuOpen = false
                        }
                      },
                      model: {
                        value: _vm.filter.search,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "search", $$v)
                        },
                        expression: "filter.search"
                      }
                    }),
                    _c("filter-option", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showClientList,
                          expression: "showClientList"
                        }
                      ],
                      attrs: {
                        label: _vm.$t("client.filter.client-list"),
                        "option-list": _vm.clientList
                      },
                      on: { change: _vm.updated },
                      model: {
                        value: _vm.filter.clientList,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "clientList", $$v)
                        },
                        expression: "filter.clientList"
                      }
                    }),
                    _c("filter-option", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showMeetingStatus,
                          expression: "showMeetingStatus"
                        }
                      ],
                      attrs: {
                        label: _vm.$t("meeting.filter.meeting-status"),
                        "option-list": _vm.meetingStatus
                      },
                      on: { change: _vm.updated },
                      model: {
                        value: _vm.filter.meetingStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "meetingStatus", $$v)
                        },
                        expression: "filter.meetingStatus"
                      }
                    }),
                    _c("div", { staticClass: "filter-option-box" }, [
                      _c(
                        "div",
                        {
                          staticClass: "row-format",
                          on: {
                            click: function($event) {
                              _vm.dateExpanded = !_vm.dateExpanded
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "font-14" }, [
                            _vm._v(_vm._s(_vm.$t("client.filter.date-range")))
                          ]),
                          _c(
                            "v-icon",
                            {
                              staticStyle: {
                                "margin-left": "auto",
                                "margin-top": "1px"
                              },
                              attrs: { small: "" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.dateExpanded ? "$arrowUp" : "$arrowDown"
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.dateExpanded
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-radio-group",
                                {
                                  on: {
                                    change: function($event) {
                                      return _vm.updated()
                                    }
                                  },
                                  model: {
                                    value: _vm.filter.dateSpecifier,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filter, "dateSpecifier", $$v)
                                    },
                                    expression: "filter.dateSpecifier"
                                  }
                                },
                                _vm._l(_vm.dateOptions, function(option) {
                                  return _c("v-radio", {
                                    key: option.value,
                                    attrs: { value: option.value },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-14 brand-semilight",
                                                  staticStyle: {
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [_vm._v(_vm._s(option.text))]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                }),
                                1
                              ),
                              _vm.showEarliest
                                ? _c(
                                    "div",
                                    {},
                                    [
                                      _c("date-selector", {
                                        attrs: {
                                          "content-class": "my-5",
                                          date: _vm.filter.earliest,
                                          label: "Earliest"
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.filter.earliest = $event
                                            _vm.updated()
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showLatest
                                ? _c(
                                    "div",
                                    {},
                                    [
                                      _c("date-selector", {
                                        attrs: {
                                          "content-class": "my-5",
                                          date: _vm.filter.latest,
                                          label: "Latest"
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.filter.latest = $event
                                            _vm.updated()
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      { staticClass: "mx-3 mt-3 mb-2" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "filter-clear mt-2 mb-1",
                            attrs: {
                              text: "",
                              block: "",
                              color: "secondary",
                              elevation: "0"
                            },
                            on: {
                              click: function($event) {
                                return _vm.clearFilter()
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("$clear")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("timetrack.filter.clear-filter"))
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "super-action",
                            attrs: { block: "", color: "", elevation: "0" },
                            on: {
                              click: function($event) {
                                _vm.menuOpen = false
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("global.done")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }