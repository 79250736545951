import { render, staticRenderFns } from "./Invoices.vue?vue&type=template&id=158b4f28&scoped=true&"
import script from "./Invoices.vue?vue&type=script&lang=js&"
export * from "./Invoices.vue?vue&type=script&lang=js&"
import style0 from "./Invoices.vue?vue&type=style&index=0&id=158b4f28&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158b4f28",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VBtn,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VPagination})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src991023174/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('158b4f28')) {
      api.createRecord('158b4f28', component.options)
    } else {
      api.reload('158b4f28', component.options)
    }
    module.hot.accept("./Invoices.vue?vue&type=template&id=158b4f28&scoped=true&", function () {
      api.rerender('158b4f28', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/subscription/v2/Invoices.vue"
export default component.exports