var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: { "bread-crumbs": [{ to: "/home", label: "Home" }] }
        },
        [_vm._v(" Preferences ")]
      ),
      _c(
        "div",
        {
          staticClass: "column-format",
          attrs: { id: "account-settings-preferences" }
        },
        [
          _c(
            "div",
            { staticClass: "row-format gap-3 pb-4" },
            _vm._l(_vm.pages, function(page) {
              return _c(
                "div",
                {
                  key: page.value,
                  class:
                    "page-link " +
                    (_vm.currentView === page.value ? "page-link-active" : ""),
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.setView(page.value)
                    }
                  }
                },
                [_c("div", [_vm._v(_vm._s(page.label))])]
              )
            }),
            0
          ),
          _vm.currentView === "app"
            ? _c(
                "div",
                [
                  _c(
                    "v-container",
                    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-3", attrs: { cols: "6" } },
                            [
                              _c("div", { staticClass: "text-left" }, [
                                _vm._v("Home page")
                              ]),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "mt-2 pt-0",
                                  attrs: { dense: "", "hide-details": "" },
                                  on: { change: _vm.homePageUpdated },
                                  model: {
                                    value: _vm.homePage,
                                    callback: function($$v) {
                                      _vm.homePage = $$v
                                    },
                                    expression: "homePage"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: "/getting-started" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c("h-icon2", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  name: "hummingbird",
                                                  size: "25",
                                                  active:
                                                    _vm.homePage ===
                                                    "/getting-started"
                                                }
                                              }),
                                              _c("span", [
                                                _vm._v("Getting started")
                                              ])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      4060961099
                                    )
                                  }),
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: "/home" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "material-symbols-rounded mr-3"
                                                },
                                                [_vm._v("other_houses")]
                                              ),
                                              _c("span", [_vm._v("Homebase")])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      2457257391
                                    )
                                  }),
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: "/projects" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "material-symbols-rounded mr-3"
                                                },
                                                [_vm._v("folder_special")]
                                              ),
                                              _c("span", [_vm._v("Projects")])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      337797975
                                    )
                                  }),
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: "/accounting" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "material-symbols-rounded mr-3"
                                                },
                                                [_vm._v("request_quote")]
                                              ),
                                              _c("span", [_vm._v("Accounting")])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1325566316
                                    )
                                  }),
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: "/pipeline/opportunities" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "material-symbols-rounded mr-3"
                                                },
                                                [_vm._v("filter_alt")]
                                              ),
                                              _c("span", [
                                                _vm._v("Sales pipeline")
                                              ])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      869955413
                                    )
                                  })
                                ],
                                1
                              ),
                              _c("div", { staticClass: "text-left mt-5" }, [
                                _vm._v("Show client logos")
                              ]),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "mt-2 pt-0",
                                  attrs: { dense: "", "hide-details": "" },
                                  on: { change: _vm.showClientLogosUpdated },
                                  model: {
                                    value:
                                      _vm.$store.state.podAccount
                                        .accountPreferences.showClientLogos,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$store.state.podAccount
                                          .accountPreferences,
                                        "showClientLogos",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$store.state.podAccount.accountPreferences.showClientLogos"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: true, label: "Enabled" }
                                  }),
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: false, label: "Disabled" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-3", attrs: { cols: "6" } },
                            [
                              _c("div", { staticClass: "text-left" }, [
                                _vm._v("Dark mode")
                              ]),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "mt-2 pt-0",
                                  attrs: { dense: "", "hide-details": "" },
                                  on: { change: _vm.darkModeUpdated },
                                  model: {
                                    value: _vm.darkMode,
                                    callback: function($$v) {
                                      _vm.darkMode = $$v
                                    },
                                    expression: "darkMode"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: {
                                      value: "always",
                                      label: "Dark mode always"
                                    }
                                  }),
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: {
                                      value: "never",
                                      label: "Light mode always"
                                    }
                                  }),
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: {
                                      value: "automatic",
                                      label: "Automatic (based on OS)"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("div", { staticClass: "text-left mt-5" }, [
                                _vm._v("Show scroll bars")
                              ]),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "mt-2 pt-0",
                                  attrs: { dense: "", "hide-details": "" },
                                  on: { change: _vm.showScrollBarsUpdated },
                                  model: {
                                    value: _vm.$store.state.showScrollBars,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$store.state,
                                        "showScrollBars",
                                        $$v
                                      )
                                    },
                                    expression: "$store.state.showScrollBars"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: true, label: "Yes" }
                                  }),
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: false, label: "No" }
                                  })
                                ],
                                1
                              ),
                              _c("div", { staticClass: "text-left mt-8" }, [
                                _vm._v("Week starts on")
                              ]),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "mt-2 pt-0",
                                  attrs: { dense: "", "hide-details": "" },
                                  on: { change: _vm.weekStartsOnUpdated },
                                  model: {
                                    value: _vm.weekStartsOn,
                                    callback: function($$v) {
                                      _vm.weekStartsOn = $$v
                                    },
                                    expression: "weekStartsOn"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: "Sunday", label: "Sunday" }
                                  }),
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: "Monday", label: "Monday" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.currentView === "client" && _vm.$store.getters.isAccountOwner
            ? _c(
                "div",
                [
                  _c(
                    "v-container",
                    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                { staticClass: "column-format gap-3" },
                                [
                                  _c("div", { staticClass: "text-left" }, [
                                    _vm._v("New client/contact defaults")
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "number",
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      label: "Default - Hourly rate",
                                      prefix: _vm.currencySymbol,
                                      dense: "",
                                      outlined: ""
                                    },
                                    on: { change: _vm.updateDefaultHourlyRate },
                                    model: {
                                      value:
                                        _vm.$store.state.podAccount
                                          .accountPreferences.defaultHourlyRate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$store.state.podAccount
                                            .accountPreferences,
                                          "defaultHourlyRate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$store.state.podAccount.accountPreferences.defaultHourlyRate"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "number",
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      label: "Default - late fee",
                                      suffix: "%/mo",
                                      dense: "",
                                      outlined: ""
                                    },
                                    on: {
                                      change: _vm.updateDefaultLatePaymentFee
                                    },
                                    model: {
                                      value:
                                        _vm.$store.state.podAccount
                                          .accountPreferences
                                          .defaultLatePaymentFee,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$store.state.podAccount
                                            .accountPreferences,
                                          "defaultLatePaymentFee",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$store.state.podAccount.accountPreferences.defaultLatePaymentFee"
                                    }
                                  }),
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.dayOptions,
                                      "hide-details": "",
                                      dense: "",
                                      outlined: "",
                                      "persistent-placeholder": "",
                                      label: "Default - payment days"
                                    },
                                    on: {
                                      change: _vm.updateDefaultPaymentDays
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              item === 0
                                                ? _c("span", [
                                                    _vm._v("On Receipt")
                                                  ])
                                                : _vm._e(),
                                              item !== 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(item) + " days"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "item",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              item === 0
                                                ? _c("span", [
                                                    _vm._v("On Receipt")
                                                  ])
                                                : _vm._e(),
                                              item !== 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(item) + " days"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2647635356
                                    ),
                                    model: {
                                      value:
                                        _vm.$store.state.podAccount
                                          .accountPreferences
                                          .defaultPaymentDays,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$store.state.podAccount
                                            .accountPreferences,
                                          "defaultPaymentDays",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$store.state.podAccount.accountPreferences.defaultPaymentDays"
                                    }
                                  }),
                                  _c("v-select", {
                                    attrs: {
                                      "persistent-placeholder": "",
                                      "hide-details": "",
                                      dense: "",
                                      outlined: "",
                                      items: _vm.paidByOptions,
                                      label:
                                        "Default - pass Stripe credit card fees to client?"
                                    },
                                    on: {
                                      change: _vm.updateDefaultWhoPaysCardFees
                                    },
                                    model: {
                                      value:
                                        _vm.$store.state.podAccount
                                          .accountPreferences
                                          .defaultWhoPaysCardFees,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$store.state.podAccount
                                            .accountPreferences,
                                          "defaultWhoPaysCardFees",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$store.state.podAccount.accountPreferences.defaultWhoPaysCardFees"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "max-width": "fit-content"
                                      }
                                    },
                                    [
                                      _c("v-switch", {
                                        directives: [
                                          {
                                            name: "tippy",
                                            rawName: "v-tippy",
                                            value: {
                                              content:
                                                "When a new contact is created, portal access will be enabled by default"
                                            },
                                            expression:
                                              "{\n\t\t\t\t\t\t\t\t\t\tcontent: 'When a new contact is created, portal access will be enabled by default',\n\t\t\t\t\t\t\t\t\t}"
                                          }
                                        ],
                                        attrs: {
                                          label:
                                            "Contacts -Default Portal Access"
                                        },
                                        on: {
                                          change: _vm.updateDefaultPortalAccess
                                        },
                                        model: {
                                          value:
                                            _vm.$store.state.podAccount
                                              .accountPreferences
                                              .defaultPortalAccess,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$store.state.podAccount
                                                .accountPreferences,
                                              "defaultPortalAccess",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$store.state.podAccount.accountPreferences.defaultPortalAccess"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-6 mb-2",
                                      attrs: { block: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.updateDefaultClientModal = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "Update all clients with these settings"
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "font-12 font-italic" },
                                    [
                                      _vm._v(
                                        " Note: You will be able to select which values are updated on the next screen "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.currentView === "invoice" && _vm.$store.getters.isAccountOwner
            ? _c(
                "div",
                [
                  _c(
                    "v-container",
                    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-3", attrs: { cols: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "column-format gap-3" },
                                [
                                  _c("div", { staticClass: "text-left" }, [
                                    _vm._v("Invoice settings")
                                  ]),
                                  _c("v-select", {
                                    attrs: {
                                      label: "Invoice reminder frequency",
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      dense: "",
                                      outlined: "",
                                      items: _vm.reminderOptions,
                                      "item-text": "label",
                                      "item-value": "value"
                                    },
                                    on: {
                                      change: _vm.invoiceDueNotificationUpdated
                                    },
                                    model: {
                                      value:
                                        _vm.$store.state.podAccount
                                          .accountPreferences
                                          .invoiceDueNotification,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$store.state.podAccount
                                            .accountPreferences,
                                          "invoiceDueNotification",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$store.state.podAccount.accountPreferences.invoiceDueNotification"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "number",
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      label: "Stripe card processing fees",
                                      suffix: "%",
                                      dense: "",
                                      outlined: ""
                                    },
                                    on: {
                                      change: _vm.invoiceCardFeeRateUpdated
                                    },
                                    model: {
                                      value:
                                        _vm.$store.state.podAccount
                                          .accountPreferences.cardFeeRate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$store.state.podAccount
                                            .accountPreferences,
                                          "cardFeeRate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$store.state.podAccount.accountPreferences.cardFeeRate"
                                    }
                                  }),
                                  _c("v-autocomplete", {
                                    attrs: {
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      dense: "",
                                      outlined: "",
                                      label: "PDF / Email locale formatting",
                                      items: _vm.locales,
                                      "item-text": "name",
                                      "item-value": "locale",
                                      clearable: ""
                                    },
                                    on: { change: _vm.defaultLocaleUpdated },
                                    model: {
                                      value:
                                        _vm.$store.state.podAccount
                                          .accountPreferences.defaultLocale,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$store.state.podAccount
                                            .accountPreferences,
                                          "defaultLocale",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$store.state.podAccount.accountPreferences.defaultLocale"
                                    }
                                  }),
                                  _c("v-checkbox", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      "hide-details": "",
                                      dense: "",
                                      label: "Use A4 Page Size on PDF Invoice"
                                    },
                                    on: {
                                      change: _vm.invoicePdfA4PageSizeUpdated
                                    },
                                    model: {
                                      value:
                                        _vm.$store.state.podAccount
                                          .accountPreferences
                                          .invoicePdfA4PageSize,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$store.state.podAccount
                                            .accountPreferences,
                                          "invoicePdfA4PageSize",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$store.state.podAccount.accountPreferences.invoicePdfA4PageSize"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "column-format gap-3" },
                                [
                                  _c("div", { staticClass: "text-left mt-5" }, [
                                    _vm._v("Default email templates")
                                  ]),
                                  _vm.emailTemplates
                                    ? _c("v-autocomplete", {
                                        attrs: {
                                          disabled:
                                            _vm.emailTemplates.length === 0 ||
                                            !_vm.isPaidAccount,
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          outlined: "",
                                          label: "New invoice",
                                          items: _vm.emailTemplates,
                                          "item-value": "id",
                                          "item-text": "name",
                                          clearable: ""
                                        },
                                        on: {
                                          change: _vm.emailTemplatesUpdated
                                        },
                                        model: {
                                          value:
                                            _vm.$store.state.podAccount
                                              .accountPreferences
                                              .invoiceEmailTemplate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$store.state.podAccount
                                                .accountPreferences,
                                              "invoiceEmailTemplate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$store.state.podAccount.accountPreferences.invoiceEmailTemplate"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.emailTemplates
                                    ? _c("v-autocomplete", {
                                        attrs: {
                                          disabled:
                                            _vm.emailTemplates.length === 0 ||
                                            !_vm.isPaidAccount,
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          outlined: "",
                                          label: "Payment confirmation",
                                          items: _vm.emailTemplates,
                                          "item-value": "id",
                                          "item-text": "name",
                                          clearable: ""
                                        },
                                        on: {
                                          change: _vm.emailTemplatesUpdated
                                        },
                                        model: {
                                          value:
                                            _vm.$store.state.podAccount
                                              .accountPreferences
                                              .paymentConfirmationTemplate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$store.state.podAccount
                                                .accountPreferences,
                                              "paymentConfirmationTemplate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$store.state.podAccount.accountPreferences.paymentConfirmationTemplate"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.emailTemplates
                                    ? _c("v-autocomplete", {
                                        attrs: {
                                          disabled:
                                            _vm.emailTemplates.length === 0 ||
                                            !_vm.isPaidAccount,
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          outlined: "",
                                          label: "Due date approaching",
                                          items: _vm.emailTemplates,
                                          "item-value": "id",
                                          "item-text": "name",
                                          clearable: ""
                                        },
                                        on: {
                                          change: _vm.emailTemplatesUpdated
                                        },
                                        model: {
                                          value:
                                            _vm.$store.state.podAccount
                                              .accountPreferences
                                              .upcomingInvoiceTemplate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$store.state.podAccount
                                                .accountPreferences,
                                              "upcomingInvoiceTemplate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$store.state.podAccount.accountPreferences.upcomingInvoiceTemplate"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.emailTemplates
                                    ? _c("v-autocomplete", {
                                        attrs: {
                                          disabled:
                                            _vm.emailTemplates.length === 0 ||
                                            !_vm.isPaidAccount,
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          outlined: "",
                                          label: "Past due notification",
                                          items: _vm.emailTemplates,
                                          "item-value": "id",
                                          "item-text": "name",
                                          clearable: ""
                                        },
                                        on: {
                                          change: _vm.emailTemplatesUpdated
                                        },
                                        model: {
                                          value:
                                            _vm.$store.state.podAccount
                                              .accountPreferences
                                              .pastDueInvoiceTemplate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$store.state.podAccount
                                                .accountPreferences,
                                              "pastDueInvoiceTemplate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$store.state.podAccount.accountPreferences.pastDueInvoiceTemplate"
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "text-left mt-1 mb-1" },
                                    [_vm._v("Payment instructions")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "h-outline" },
                                    [
                                      _c("editor", {
                                        ref: "editor",
                                        staticClass: "mt-1 px-2 text-left",
                                        staticStyle: {
                                          width: "100%",
                                          "min-height": "100px",
                                          "border-bottom": "none"
                                        },
                                        attrs: {
                                          "api-key":
                                            _vm.$store.getters.getTinyMceKey,
                                          "initial-value":
                                            _vm.$store.state.podAccount
                                              .payInstructions,
                                          inline: true,
                                          init: _vm.mceConfigText,
                                          spellcheck: "true"
                                        },
                                        on: {
                                          input: _vm.handlePayInstructionsInput
                                        }
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "my-2",
                                          attrs: {
                                            disabled: !_vm.payInstructionsChanged,
                                            outlined: "",
                                            color: "secondary",
                                            elevation: "0"
                                          },
                                          on: {
                                            click: _vm.payInstructionsUpdated
                                          }
                                        },
                                        [_vm._v("Save payment instructions")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-3", attrs: { cols: "6" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center"
                                },
                                [
                                  _c("div", { staticClass: "text-left" }, [
                                    _vm._v("Invoice numbering")
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "primary-action mr-n4 font-12",
                                          attrs: { text: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.invoiceNumberModal = true
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("global.edit")))]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-outline text-left py-4 px-3 pointer",
                                  on: {
                                    click: function($event) {
                                      _vm.invoiceNumberModal = true
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-12 gray_80--text brand-medium"
                                    },
                                    [_vm._v("Scheme")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-14 gray_90--text brand-semilight"
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.formattedFormat) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-12 gray_80--text brand-medium mt-2"
                                    },
                                    [_vm._v("Starting number")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-14 gray_90--text brand-semilight"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$store.state.podAccount
                                              .accountPreferences
                                              .invoiceNumberSeed
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-12 gray_80--text brand-medium mt-2"
                                    },
                                    [_vm._v("Invoice number by client?")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-14 gray_90--text brand-semilight"
                                    },
                                    [
                                      _vm.$store.state.podAccount
                                        .accountPreferences
                                        .invoiceNumberPerClient
                                        ? _c("span", [
                                            _vm._v(
                                              " Number counts up by client "
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              " Number counts up globally (not by client) "
                                            )
                                          ])
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-12 gray_80--text brand-medium mt-2"
                                    },
                                    [_vm._v("Invoice number by year?")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-14 gray_90--text brand-semilight"
                                    },
                                    [
                                      _vm.$store.state.podAccount
                                        .accountPreferences.invoiceNumberPerYear
                                        ? _c("span", [
                                            _vm._v(
                                              " Number resets on " +
                                                _vm._s(
                                                  _vm.months[
                                                    _vm.$store.state.podAccount
                                                      .accountPreferences
                                                      .fiscalYearStartMonth - 1
                                                  ].text
                                                ) +
                                                " 1st "
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              " Number does not reset every year "
                                            )
                                          ])
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "column-format gap-3" },
                                [
                                  _c("div", { staticClass: "text-left mt-4" }, [
                                    _vm._v("Compliance Settings")
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      label: "Compliance Header",
                                      placeholder: "Header text above logo",
                                      dense: "",
                                      outlined: ""
                                    },
                                    on: { change: _vm.invoiceHeaderUpdated },
                                    model: {
                                      value:
                                        _vm.$store.state.podAccount
                                          .accountPreferences
                                          .invoiceDocumentHeader,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$store.state.podAccount
                                            .accountPreferences,
                                          "invoiceDocumentHeader",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$store.state.podAccount.accountPreferences.invoiceDocumentHeader"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      label: "Paid invoice compliance Header",
                                      placeholder: "Header text above logo",
                                      dense: "",
                                      outlined: ""
                                    },
                                    on: {
                                      change: _vm.paidInvoiceHeaderUpdated
                                    },
                                    model: {
                                      value:
                                        _vm.$store.state.podAccount
                                          .accountPreferences
                                          .paidInvoiceDocumentHeader,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$store.state.podAccount
                                            .accountPreferences,
                                          "paidInvoiceDocumentHeader",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$store.state.podAccount.accountPreferences.paidInvoiceDocumentHeader"
                                    }
                                  }),
                                  _c("div", { staticClass: "text-left mt-4" }, [
                                    _vm._v("Tax preferences")
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "number",
                                      disabled: !_vm.$store.state.podAccount
                                        .accountPreferences.invoiceUseTax,
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      label: "Default rate",
                                      suffix: "%",
                                      dense: "",
                                      outlined: ""
                                    },
                                    on: {
                                      change: _vm.invoiceDefaultTaxUpdated
                                    },
                                    model: {
                                      value:
                                        _vm.$store.state.podAccount
                                          .accountPreferences.defaultTaxRate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$store.state.podAccount
                                            .accountPreferences,
                                          "defaultTaxRate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$store.state.podAccount.accountPreferences.defaultTaxRate"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      disabled: !_vm.$store.state.podAccount
                                        .accountPreferences.invoiceUseTax,
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      label: "Tax label",
                                      placeholder: "Tax, VAT, ISV, etc",
                                      dense: "",
                                      outlined: ""
                                    },
                                    on: { change: _vm.invoiceTaxLabelUpdated },
                                    model: {
                                      value:
                                        _vm.$store.state.podAccount
                                          .accountPreferences.taxLabel,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$store.state.podAccount
                                            .accountPreferences,
                                          "taxLabel",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$store.state.podAccount.accountPreferences.taxLabel"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-checkbox", {
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  label: "Tax enabled on invoices"
                                },
                                on: { change: _vm.invoiceUseTaxUpdated },
                                model: {
                                  value:
                                    _vm.$store.state.podAccount
                                      .accountPreferences.invoiceUseTax,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$store.state.podAccount
                                        .accountPreferences,
                                      "invoiceUseTax",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$store.state.podAccount.accountPreferences.invoiceUseTax"
                                }
                              }),
                              _c("v-checkbox", {
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  label: "Show tax on 0% invoices"
                                },
                                on: {
                                  change: _vm.showTaxWithZeroPercentUpdated
                                },
                                model: {
                                  value:
                                    _vm.$store.state.podAccount
                                      .accountPreferences
                                      .showTaxWithZeroPercent,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$store.state.podAccount
                                        .accountPreferences,
                                      "showTaxWithZeroPercent",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$store.state.podAccount.accountPreferences.showTaxWithZeroPercent"
                                }
                              }),
                              _c("v-checkbox", {
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  label: "Apply tax to late fees"
                                },
                                on: {
                                  change: _vm.invoiceLateFeeTaxableUpdated
                                },
                                model: {
                                  value:
                                    _vm.$store.state.podAccount
                                      .accountPreferences.invoiceLateFeeTaxable,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$store.state.podAccount
                                        .accountPreferences,
                                      "invoiceLateFeeTaxable",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$store.state.podAccount.accountPreferences.invoiceLateFeeTaxable"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.currentView === "agreement" && _vm.$store.getters.isAccountOwner
            ? _c(
                "div",
                [
                  _c(
                    "v-container",
                    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-3", attrs: { cols: "6" } },
                            [
                              _c("div", { staticClass: "text-left" }, [
                                _vm._v("Enable geo location on Signatures")
                              ]),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "mt-2 pt-0",
                                  attrs: { dense: "", "hide-details": "" },
                                  on: { change: _vm.geoLocationUpdated },
                                  model: {
                                    value:
                                      _vm.$store.state.podAccount
                                        .accountPreferences.proposalPreferences
                                        .enableGeoLocationOnSignature,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$store.state.podAccount
                                          .accountPreferences
                                          .proposalPreferences,
                                        "enableGeoLocationOnSignature",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n\t\t\t\t\t\t\t\t$store.state.podAccount.accountPreferences.proposalPreferences.enableGeoLocationOnSignature\n\t\t\t\t\t\t\t"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: true, label: "Enabled" }
                                  }),
                                  _c("v-radio", {
                                    staticClass: "h-outline pa-2",
                                    attrs: { value: false, label: "Disabled" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.currentView === "fonts" && _vm.isAccountFullUser
            ? _c(
                "div",
                [
                  _c(
                    "v-container",
                    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("custom-fonts")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.invoiceNumberModal, persistent: false },
              on: {
                close: function($event) {
                  _vm.invoiceNumberModal = false
                }
              }
            },
            [
              _vm.invoiceNumberModal
                ? _c("invoice-number-format-modal", {
                    on: {
                      close: function($event) {
                        _vm.invoiceNumberModal = false
                      },
                      "set-account-info": function($event) {
                        return _vm.handleInvoiceNumberFormatModalUpdate($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "basic-modal",
            {
              staticStyle: { "max-width": "400px" },
              attrs: {
                dialog: _vm.updateDefaultClientModal,
                persistent: false
              },
              on: {
                close: function($event) {
                  _vm.updateDefaultClientModal = false
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "column-format gap-4 pa-4",
                  staticStyle: { "max-width": "400px" }
                },
                [
                  _c("div", [
                    _vm._v(
                      " What fields would you like to update on your clients? This will update all of your client records with the default values specified. "
                    )
                  ]),
                  _c(
                    "div",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          label: "Hourly rate"
                        },
                        model: {
                          value: _vm.clientUpdate.hourlyRate,
                          callback: function($$v) {
                            _vm.$set(_vm.clientUpdate, "hourlyRate", $$v)
                          },
                          expression: "clientUpdate.hourlyRate"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          label: "Late fee"
                        },
                        model: {
                          value: _vm.clientUpdate.lateFee,
                          callback: function($$v) {
                            _vm.$set(_vm.clientUpdate, "lateFee", $$v)
                          },
                          expression: "clientUpdate.lateFee"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          label: "Payment days"
                        },
                        model: {
                          value: _vm.clientUpdate.paymentDays,
                          callback: function($$v) {
                            _vm.$set(_vm.clientUpdate, "paymentDays", $$v)
                          },
                          expression: "clientUpdate.paymentDays"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          label: "Stripe fees"
                        },
                        model: {
                          value: _vm.clientUpdate.stripeFees,
                          callback: function($$v) {
                            _vm.$set(_vm.clientUpdate, "stripeFees", $$v)
                          },
                          expression: "clientUpdate.stripeFees"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          label: "Portal access"
                        },
                        model: {
                          value: _vm.clientUpdate.portalAccess,
                          callback: function($$v) {
                            _vm.$set(_vm.clientUpdate, "portalAccess", $$v)
                          },
                          expression: "clientUpdate.portalAccess"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        color: "primary",
                        disabled:
                          !_vm.clientUpdate.hourlyRate &&
                          !_vm.clientUpdate.lateFee &&
                          !_vm.clientUpdate.paymentDays &&
                          !_vm.clientUpdate.stripeFees &&
                          !_vm.clientUpdate.portalAccess
                      },
                      on: { click: _vm.updateClientDefaults }
                    },
                    [_vm._v("Update")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }