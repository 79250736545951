var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-8" },
    [
      _vm.customEmail
        ? _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              !_vm.customEmail.active
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("div", { staticClass: "row-format gap-3" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row-format centered px-3 py-1 pointer button-box",
                              on: { click: _vm.connectGoogleOAuth }
                            },
                            [
                              _c("v-icon", [_vm._v("$googleColor")]),
                              _c(
                                "div",
                                { staticClass: "font-14 brand-medium ml-3" },
                                [
                                  _vm._v("Connect Google Account"),
                                  _c("br"),
                                  _vm._v("GMail / Google Workspace")
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row-format centered px-3 py-1 pointer button-box",
                              on: { click: _vm.connectMicrosoftEmail }
                            },
                            [
                              _c("v-icon", [_vm._v("$microsoft")]),
                              _c(
                                "div",
                                { staticClass: "font-14 brand-medium ml-3" },
                                [
                                  _vm._v(" Connect Microsoft Account"),
                                  _c("br"),
                                  _vm._v("Office365 / Outlook / Hotmail ")
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.customEmail.active &&
              _vm.customEmail.authenticationType === "OAuth"
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("div", { staticClass: "row-format" }, [
                          _c("div", { staticClass: "font-14 text-left" }, [
                            _vm.customEmail.active
                              ? _c(
                                  "div",
                                  [
                                    _vm._v(" Email settings verified "),
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "success", small: "" }
                                      },
                                      [_vm._v("$success")]
                                    )
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c(
                                    "div",
                                    [
                                      _c("v-icon", [_vm._v("$alert")]),
                                      _vm._v(" Email settings not active")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "font-italic font-12 mt-2" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.customEmail.errorMessage)
                                      )
                                    ]
                                  )
                                ]),
                            _c("div", [
                              _vm._v(
                                "Type: " + _vm._s(_vm.customEmail.oauthProvider)
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "Account: " + _vm._s(_vm.customEmail.oauthName)
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "Email: " + _vm._s(_vm.customEmail.oauthEmail)
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "ml-auto" }, [
                            _vm.customEmail.oauthProvider === "Office365"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row-format centered px-3 py-1 pointer button-box",
                                    on: { click: _vm.connectMicrosoftEmail }
                                  },
                                  [
                                    _c("v-icon", [_vm._v("$microsoft")]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "font-14 brand-medium ml-3"
                                      },
                                      [
                                        _vm._v(" Reconnect Microsoft Account"),
                                        _c("br"),
                                        _vm._v("Office365 / Outlook / Hotmail ")
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.customEmail.oauthProvider === "Google"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row-format centered px-3 py-1 pointer button-box",
                                    on: { click: _vm.connectGoogleOAuth }
                                  },
                                  [
                                    _c("v-icon", [_vm._v("$googleColor")]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "font-14 brand-medium ml-3"
                                      },
                                      [
                                        _vm._v(" Reconnect Google Account"),
                                        _c("br"),
                                        _vm._v("Gmail / Google Workspace ")
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ])
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "mt-8", attrs: { cols: "12" } },
                        [
                          _c(
                            "div",
                            { staticClass: "column-format gap-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "From address (optional)",
                                  placeholder: "First Last <first@email.com>",
                                  "persistent-placeholder": "",
                                  "hide-details": "",
                                  dense: "",
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.customEmail.fromEmail,
                                  callback: function($$v) {
                                    _vm.$set(_vm.customEmail, "fromEmail", $$v)
                                  },
                                  expression: "customEmail.fromEmail"
                                }
                              }),
                              _c("editor", {
                                ref: "editor",
                                staticClass: "pa-2 text-left",
                                staticStyle: {
                                  width: "100%",
                                  "min-height": "100px",
                                  "border-bottom": "none"
                                },
                                attrs: {
                                  "api-key": _vm.$store.getters.getTinyMceKey,
                                  "initial-value": _vm.customEmail.signature,
                                  inline: false,
                                  init: _vm.mceConfigText,
                                  spellcheck: true,
                                  placeholder: "Signature"
                                },
                                on: { input: _vm.handleSignatureUpdated }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "mt-8", attrs: { cols: "12" } },
                        [
                          _c(
                            "div",
                            { staticClass: "row-format gap-2 centered" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "super-action",
                                  attrs: { width: "150" },
                                  on: { click: _vm.saveFromName }
                                },
                                [_vm._v("Save")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.confirmDelete }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "material-symbols-rounded" },
                                    [_vm._v("delete")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "div",
                          { staticClass: "column-format gap-3" },
                          [
                            _vm.customEmail.active
                              ? _c(
                                  "div",
                                  { staticClass: "brand-medium mt-8" },
                                  [_vm._v("Custom email settings")]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "brand-medium mt-8" },
                                  [_vm._v("or Configure custom email settings")]
                                ),
                            _c("v-form", [
                              _c(
                                "div",
                                { staticClass: "column-format gap-3" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Username",
                                      "persistent-placeholder": "",
                                      "hide-details": "",
                                      dense: "",
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.customEmail.username,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.customEmail,
                                          "username",
                                          $$v
                                        )
                                      },
                                      expression: "customEmail.username"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Password",
                                      type: "password",
                                      "persistent-placeholder": "",
                                      "hide-details": "",
                                      dense: "",
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "From (optional)",
                                      placeholder:
                                        "First Last <first@email.com>",
                                      "persistent-placeholder": "",
                                      "hide-details": "",
                                      dense: "",
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.customEmail.fromEmail,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.customEmail,
                                          "fromEmail",
                                          $$v
                                        )
                                      },
                                      expression: "customEmail.fromEmail"
                                    }
                                  }),
                                  _c("editor", {
                                    ref: "editor",
                                    staticClass: "pa-2 text-left",
                                    staticStyle: {
                                      width: "100%",
                                      "min-height": "100px",
                                      "border-bottom": "none"
                                    },
                                    attrs: {
                                      "api-key":
                                        _vm.$store.getters.getTinyMceKey,
                                      "initial-value":
                                        _vm.customEmail.signature,
                                      inline: false,
                                      init: _vm.mceConfigText,
                                      spellcheck: true,
                                      placeholder: "Signature"
                                    },
                                    on: { input: _vm.handleSignatureUpdated }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "SMTP Server",
                                      "persistent-placeholder": "",
                                      "hide-details": "",
                                      dense: "",
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.customEmail.smtpHost,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.customEmail,
                                          "smtpHost",
                                          $$v
                                        )
                                      },
                                      expression: "customEmail.smtpHost"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "SMTP Port",
                                      type: "number",
                                      "persistent-placeholder": "",
                                      "hide-details": "",
                                      dense: "",
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.customEmail.smtpPort,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.customEmail,
                                          "smtpPort",
                                          $$v
                                        )
                                      },
                                      expression: "customEmail.smtpPort"
                                    }
                                  }),
                                  _c("v-select", {
                                    attrs: {
                                      label: "Connection Security",
                                      "persistent-placeholder": "",
                                      "hide-details": "",
                                      dense: "",
                                      outlined: "",
                                      items: _vm.connectionSecurity
                                    },
                                    model: {
                                      value: _vm.customEmail.connectionSecurity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.customEmail,
                                          "connectionSecurity",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "customEmail.connectionSecurity"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "row-format centered mt-4",
                                staticStyle: { gap: "8px" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "super-action",
                                    attrs: { width: "150" },
                                    on: { click: _vm.saveCustomEmail }
                                  },
                                  [_vm._v("Save")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: { click: _vm.confirmDelete }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "material-symbols-rounded"
                                      },
                                      [_vm._v("delete")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "font-14 mt-4" }, [
                              _vm.customEmail.active
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "success" } },
                                        [_vm._v("$success")]
                                      ),
                                      _vm._v(" Email settings verified")
                                    ],
                                    1
                                  )
                                : _c("div", [
                                    _c(
                                      "div",
                                      [
                                        _c("v-icon", [_vm._v("$alert")]),
                                        _vm._v(" Email settings not active")
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "font-italic font-12 mt-2"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.customEmail.errorMessage)
                                        )
                                      ]
                                    )
                                  ])
                            ])
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }