var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          staticStyle: { "overflow-y": "auto" },
          attrs: {
            "nudge-bottom": 34,
            "min-width": "250",
            "max-width": "250",
            "close-on-content-click": false,
            "close-on-click": true,
            "content-class": "filter-menu-class"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "standard-action",
                        attrs: { elevation: "0", outlined: "", color: "" }
                      },
                      on
                    ),
                    [
                      _c("span", {
                        class:
                          "font-14 " +
                          (!_vm.filtersAreEmpty ? "" : "font-gray_60") +
                          " brand-medium",
                        domProps: { innerHTML: _vm._s(_vm.filterLabel) }
                      }),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: !_vm.filtersAreEmpty ? "" : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "filter-option-box text-left",
              staticStyle: { border: "" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "row-format",
                  on: {
                    click: function($event) {
                      _vm.clientExpanded = !_vm.clientExpanded
                    }
                  }
                },
                [
                  _c("div", { staticClass: "pr-4 font-14" }, [
                    _vm._v(" Client / Prospect "),
                    _vm.filter.clients.length
                      ? _c(
                          "span",
                          { staticClass: "brand-medium font-gray_60" },
                          [
                            _vm._v(
                              "[" + _vm._s(_vm.filter.clients.length) + "]"
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticStyle: {
                        "margin-left": "auto",
                        "margin-top": "1px",
                        "margin-right": "4px"
                      },
                      attrs: { small: "" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.clientExpanded ? "$arrowUp" : "$arrowDown")
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.clientExpanded
                ? _c(
                    "div",
                    [
                      _c("v-checkbox", {
                        staticClass: "mx-0 my-2 pa-0",
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          indeterminate: _vm.clientsIndeterminate
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "client-label row-format pointer",
                                      staticStyle: { "align-content": "center" }
                                    },
                                    [
                                      _c("div", { staticClass: "tree-label" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "calendar.filter.select-deselect"
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3960988798
                        ),
                        model: {
                          value: _vm.clientsSelected,
                          callback: function($$v) {
                            _vm.clientsSelected = $$v
                          },
                          expression: "clientsSelected"
                        }
                      }),
                      _vm._l(_vm.clients, function(client) {
                        return _c("v-checkbox", {
                          key: client.id,
                          staticClass: "mx-0 my-2 pa-0",
                          attrs: {
                            value: client.id,
                            "hide-details": "",
                            dense: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "client-label row-format pointer",
                                        staticStyle: {
                                          "align-content": "center"
                                        }
                                      },
                                      [
                                        _c("client-avatar", {
                                          attrs: {
                                            small: true,
                                            left: "",
                                            client: client,
                                            "disable-click": true
                                          }
                                        }),
                                        _c("div", { staticClass: "truncate" }, [
                                          _vm._v(_vm._s(client.name))
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.filter.clients,
                            callback: function($$v) {
                              _vm.$set(_vm.filter, "clients", $$v)
                            },
                            expression: "filter.clients"
                          }
                        })
                      })
                    ],
                    2
                  )
                : _vm._e()
            ]
          ),
          _c("div", { staticClass: "filter-option-box" }, [
            _c(
              "div",
              {
                staticClass: "row-format",
                on: {
                  click: function($event) {
                    _vm.statusExpanded = !_vm.statusExpanded
                  }
                }
              },
              [
                _c("div", { staticClass: "font-14" }, [
                  _vm._v(" Stages "),
                  _vm.filter.stages.length
                    ? _c("span", { staticClass: "brand-medium font-gray_60" }, [
                        _vm._v("[" + _vm._s(_vm.filter.stages.length) + "]")
                      ])
                    : _vm._e()
                ]),
                _c(
                  "v-icon",
                  {
                    staticStyle: {
                      "margin-left": "auto",
                      "margin-top": "1px",
                      "margin-right": "4px"
                    },
                    attrs: { small: "" }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.statusExpanded ? "$arrowUp" : "$arrowDown")
                    )
                  ]
                )
              ],
              1
            ),
            _vm.statusExpanded
              ? _c(
                  "div",
                  [
                    _c("v-checkbox", {
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        indeterminate: _vm.stagesIndeterminate
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "client-label row-format pointer",
                                    staticStyle: { "align-content": "center" }
                                  },
                                  [
                                    _c("div", { staticClass: "tree-label" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "calendar.filter.select-deselect"
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3960988798
                      ),
                      model: {
                        value: _vm.stagesSelected,
                        callback: function($$v) {
                          _vm.stagesSelected = $$v
                        },
                        expression: "stagesSelected"
                      }
                    }),
                    _vm._l(_vm.stages, function(stage) {
                      return _c("v-checkbox", {
                        key: stage.id,
                        attrs: {
                          value: stage.id,
                          "hide-details": "",
                          dense: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "row-format align-center",
                                      staticStyle: { width: "100%" }
                                    },
                                    [
                                      _c("div", {
                                        style:
                                          "width:12px; height: 12px; border-radius: 2px; background-color:" +
                                          stage.hexColor
                                      }),
                                      _c("div", {
                                        staticClass:
                                          "status-label ml-2 ellipsis",
                                        domProps: {
                                          innerHTML: _vm._s(stage.label)
                                        }
                                      })
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.filter.stages,
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "stages", $$v)
                          },
                          expression: "filter.stages"
                        }
                      })
                    })
                  ],
                  2
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "filter-option-box" }, [
            _c(
              "div",
              {
                staticClass: "row-format",
                on: {
                  click: function($event) {
                    _vm.sentimentExpanded = !_vm.sentimentExpanded
                  }
                }
              },
              [
                _c("div", { staticClass: "font-14" }, [
                  _vm._v("Confidence "),
                  _vm.filter.sentimentType !== "Any"
                    ? _c("span", { staticClass: "brand-medium font-gray_60" }, [
                        _vm._v("[1]")
                      ])
                    : _vm._e()
                ]),
                _c(
                  "v-icon",
                  {
                    staticStyle: {
                      "margin-left": "auto",
                      "margin-top": "1px",
                      "margin-right": "4px"
                    },
                    attrs: { small: "" }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.sentimentExpanded ? "$arrowUp" : "$arrowDown")
                    )
                  ]
                )
              ],
              1
            ),
            _vm.sentimentExpanded
              ? _c(
                  "div",
                  { staticClass: "row-format gap-2" },
                  [
                    _c("v-select", {
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        items: ["Any", ">=", "<=", "="]
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(ref) {
                              var item = ref.item
                              return [_c("div", [_vm._v(_vm._s(item))])]
                            }
                          }
                        ],
                        null,
                        false,
                        3160188630
                      ),
                      model: {
                        value: _vm.filter.sentimentType,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "sentimentType", $$v)
                        },
                        expression: "filter.sentimentType"
                      }
                    }),
                    _vm.filter.sentimentType !== "Any"
                      ? _c("v-select", {
                          attrs: {
                            "hide-details": "",
                            dense: "",
                            items: _vm.confidenceList,
                            "item-value": "value"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "material-symbols-rounded",
                                        attrs: { color: item.color, size: "28" }
                                      },
                                      [_vm._v(" " + _vm._s(item.icon) + " ")]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "fill-width row-format centered"
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "material-symbols-rounded",
                                            attrs: {
                                              color: item.color,
                                              size: "28"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.icon))]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2149679425
                          ),
                          model: {
                            value: _vm.filter.sentimentValue,
                            callback: function($$v) {
                              _vm.$set(_vm.filter, "sentimentValue", $$v)
                            },
                            expression: "filter.sentimentValue"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "filter-option-box" }, [
            _c(
              "div",
              {
                staticClass: "row-format",
                on: {
                  click: function($event) {
                    _vm.valueExpanded = !_vm.valueExpanded
                  }
                }
              },
              [
                _c("div", { staticClass: "font-14" }, [
                  _vm._v("Value "),
                  _vm.filter.valueType !== "Any"
                    ? _c("span", { staticClass: "brand-medium font-gray_60" }, [
                        _vm._v("[1]")
                      ])
                    : _vm._e()
                ]),
                _c(
                  "v-icon",
                  {
                    staticStyle: {
                      "margin-left": "auto",
                      "margin-top": "1px",
                      "margin-right": "4px"
                    },
                    attrs: { small: "" }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.valueExpanded ? "$arrowUp" : "$arrowDown")
                    )
                  ]
                )
              ],
              1
            ),
            _vm.valueExpanded
              ? _c(
                  "div",
                  { staticClass: "row-format gap-2" },
                  [
                    _c("v-select", {
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        items: ["Any", ">=", "<=", "="]
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(ref) {
                              var item = ref.item
                              return [_c("div", [_vm._v(_vm._s(item))])]
                            }
                          }
                        ],
                        null,
                        false,
                        3160188630
                      ),
                      model: {
                        value: _vm.filter.valueType,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "valueType", $$v)
                        },
                        expression: "filter.valueType"
                      }
                    }),
                    _vm.filter.valueType !== "Any"
                      ? _c("v-text-field", {
                          attrs: {
                            type: "number",
                            "hide-details": "",
                            dense: ""
                          },
                          model: {
                            value: _vm.filter.value,
                            callback: function($$v) {
                              _vm.$set(_vm.filter, "value", $$v)
                            },
                            expression: "filter.value"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "filter-option-box" }, [
            _c(
              "div",
              {
                staticClass: "row-format",
                on: {
                  click: function($event) {
                    _vm.archiveExpanded = !_vm.archiveExpanded
                  }
                }
              },
              [
                _c("div", { staticClass: "font-14" }, [
                  _vm._v("Active "),
                  _vm.filter.archiveStatus !== "active"
                    ? _c("span", { staticClass: "brand-medium font-gray_60" }, [
                        _vm._v("[1]")
                      ])
                    : _vm._e()
                ]),
                _c(
                  "v-icon",
                  {
                    staticStyle: {
                      "margin-left": "auto",
                      "margin-top": "1px",
                      "margin-right": "4px"
                    },
                    attrs: { small: "" }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.archiveExpanded ? "$arrowUp" : "$arrowDown")
                    )
                  ]
                )
              ],
              1
            ),
            _vm.archiveExpanded
              ? _c(
                  "div",
                  { staticClass: "row-format gap-2" },
                  [
                    _c(
                      "v-radio-group",
                      {
                        staticClass: "small-radio",
                        attrs: { "hide-details": "", dense: "" },
                        on: { change: _vm.updated },
                        model: {
                          value: _vm.filter.archiveStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "archiveStatus", $$v)
                          },
                          expression: "filter.archiveStatus"
                        }
                      },
                      [
                        _c("v-radio", {
                          attrs: { label: "Active", value: "active" }
                        }),
                        _c("v-radio", {
                          attrs: { label: "Archived", value: "archive" }
                        }),
                        _c("v-radio", { attrs: { label: "All", value: "all" } })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass: "mx-3 mt-3 mb-3 column-format",
              staticStyle: { gap: "12px" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "pointer font-14 row-format centered",
                  staticStyle: { color: "var(--v-secondary-base)" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("reset-filter")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "", color: "secondary" } }, [
                    _vm._v("$clear")
                  ]),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("projects.filter.clear-filter")))
                  ])
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "super-action",
                  on: {
                    click: function($event) {
                      _vm.menu = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("global.done")))]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }