var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "nudge-bottom": 34,
            "close-on-content-click": false,
            "content-class": "filter-menu-class",
            transition: "slide-y-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "standard-action",
                        attrs: { elevation: "0", outlined: "", color: "" }
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          class:
                            "font-14 " +
                            (_vm.isFilterActive ? "" : "font-gray_60") +
                            " brand-medium"
                        },
                        [_vm._v(_vm._s(_vm.filterLabel))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: _vm.isFilterActive ? "" : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _c("div", { staticClass: "column-format" }, [
            _c(
              "div",
              {
                staticStyle: {
                  "max-height": "calc(90vh - 90px)",
                  "overflow-y": "auto"
                }
              },
              [
                _c("div", { staticClass: "filter-option-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row-format",
                      on: {
                        click: function($event) {
                          _vm.clientExpanded = !_vm.clientExpanded
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "font-14" }, [_vm._v("Client")]),
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            "margin-left": "auto",
                            "margin-top": "1px"
                          },
                          attrs: { small: "" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.clientExpanded ? "$arrowUp" : "$arrowDown"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.clientExpanded
                    ? _c(
                        "div",
                        { staticClass: "assigned-user ellipsis" },
                        _vm._l(_vm.clientList, function(client) {
                          return _c("v-checkbox", {
                            key: client.value,
                            staticClass: "my-0",
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              value: client.value
                            },
                            on: { change: _vm.updated },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-14 brand-semilight",
                                          staticStyle: { "font-size": "14px" }
                                        },
                                        [_vm._v(_vm._s(client.text))]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.filter.clientList,
                              callback: function($$v) {
                                _vm.$set(_vm.filter, "clientList", $$v)
                              },
                              expression: "filter.clientList"
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "filter-option-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row-format",
                      on: {
                        click: function($event) {
                          _vm.projectOwnerExpanded = !_vm.projectOwnerExpanded
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "font-14" }, [
                        _vm._v(_vm._s(_vm.$t("projects.filter.owner")))
                      ]),
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            "margin-left": "auto",
                            "margin-top": "1px"
                          },
                          attrs: { small: "" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.projectOwnerExpanded
                                ? "$arrowUp"
                                : "$arrowDown"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.projectOwnerExpanded
                    ? _c(
                        "div",
                        { staticClass: "assigned-user ellipsis" },
                        _vm._l(
                          _vm.userOptions.filter(function(u) {
                            return u.userId !== "unassigned"
                          }),
                          function(user) {
                            return _c("v-checkbox", {
                              key: user.userId,
                              attrs: {
                                value: user.userId,
                                "hide-details": "",
                                dense: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        user.userId !== "unassigned"
                                          ? _c("assigned-user", {
                                              attrs: {
                                                "assigned-to": user.userId
                                              }
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("projects.unassigned")
                                                )
                                              )
                                            ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.filter.projectOwner,
                                callback: function($$v) {
                                  _vm.$set(_vm.filter, "projectOwner", $$v)
                                },
                                expression: "filter.projectOwner"
                              }
                            })
                          }
                        ),
                        1
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "filter-option-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row-format",
                      on: {
                        click: function($event) {
                          _vm.projectTypeExpanded = !_vm.projectTypeExpanded
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "font-14" }, [
                        _vm._v("Project type")
                      ]),
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            "margin-left": "auto",
                            "margin-top": "1px"
                          },
                          attrs: { small: "" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.projectTypeExpanded
                                ? "$arrowUp"
                                : "$arrowDown"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.projectTypeExpanded
                    ? _c(
                        "div",
                        { staticClass: "assigned-user ellipsis" },
                        _vm._l(_vm.$store.state.projectTypes, function(type) {
                          return _c("v-checkbox", {
                            key: type.id,
                            attrs: {
                              value: type.id,
                              "hide-details": "",
                              dense: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-14 brand-semilight",
                                          staticStyle: { "font-size": "14px" }
                                        },
                                        [_vm._v(_vm._s(type.name))]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.filter.projectType,
                              callback: function($$v) {
                                _vm.$set(_vm.filter, "projectType", $$v)
                              },
                              expression: "filter.projectType"
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e()
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "mx-3 mb-3 mt-3" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-2",
                    staticStyle: { "font-weight": "500" },
                    attrs: {
                      text: "",
                      block: "",
                      color: "secondary",
                      elevation: "0"
                    },
                    on: {
                      click: function($event) {
                        return _vm.clearFilter()
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("$clear")]),
                    _vm._v(" Clear Filters")
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action mt-1",
                    attrs: { color: "primary", block: "" },
                    on: {
                      click: function($event) {
                        _vm.showMenu = false
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("global.done")))]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }