import { render, staticRenderFns } from "./Focus.vue?vue&type=template&id=656ccea9&scoped=true&"
import script from "./Focus.vue?vue&type=script&lang=js&"
export * from "./Focus.vue?vue&type=script&lang=js&"
import style0 from "./Focus.vue?vue&type=style&index=0&id=656ccea9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "656ccea9",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src991023174/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('656ccea9')) {
      api.createRecord('656ccea9', component.options)
    } else {
      api.reload('656ccea9', component.options)
    }
    module.hot.accept("./Focus.vue?vue&type=template&id=656ccea9&scoped=true&", function () {
      api.rerender('656ccea9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/home/Focus.vue"
export default component.exports