var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format wrapper" },
    [
      !_vm.hasTicketAccess
        ? _c("div", { staticClass: "py-8" }, [
            _c("h2", [
              _vm._v("This feature is not enabled for your user account.")
            ])
          ])
        : _c("div", { staticClass: "column-format wrapper" }, [
            _c(
              "div",
              { staticClass: "row-format pb-4 flex-wrap gap-2" },
              [
                _c(
                  "div",
                  { staticClass: "row-format gap-2 flex-wrap" },
                  [
                    _c("inbox-filter", {
                      model: {
                        value: _vm.inboxSearch,
                        callback: function($$v) {
                          _vm.inboxSearch = $$v
                        },
                        expression: "inboxSearch"
                      }
                    }),
                    _c("generic-filter", {
                      attrs: {
                        filter: _vm.filter,
                        items: ["Open", "Closed"],
                        property: "folder"
                      }
                    }),
                    !_vm.clientId
                      ? _c("client-filter", { attrs: { filter: _vm.filter } })
                      : _vm._e(),
                    _c("assigned-to-filter", { attrs: { filter: _vm.filter } }),
                    _c(
                      "v-menu",
                      {
                        staticStyle: { "overflow-y": "auto" },
                        attrs: {
                          "max-height": "90vh",
                          "max-width": "250",
                          "close-on-content-click": false,
                          "close-on-click": true,
                          "content-class": "filter-menu-class",
                          "nudge-bottom": "34"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "standard-action",
                                      attrs: {
                                        elevation: "0",
                                        outlined: "",
                                        color: ""
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "span",
                                      {
                                        class:
                                          "font-14 " +
                                          (_vm.filter.group !== "None"
                                            ? ""
                                            : "font-gray_60") +
                                          " brand-medium"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.filter.group !== "None"
                                              ? _vm.filter.group
                                              : "Group by"
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mx-1",
                                        attrs: {
                                          "x-small": "",
                                          color:
                                            _vm.filter.group !== "None"
                                              ? ""
                                              : "gray_60"
                                        }
                                      },
                                      [_vm._v("$arrowDown")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.menu,
                          callback: function($$v) {
                            _vm.menu = $$v
                          },
                          expression: "menu"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "add-new-dropdown" },
                          _vm._l(_vm.groupItems, function(item) {
                            return _c(
                              "div",
                              {
                                key: item,
                                staticClass: "add-new-item",
                                on: {
                                  click: function($event) {
                                    return _vm.setValue(item)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(item) + " ")]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ],
                  1
                ),
                !_vm.clientId
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "super-action ml-auto",
                        on: {
                          click: function($event) {
                            return _vm.addNew()
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { size: "20" } }, [
                          _vm._v("add")
                        ]),
                        _vm._v(" Add ticket")
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "background-color": "var(--v-white-base)" } },
              [
                _c("ticket-table", {
                  attrs: {
                    communications: _vm.communications,
                    snoozeOptions: _vm.snoozeOptions,
                    filter: _vm.filter
                  },
                  on: {
                    "open-ticket": function($event) {
                      return _vm.handleTicketClick($event)
                    }
                  }
                })
              ],
              1
            )
          ]),
      _vm.showCreateNew
        ? _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: false,
                "max-width": "600px",
                "retain-focus": false,
                eager: true,
                persistent: true
              },
              model: {
                value: _vm.showCreateNew,
                callback: function($$v) {
                  _vm.showCreateNew = $$v
                },
                expression: "showCreateNew"
              }
            },
            [
              _c("create-ticket", {
                attrs: { "client-id": _vm.clientId },
                on: {
                  result: function($event) {
                    return _vm.handleNewTicketCreated($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }