var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { width: "600px", "max-width": "600px" },
      attrs: { id: "" }
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("result")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c("div", { staticClass: "modal-title" }, [
          _vm._v(" Select new parent task ")
        ]),
        _c(
          "div",
          { staticClass: "column-format gap-6 text-left font-14" },
          [
            _c("v-text-field", {
              staticClass: "standard-input",
              attrs: {
                "hide-details": "",
                dense: "",
                outlined: "",
                placeholder: "Search..."
              },
              scopedSlots: _vm._u([
                {
                  key: "prepend-inner",
                  fn: function() {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "material-symbols-rounded font-gray_50"
                        },
                        [_vm._v("search")]
                      )
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.filter,
                callback: function($$v) {
                  _vm.filter = $$v
                },
                expression: "filter"
              }
            }),
            _c(
              "div",
              { staticClass: "column-format text-left" },
              _vm._l(_vm.filteredTasks, function(task) {
                return _c(
                  "div",
                  {
                    key: task.id,
                    class:
                      "column-format text-left py-2 px-2 task-item " +
                      (_vm.selectedTaskId === task.id ? "selected" : ""),
                    on: {
                      click: function($event) {
                        return _vm.selectTask(task)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "text-left" }, [
                      task.client
                        ? _c("span", { staticClass: "font-gray_70 font-12" }, [
                            _vm._v(_vm._s(task.client.name))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      task.project
                        ? _c("span", { staticClass: "font-gray_70 font-12" }, [
                            _vm._v(">> " + _vm._s(task.project.name))
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "text-left" }, [
                      _vm._v(_vm._s(task.name))
                    ])
                  ]
                )
              }),
              0
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "modal-footer row-format gap-2 centered" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              on: {
                click: function($event) {
                  return _vm.$emit("result")
                }
              }
            },
            [
              _c("span", { staticClass: "font-gray_70" }, [
                _vm._v(_vm._s(_vm.$t("global.cancel")))
              ])
            ]
          ),
          _c(
            "v-btn",
            {
              staticClass: "super-action",
              attrs: { disabled: !_vm.selectedTaskId },
              on: {
                click: function($event) {
                  return _vm.transferTask()
                }
              }
            },
            [_vm._v(" Confirm ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }