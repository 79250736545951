var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "new-project" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.handleClose()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          },
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c("div", { staticClass: "modal-padding" }, [
            _c("div", { staticClass: "modal-title" }, [
              _vm._v(" New project"),
              _vm.ourClient
                ? _c("span", [_vm._v(" for " + _vm._s(_vm.ourClient.name))])
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "pb-3 column-format gap-2" },
              [
                !_vm.client
                  ? _c("select-client", {
                      attrs: {
                        "show-avatar": true,
                        "hide-archive": true,
                        dense: true
                      },
                      model: {
                        value: _vm.selectedClientId,
                        callback: function($$v) {
                          _vm.selectedClientId = $$v
                        },
                        expression: "selectedClientId"
                      }
                    })
                  : _vm._e(),
                _vm.assistantMode
                  ? _c("v-select", {
                      attrs: {
                        "item-text": "name",
                        "item-value": "id",
                        items: _vm.projectTypes,
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        "persistent-placeholder": "",
                        label: "Project type"
                      },
                      model: {
                        value: _vm.projectTypeId,
                        callback: function($$v) {
                          _vm.projectTypeId = $$v
                        },
                        expression: "projectTypeId"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            !_vm.assistantMode
              ? _c(
                  "div",
                  {
                    staticClass: "column-format gap-3",
                    staticStyle: { "min-width": "450px" }
                  },
                  [
                    !_vm.proposalId
                      ? _c(
                          "div",
                          { staticClass: "row-format gap-2" },
                          [
                            _c("v-text-field", {
                              staticStyle: { flex: "1" },
                              attrs: {
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                "persistent-placeholder": "",
                                rules: _vm.nameRules,
                                label: _vm.$t("projects.project-name"),
                                placeholder: _vm.$t(
                                  "projects.project-name-placeholder"
                                ),
                                autofocus: ""
                              },
                              model: {
                                value: _vm.projectName,
                                callback: function($$v) {
                                  _vm.projectName = $$v
                                },
                                expression: "projectName"
                              }
                            }),
                            !_vm.deliverablePackageId
                              ? _c("v-select", {
                                  staticStyle: { flex: "1" },
                                  attrs: {
                                    "item-text": "name",
                                    "item-value": "id",
                                    items: _vm.projectTypes,
                                    "hide-details": "",
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    label: "Project type"
                                  },
                                  model: {
                                    value: _vm.projectTypeId,
                                    callback: function($$v) {
                                      _vm.projectTypeId = $$v
                                    },
                                    expression: "projectTypeId"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _c("div", { staticClass: "font-14 text-left" }, [
                          _vm._v(
                            " Please confirm you would like to create projects from this executed agreement. "
                          )
                        ]),
                    _vm.isPaid && _vm.packageList.length && !_vm.proposalId
                      ? _c("v-autocomplete", {
                          attrs: {
                            "hide-details": "",
                            "persistent-placeholder": "",
                            dense: "",
                            outlined: "",
                            label: _vm.$t("templates.start-from-template"),
                            items: _vm.packageList,
                            "item-text": "name",
                            clearable: "",
                            "item-value": "id"
                          },
                          on: { change: _vm.handleTemplateChange },
                          model: {
                            value: _vm.deliverablePackageId,
                            callback: function($$v) {
                              _vm.deliverablePackageId = $$v
                            },
                            expression: "deliverablePackageId"
                          }
                        })
                      : _vm._e(),
                    _vm.deliverablePackageId
                      ? _c("date-selector", {
                          attrs: {
                            standard: true,
                            date: _vm.startDate,
                            label: "Start date"
                          },
                          on: {
                            change: function($event) {
                              _vm.startDate = $event
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.isPaid && _vm.packageList.length && !_vm.proposalId
                      ? _c("div", { staticClass: "font-14" }, [
                          _vm._v("- Or -")
                        ])
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        directives: [{ name: "tippy", rawName: "v-tippy" }],
                        staticStyle: { "min-height": "42px" },
                        attrs: {
                          outlined: "",
                          block: "",
                          color: "gray_50",
                          content:
                            "Create a new project using the AI project assistant."
                        },
                        on: {
                          click: function($event) {
                            return _vm.startAiSession()
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { color: "var(--v-black-base)" } },
                          [_vm._v("Project ✨Assistant")]
                        )
                      ]
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticStyle: { "max-width": "700px", "min-width": "700px" }
                  },
                  [
                    _c("ai-chat-core", {
                      ref: "ChatCore",
                      attrs: {
                        "max-height": "calc(100vh - 350px)",
                        context: _vm.initialAiContext,
                        "initial-prompt": _vm.initialAiPrompt,
                        "finalization-prompt": _vm.finalizationPrompt,
                        "use-case": "ProjectCreation",
                        model: "gpt-4o",
                        "result-format": "json"
                      },
                      on: {
                        result: function($event) {
                          return _vm.aiResult($event)
                        },
                        processing: function($event) {
                          return _vm.setProcessing($event)
                        }
                      }
                    })
                  ],
                  1
                )
          ]),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secondary-action mr-1",
                  attrs: { width: "130" },
                  on: {
                    click: function($event) {
                      return _vm.handleClose()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "font-gray_70" }, [
                    _vm._v(_vm._s(_vm.$t("global.cancel")))
                  ])
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "super-action ml-1",
                  attrs: {
                    width: "130",
                    disabled:
                      !_vm.isValid || !_vm.selectedClientId || _vm.processing
                  },
                  on: {
                    click: function($event) {
                      return _vm.createProject()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.create")) + " ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }