var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "migrate-project" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.handleClose()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _vm.migrationPlan
        ? _c("div", { staticClass: "modal-padding" }, [
            _c("div", { staticClass: "modal-title" }, [
              _vm._v("Update project type for: " + _vm._s(_vm.project.name))
            ]),
            _c(
              "div",
              { staticClass: "pb-3 column-format gap-2" },
              [
                _c(
                  "v-container",
                  { staticClass: "fill-width" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "mb-4 mt-2" },
                      [
                        _c("v-col", { attrs: { cols: "3" } }),
                        _c(
                          "v-col",
                          { staticClass: "row-format", attrs: { cols: "9" } },
                          [
                            _c("v-select", {
                              staticClass: "ml-auto",
                              attrs: {
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                "persistent-placeholder": "",
                                label: "New project type",
                                items: _vm.projectTypes,
                                "item-value": "id",
                                "item-text": "name"
                              },
                              model: {
                                value: _vm.migrationPlan.newProjectTypeId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.migrationPlan,
                                    "newProjectTypeId",
                                    $$v
                                  )
                                },
                                expression: "migrationPlan.newProjectTypeId"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._l(_vm.migrationPlan.statusList, function(status) {
                      return _c(
                        "v-row",
                        { key: status.id, staticClass: "py-1" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "row-format align-center brand-box",
                              style: "background-color: " + status.hexColor,
                              attrs: { cols: "3" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ml-auto nowrap font-14 brand-bold"
                                },
                                [_vm._v(_vm._s(status.label))]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "9" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.newProjectType.statusList,
                                  placeholder: "Maps to...",
                                  "item-text": "label",
                                  "item-value": "id",
                                  "hide-details": "",
                                  dense: "",
                                  outlined: ""
                                },
                                model: {
                                  value: status.newId,
                                  callback: function($$v) {
                                    _vm.$set(status, "newId", $$v)
                                  },
                                  expression: "status.newId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              attrs: { width: "130" },
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [
              _c("span", { staticClass: "font-gray_70" }, [
                _vm._v(_vm._s(_vm.$t("global.cancel")))
              ])
            ]
          ),
          _c(
            "v-btn",
            {
              staticClass: "super-action ml-1",
              attrs: { disabled: !_vm.isValid },
              on: {
                click: function($event) {
                  return _vm.migrateProject()
                }
              }
            },
            [_vm._v(" Update project type ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }