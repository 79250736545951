var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      { staticClass: "table", attrs: { id: "stages-table" } },
      [
        _c("thead", [
          _c("tr", [
            _c("th", { staticStyle: { width: "50px" } }),
            _c("th", [_vm._v("Label")]),
            _c(
              "th",
              [
                _vm._v(" Client action "),
                _c(
                  "v-icon",
                  {
                    directives: [
                      {
                        name: "tippy",
                        rawName: "v-tippy",
                        value: { content: _vm.$t("projects.action-icon") },
                        expression: "{ content: $t('projects.action-icon') }"
                      }
                    ],
                    staticClass: "material-symbols-rounded",
                    attrs: { small: "" }
                  },
                  [_vm._v("help")]
                )
              ],
              1
            ),
            _c("th", [_vm._v("Complete")]),
            _c("th", [_vm._v("Highlight color")]),
            _c("th", { staticStyle: { width: "50px" } })
          ])
        ]),
        _vm.statusList
          ? _c(
              "draggable",
              {
                attrs: { group: "options", tag: "tbody", handle: ".drag" },
                on: { change: _vm.saveSettings },
                model: {
                  value: _vm.statusList,
                  callback: function($$v) {
                    _vm.statusList = $$v
                  },
                  expression: "statusList"
                }
              },
              _vm._l(_vm.statusList, function(status) {
                return _c(
                  "tr",
                  { key: status.id, staticClass: "font-14 text-left" },
                  [
                    _c(
                      "td",
                      {
                        staticStyle: { width: "50px", "text-align": "center" }
                      },
                      [
                        _c("v-icon", { staticClass: "drag" }, [
                          _vm._v("drag_handle")
                        ])
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "div",
                        {
                          staticClass: "pa-1",
                          attrs: { contenteditable: "true" },
                          on: {
                            blur: function($event) {
                              return _vm.setName(status, $event)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(status.label) + " ")]
                      )
                    ]),
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "row-format align-center" },
                        [
                          _c("v-switch", {
                            staticClass: "mt-n1",
                            attrs: { dense: "", "hide-details": "" },
                            on: {
                              change: function($event) {
                                return _vm.saveSettings()
                              }
                            },
                            model: {
                              value: status.clientApproval,
                              callback: function($$v) {
                                _vm.$set(status, "clientApproval", $$v)
                              },
                              expression: "status.clientApproval"
                            }
                          }),
                          status.clientApproval
                            ? _c("v-select", {
                                staticClass: "ml-auto",
                                staticStyle: { "max-width": "175px" },
                                attrs: {
                                  items: _vm.reminderOptions,
                                  dense: "",
                                  "hide-details": "",
                                  outlined: "",
                                  "item-value": "value",
                                  "item-text": "label",
                                  "persistent-placeholder": "",
                                  label: "Reminder frequency"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.saveSettings()
                                  }
                                },
                                model: {
                                  value: status.clientApprovalReminderDays,
                                  callback: function($$v) {
                                    _vm.$set(
                                      status,
                                      "clientApprovalReminderDays",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "status.clientApprovalReminderDays"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _c(
                      "td",
                      [
                        _c("v-switch", {
                          staticClass: "mt-n1",
                          attrs: { dense: "", "hide-details": "" },
                          on: {
                            change: function($event) {
                              return _vm.completeUpdated(status)
                            }
                          },
                          model: {
                            value: status.complete,
                            callback: function($$v) {
                              _vm.$set(status, "complete", $$v)
                            },
                            expression: "status.complete"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-click": true,
                              "close-on-content-click": true
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          {
                                            staticClass: "stage-color pointer",
                                            style:
                                              "--stage-color:" + status.hexColor
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "div",
                              { attrs: { id: "color-picker-div" } },
                              [
                                _c("v-color-picker", {
                                  staticClass: "mx-auto",
                                  attrs: {
                                    flat: "",
                                    mode: "hexa",
                                    "show-swatches": "",
                                    "hide-canvas": "",
                                    "hide-mode-switch": "",
                                    "hide-inputs": "",
                                    "swatches-max-height": "200",
                                    swatches: _vm.clientPalette.palette
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.saveSettings()
                                    }
                                  },
                                  model: {
                                    value: status.hexColor,
                                    callback: function($$v) {
                                      _vm.$set(status, "hexColor", $$v)
                                    },
                                    expression: "status.hexColor"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "td",
                      {
                        staticStyle: { "text-align": "center", width: "50px" }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "material-symbols-rounded",
                            attrs: { size: "20" },
                            on: {
                              click: function($event) {
                                return _vm.confirmDelete(status)
                              }
                            }
                          },
                          [_vm._v("delete")]
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }