var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bulk-actions" },
    [
      _c("div", { staticClass: "count" }, [_vm._v(_vm._s(_vm.tasks.length))]),
      _c("div", [_vm._v(_vm._s(_vm.label))]),
      _c(
        "v-menu",
        {
          attrs: { "close-on-click": true, "close-on-content-click": false },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({ staticClass: "action" }, on),
                    [
                      _c("v-icon", { attrs: { size: "24" } }, [
                        _vm._v("today")
                      ]),
                      _c("div", [_vm._v("Set start")])
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.startMenu,
            callback: function($$v) {
              _vm.startMenu = $$v
            },
            expression: "startMenu"
          }
        },
        [
          _c("bulk-date", {
            on: {
              "shift-date": function($event) {
                return _vm.shiftDate("startDate", $event)
              },
              "set-date": function($event) {
                return _vm.setDate("startDate", $event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: { "close-on-click": true, "close-on-content-click": false },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({ staticClass: "action" }, on),
                    [
                      _c("v-icon", { attrs: { size: "24" } }, [
                        _vm._v("event")
                      ]),
                      _c("div", [_vm._v("Set due")])
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dueMenu,
            callback: function($$v) {
              _vm.dueMenu = $$v
            },
            expression: "dueMenu"
          }
        },
        [
          _c("bulk-date", {
            on: {
              "shift-date": function($event) {
                return _vm.shiftDate("dueDate", $event)
              },
              "set-date": function($event) {
                return _vm.setDate("dueDate", $event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: { "close-on-click": true, "close-on-content-click": true },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({ staticClass: "action" }, on),
                    [
                      _c("v-icon", { attrs: { size: "24" } }, [_vm._v("bolt")]),
                      _c("div", [_vm._v("Set status")])
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.statusMenu,
            callback: function($$v) {
              _vm.statusMenu = $$v
            },
            expression: "statusMenu"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "text-left pa-3 font-14",
              staticStyle: { "background-color": "var(--v-white-base)" }
            },
            _vm._l(_vm.statusList, function(status) {
              return _c(
                "div",
                { key: status.id, staticClass: "py-1 pointer" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "deliverableStatus row-format align-center highlight-hover",
                      style: "--status-color:" + status.hexColor,
                      on: {
                        click: function($event) {
                          return _vm.setStatus(status)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "statusBox" }),
                      _c("div", [_vm._v(_vm._s(status.label))])
                    ]
                  )
                ]
              )
            }),
            0
          )
        ]
      ),
      _c(
        "v-menu",
        {
          attrs: { "close-on-click": true, "close-on-content-click": true },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({ staticClass: "action" }, on),
                    [
                      _c("v-icon", { attrs: { size: "24" } }, [
                        _vm._v("priority_high")
                      ]),
                      _c("div", [_vm._v("Set priority")])
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.priorityMenu,
            callback: function($$v) {
              _vm.priorityMenu = $$v
            },
            expression: "priorityMenu"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "text-left pa-3 font-14",
              staticStyle: { "background-color": "var(--v-white-base)" }
            },
            _vm._l(_vm.priorityList, function(priority) {
              return _c("div", { key: priority, staticClass: "py-1 pointer" }, [
                _c(
                  "div",
                  {
                    staticClass: "row-format align-center highlight-hover",
                    on: {
                      click: function($event) {
                        return _vm.setPriority(priority)
                      }
                    }
                  },
                  [_c("div", [_vm._v(_vm._s(priority))])]
                )
              ])
            }),
            0
          )
        ]
      ),
      _c(
        "v-menu",
        {
          attrs: { "close-on-click": true, "close-on-content-click": false },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({ staticClass: "action" }, on),
                    [
                      _c("v-icon", { attrs: { size: "24" } }, [
                        _vm._v("person")
                      ]),
                      _c("div", [_vm._v("Assigned to")])
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.assignedMenu,
            callback: function($$v) {
              _vm.assignedMenu = $$v
            },
            expression: "assignedMenu"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "text-left pa-3 font-14",
              staticStyle: { "background-color": "var(--v-white-base)" }
            },
            [
              _vm._l(_vm.userList, function(user) {
                return _c(
                  "div",
                  {
                    key: user.userId,
                    staticClass:
                      "row-format align-center text-left py-1 pointer"
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.assignedToList,
                          expression: "assignedToList"
                        }
                      ],
                      staticStyle: {
                        width: "20px",
                        height: "20px",
                        "border-radius": "4px"
                      },
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: user.userId,
                        checked: Array.isArray(_vm.assignedToList)
                          ? _vm._i(_vm.assignedToList, user.userId) > -1
                          : _vm.assignedToList
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.assignedToList,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = user.userId,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.assignedToList = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.assignedToList = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.assignedToList = $$c
                          }
                        }
                      }
                    }),
                    user.userId
                      ? _c("assigned-user", {
                          staticClass: "mx-2",
                          attrs: {
                            "assigned-to": user.userId,
                            "show-name": true
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c(
                "v-btn",
                {
                  staticClass: "mt-2",
                  attrs: { block: "", small: "", color: "primary" },
                  on: { click: _vm.setAssignedTo }
                },
                [_vm._v("Update")]
              )
            ],
            2
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "action", on: { click: _vm.archiveTasks } },
        [
          _c("v-icon", { attrs: { size: "24" } }, [_vm._v("archive")]),
          _c("div", [_vm._v("Archive")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }