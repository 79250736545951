var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subscription-blocker" }, [
    _c("div", { staticClass: "blocker-content" }, [
      _c("span", { staticClass: "material-symbols-rounded lock-icon" }, [
        _vm._v("lock")
      ]),
      _c("h1", { staticClass: "title" }, [_vm._v("Feature Unavailable")]),
      _c("p", { staticClass: "description" }, [
        _vm._v(
          " Your current subscription tier does not include this feature. Upgrade now to get access! "
        )
      ]),
      _c(
        "div",
        { staticClass: "button-group" },
        [
          _c(
            "v-btn",
            { staticClass: "super-action", on: { click: _vm.onUpgrade } },
            [_vm._v("Upgrade Subscription")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }