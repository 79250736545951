var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Accounting" }
            ]
          }
        },
        [_vm._v(" Products & services ")]
      ),
      _c(
        "div",
        { staticClass: "row-format align-center mb-4" },
        [
          _c("v-text-field", {
            staticClass: "standard-input",
            style: "max-width: 200px;",
            attrs: {
              outlined: "",
              "hide-details": "",
              dense: "",
              placeholder: "Search...",
              color: ""
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-inner",
                fn: function() {
                  return [
                    _c(
                      "span",
                      { staticClass: "material-symbols-rounded font-gray_50" },
                      [_vm._v("search")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto super-action",
              on: {
                click: function($event) {
                  return _vm.$refs.ProductTable.createNewProduct()
                }
              }
            },
            [
              _c("v-icon", { attrs: { size: "20" } }, [_vm._v("add")]),
              _vm._v(" Add item")
            ],
            1
          )
        ],
        1
      ),
      _c("products-table", {
        ref: "ProductTable",
        attrs: { filter: _vm.search, "edit-mode": true }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }