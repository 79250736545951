var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "communicator-channel-filter" } },
    [
      _c(
        "v-menu",
        {
          staticStyle: { "overflow-y": "auto" },
          attrs: {
            "max-height": "90vh",
            "max-width": "250",
            "close-on-content-click": false,
            "close-on-click": true,
            id: "channel-filter",
            "nudge-bottom": "34",
            "content-class": "filter-menu-class"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "standard-action",
                        attrs: { elevation: "0", outlined: "", color: "" }
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        { class: "font-14 font-gray_60 brand-medium" },
                        [_vm._v(_vm._s(_vm.selected))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: { "x-small": "", color: "gray_60" }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _c(
            "div",
            { staticClass: "add-new-dropdown" },
            _vm._l(_vm.dateList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "add-new-item",
                  on: {
                    click: function($event) {
                      return _vm.update(item)
                    }
                  }
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }