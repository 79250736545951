var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "overview" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("duration-selector", {
                staticClass: "mt-4",
                attrs: { label: "Meeting duration" },
                model: {
                  value: _vm.meeting.meetingLength,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "meetingLength", $$v)
                  },
                  expression: "meeting.meetingLength"
                }
              }),
              _c(
                "div",
                { staticClass: "row-format gap-2 align-center mt-4 mb-2" },
                [
                  _c("v-select", {
                    attrs: {
                      "persistent-placeholder": "",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      label: "Location",
                      placeholder: "Add location option",
                      items: _vm.locationOptions,
                      "item-value": "value"
                    },
                    on: {
                      change: function($event) {
                        return _vm.selectLocation(_vm.selectedLocation)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "div",
                              { staticClass: "row-format my-2" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    class:
                                      item.value === "PhoneIn" ? "reverse" : "",
                                    attrs: { small: "", color: "gray_80" }
                                  },
                                  [_vm._v(_vm._s(item.icon))]
                                ),
                                _c("div", { staticClass: "text-left ml-2" }, [
                                  _c("div", { staticClass: "font-16" }, [
                                    _vm._v(_vm._s(item.text))
                                  ])
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "item",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "div",
                              { staticClass: "row-format my-1" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    class:
                                      item.value === "PhoneIn" ? "reverse" : "",
                                    attrs: { color: "gray_80" }
                                  },
                                  [_vm._v(_vm._s(item.icon))]
                                ),
                                _c("div", { staticClass: "text-left ml-3" }, [
                                  _c("div", { staticClass: "font-16" }, [
                                    _vm._v(_vm._s(item.text))
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "font-12 font-gray_70" },
                                    [_vm._v(_vm._s(item.description))]
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedLocation,
                      callback: function($$v) {
                        _vm.selectedLocation = $$v
                      },
                      expression: "selectedLocation"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "brand-medium text-left" }, [
                _vm._v("Location options:")
              ]),
              _c(
                "div",
                { staticClass: "column-format gap-2 text-left font-14 mb-6" },
                [
                  _vm.locations.length === 0
                    ? _c(
                        "div",
                        { staticClass: "font-gray_70 font-12" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "error", small: "" } },
                            [_vm._v("warning")]
                          ),
                          _vm._v(
                            " Please select at least one location option for this meeting "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "draggable",
                    {
                      attrs: { handle: ".drag-handle" },
                      model: {
                        value: _vm.locations,
                        callback: function($$v) {
                          _vm.locations = $$v
                        },
                        expression: "locations"
                      }
                    },
                    _vm._l(_vm.locations, function(location, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "pa-2 row-format align-center gap-2 location-row",
                          staticStyle: { "border-radius": "4px" }
                        },
                        [
                          location.type === "Google"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mr-1 drag-handle" },
                                    [_vm._v("$googleCalendar")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatGoogleLocation(location)
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            : location.type === "Microsoft"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mr-1 drag-handle" },
                                    [_vm._v("$microsoft")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatMicrosoftLocation(location)
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            : location.type === "Zoom"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mr-1 drag-handle" },
                                    [_vm._v("$zoomIcon")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatZoomLocation(location)) +
                                      " "
                                  )
                                ],
                                1
                              )
                            : location.type === "PhoneOut"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mr-1 drag-handle" },
                                    [_vm._v("$phoneInTalk")]
                                  ),
                                  _vm._v(
                                    " We will ask the invitee for their phone number at booking time. "
                                  )
                                ],
                                1
                              )
                            : location.type === "InPerson"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row-format align-center gap-1 flex-grow-1"
                                },
                                [
                                  _c("v-icon", { staticClass: "drag-handle" }, [
                                    _vm._v("$map")
                                  ]),
                                  _c("v-textarea", {
                                    staticStyle: {
                                      "background-color": "var(--v-white-base)"
                                    },
                                    attrs: {
                                      "persistent-placeholder": "",
                                      "hide-details": "",
                                      dense: "",
                                      outlined: "",
                                      rows: "1",
                                      "auto-grow": true,
                                      label: "Address of meeting"
                                    },
                                    model: {
                                      value: location.address,
                                      callback: function($$v) {
                                        _vm.$set(location, "address", $$v)
                                      },
                                      expression: "location.address"
                                    }
                                  })
                                ],
                                1
                              )
                            : location.type === "PhoneIn"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row-format align-center gap-1 flex-grow-1"
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "reverse drag-handle" },
                                    [_vm._v("$phoneInTalk")]
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "flex-grow-1",
                                    staticStyle: {
                                      "background-color": "var(--v-white-base)"
                                    },
                                    attrs: {
                                      "persistent-placeholder": "",
                                      "hide-details": "",
                                      dense: "",
                                      outlined: "",
                                      type: "phone",
                                      placeholder: "(555) 555-1212 ext. 123",
                                      label: "Phone number for invitee to call"
                                    },
                                    model: {
                                      value: location.phone,
                                      callback: function($$v) {
                                        _vm.$set(location, "phone", $$v)
                                      },
                                      expression: "location.phone"
                                    }
                                  })
                                ],
                                1
                              )
                            : location.type === "Custom"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row-format align-center gap-1 flex-grow-1"
                                },
                                [
                                  _c("v-icon", { staticClass: "drag-handle" }, [
                                    _vm._v("$edit")
                                  ]),
                                  _c("v-textarea", {
                                    staticStyle: {
                                      "background-color": "var(--v-white-base)"
                                    },
                                    attrs: {
                                      "persistent-placeholder": "",
                                      "hide-details": "",
                                      dense: "",
                                      outlined: "",
                                      rows: "1",
                                      "auto-grow": true,
                                      label: "Custom meeting instructions"
                                    },
                                    model: {
                                      value: location.custom,
                                      callback: function($$v) {
                                        _vm.$set(location, "custom", $$v)
                                      },
                                      expression: "location.custom"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-icon",
                            {
                              staticClass:
                                "show-on-hover material-symbols-rounded ml-auto pointer",
                              attrs: { color: "gray_70" },
                              on: {
                                click: function($event) {
                                  return _vm.removeLocation(index)
                                }
                              }
                            },
                            [_vm._v("delete")]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c("v-textarea", {
                staticClass: "mt-4",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  rows: "6",
                  "auto-grow": true,
                  label: "Brief description of meeting"
                },
                model: {
                  value: _vm.meeting.description,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "description", $$v)
                  },
                  expression: "meeting.description"
                }
              }),
              _vm.mappedCalendars.length && _vm.hasNonLinkedCalendarOption
                ? _c("v-select", {
                    staticClass: "mt-4",
                    attrs: {
                      "persistent-placeholder": "",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      label:
                        "Add meeting to connected calendar when not using Google Meet or Microsoft Teams",
                      items: _vm.mappedCalendars
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                { staticClass: "row-format align-center" },
                                [
                                  item.type === "Google"
                                    ? _c("v-icon", { staticClass: "mb-1" }, [
                                        _vm._v("$googleCalendar")
                                      ])
                                    : _vm._e(),
                                  item.type === "Apple"
                                    ? _c("v-icon", { staticClass: "mb-1" }, [
                                        _vm._v("$apple")
                                      ])
                                    : _vm._e(),
                                  item.type === "CalDav"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "secondary" } },
                                        [_vm._v("event")]
                                      )
                                    : _vm._e(),
                                  item.type === "Microsoft"
                                    ? _c("v-icon", { staticClass: "mb-1" }, [
                                        _vm._v("$microsoft")
                                      ])
                                    : _vm._e(),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v(_vm._s(item.text))
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "selection",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                { staticClass: "row-format align-center" },
                                [
                                  item.type === "Google"
                                    ? _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("$googleCalendar")
                                      ])
                                    : _vm._e(),
                                  item.type === "Apple"
                                    ? _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("$apple")
                                      ])
                                    : _vm._e(),
                                  item.type === "CalDav"
                                    ? _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "secondary"
                                          }
                                        },
                                        [_vm._v("event")]
                                      )
                                    : _vm._e(),
                                  item.type === "Microsoft"
                                    ? _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("$microsoft")
                                      ])
                                    : _vm._e(),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v(_vm._s(item.text))
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2156913587
                    ),
                    model: {
                      value: _vm.addToCalendarUser,
                      callback: function($$v) {
                        _vm.addToCalendarUser = $$v
                      },
                      expression: "addToCalendarUser"
                    }
                  })
                : _vm._e(),
              _c("multi-email-input", {
                staticClass: "mt-4",
                attrs: {
                  "known-emails": _vm.userEmails,
                  label: "Additional attendees"
                },
                model: {
                  value: _vm.meeting.additionalAttendees,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "additionalAttendees", $$v)
                  },
                  expression: "meeting.additionalAttendees"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-4",
                attrs: {
                  readonly: "",
                  "persistent-placeholder": "",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: "Direct meeting link",
                  value: _vm.fullMeetingLink
                }
              }),
              _c("v-select", {
                staticClass: "mt-4",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: "Scheduler owner",
                  items: _vm.usersForAccount,
                  "item-value": "userId"
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.userId
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(item.firstName) +
                                  " " +
                                  _vm._s(item.lastName) +
                                  " "
                              ),
                              _c("span", { staticClass: "font-gray_70" }, [
                                _vm._v(" (" + _vm._s(item.email) + ")")
                              ])
                            ])
                          : _c("div", [_vm._v("-- No owner --")])
                      ]
                    }
                  },
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.userId
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(item.firstName) +
                                  " " +
                                  _vm._s(item.lastName) +
                                  " "
                              ),
                              _c("span", { staticClass: "font-gray_70" }, [
                                _vm._v(" (" + _vm._s(item.email) + ")")
                              ])
                            ])
                          : _c("div", [_vm._v("-- No owner --")])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.meeting.ownerUserId,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "ownerUserId", $$v)
                  },
                  expression: "meeting.ownerUserId"
                }
              }),
              _c("v-select", {
                staticClass: "mt-4",
                attrs: {
                  disabled: _vm.pipelineStages.length === 0,
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  "persistent-placeholder": "",
                  label: "Add meeting to pipeline",
                  items: _vm.pipelineStages,
                  "item-value": "id",
                  "item-text": "label",
                  clearable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", {
                          style:
                            "width: 20px; height: 20px; border-radius: 4px; background-color: " +
                            item.hexColor
                        }),
                        _c("div", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(item.label))
                        ])
                      ]
                    }
                  },
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", {
                          style:
                            "width: 20px; height: 20px; border-radius: 4px; background-color: " +
                            item.hexColor
                        }),
                        _c("div", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(item.label))
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.meeting.pipelineStageId,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "pipelineStageId", $$v)
                  },
                  expression: "meeting.pipelineStageId"
                }
              }),
              _c("v-switch", {
                attrs: { label: "Link active", "hide-details": "" },
                model: {
                  value: _vm.meeting.active,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "active", $$v)
                  },
                  expression: "meeting.active"
                }
              }),
              _c("v-switch", {
                attrs: { label: "Show in Client Portal", "hide-details": "" },
                model: {
                  value: _vm.meeting.showInPortal,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "showInPortal", $$v)
                  },
                  expression: "meeting.showInPortal"
                }
              }),
              _c("v-switch", {
                directives: [
                  {
                    name: "tippy",
                    rawName: "v-tippy",
                    value: {
                      content:
                        "Require Google ReCaptcha when embedding form on external websites."
                    },
                    expression:
                      "{content: 'Require Google ReCaptcha when embedding form on external websites.'}"
                  }
                ],
                attrs: { label: "Embedded ReCaptcha", "hide-details": "" },
                model: {
                  value: _vm.meeting.reCaptchaEnabled,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "reCaptchaEnabled", $$v)
                  },
                  expression: "meeting.reCaptchaEnabled"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }