var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.meeting
    ? _c(
        "div",
        { staticClass: "meeting-detail" },
        [
          _c("div", { staticClass: "header-area pl-1" }, [
            _c(
              "div",
              [
                _c(
                  "v-form",
                  {
                    staticClass: "header-left row-format gap-3",
                    model: {
                      value: _vm.nameIsValid,
                      callback: function($$v) {
                        _vm.nameIsValid = $$v
                      },
                      expression: "nameIsValid"
                    }
                  },
                  [
                    _c("v-text-field", {
                      staticStyle: { "min-width": "294px" },
                      attrs: {
                        rules: _vm.nameRules,
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        disabled: _vm.sampleMode,
                        "persistent-placeholder": "",
                        placeholder: "Name"
                      },
                      on: {
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          return _vm.saveMeeting.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.meeting.name,
                        callback: function($$v) {
                          _vm.$set(_vm.meeting, "name", $$v)
                        },
                        expression: "meeting.name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "header-right row-format gap-2" },
              [
                _c("div", { staticClass: "font-14 font-gray_70 mr-3" }, [
                  _vm._v(_vm._s(_vm.saveMessage))
                ]),
                _c("folder-selector", {
                  attrs: { folders: _vm.folders },
                  model: {
                    value: _vm.meeting.folder,
                    callback: function($$v) {
                      _vm.$set(_vm.meeting, "folder", $$v)
                    },
                    expression: "meeting.folder"
                  }
                }),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    attrs: { elevation: "0" },
                    on: {
                      click: function($event) {
                        _vm.shareDialog = true
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.share")) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action",
                    attrs: { elevation: "0", width: "96" },
                    on: {
                      click: function($event) {
                        return _vm.saveMeeting(false, true)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.saveMeeting(true)
                      }
                    }
                  },
                  [_c("v-icon", { attrs: { size: 20 } }, [_vm._v("$close")])],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "row-format",
              staticStyle: { height: "calc(100% - 50px)" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "column-format",
                  staticStyle: {
                    width: "300px",
                    "max-width": "300px",
                    "min-width": "300px",
                    "border-right": "1px solid var(--v-gray_30-base)",
                    height: "99%",
                    "max-height": "99%"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "column-format gap-2 show-scrollbar",
                      staticStyle: { height: "100%", "max-height": "100%" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "row-format pb-2 pt-3 gap-3 px-3" },
                        [
                          _c(
                            "div",
                            {
                              class:
                                "row-format align-center page-link " +
                                (_vm.tab === "Details"
                                  ? "page-link-active"
                                  : ""),
                              on: {
                                click: function($event) {
                                  return _vm.selectTab("Details")
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-symbols-rounded",
                                  attrs: {
                                    size: "22",
                                    color:
                                      _vm.tab === "Details"
                                        ? "primary"
                                        : "gray_0"
                                  }
                                },
                                [_vm._v("settings")]
                              ),
                              _c("div", { staticClass: "ml-1" }, [
                                _vm._v("Settings")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              class:
                                "row-format align-center page-link " +
                                (_vm.tab === "Style" ? "page-link-active" : ""),
                              on: {
                                click: function($event) {
                                  return _vm.selectTab("Style")
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-symbols-rounded",
                                  attrs: {
                                    size: "22",
                                    color:
                                      _vm.tab === "Style"
                                        ? "primary"
                                        : "gray_50"
                                  }
                                },
                                [_vm._v("palette")]
                              ),
                              _c("div", { staticClass: "ml-1" }, [
                                _vm._v("Style")
                              ])
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.tab === "Details"
                        ? _c("div", { staticStyle: { "font-size": "14px" } }, [
                            _c(
                              "div",
                              {
                                class:
                                  "step-button " +
                                  (_vm.view === "overview" ? "active" : ""),
                                on: {
                                  click: function($event) {
                                    return _vm.setView("overview")
                                  }
                                }
                              },
                              [_c("div", [_vm._v("1) Overview")])]
                            ),
                            _c(
                              "div",
                              {
                                class:
                                  "step-button " +
                                  (_vm.view === "availability" ? "active" : ""),
                                on: {
                                  click: function($event) {
                                    return _vm.setView("availability")
                                  }
                                }
                              },
                              [_c("div", [_vm._v("2) Availability")])]
                            ),
                            _c(
                              "div",
                              {
                                class:
                                  "step-button " +
                                  (_vm.view === "questions" ? "active" : ""),
                                on: {
                                  click: function($event) {
                                    return _vm.setView("questions")
                                  }
                                }
                              },
                              [_c("div", [_vm._v("3) Question form")])]
                            ),
                            _c(
                              "div",
                              {
                                class:
                                  "step-button " +
                                  (_vm.view === "confirmation" ? "active" : ""),
                                on: {
                                  click: function($event) {
                                    return _vm.setView("confirmation")
                                  }
                                }
                              },
                              [
                                _c("div", [
                                  _vm._v("4) Confirmation & reminder")
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                class:
                                  "step-button " +
                                  (_vm.view === "payment" ? "active" : ""),
                                on: {
                                  click: function($event) {
                                    return _vm.setView("payment")
                                  }
                                }
                              },
                              [_c("div", [_vm._v("5) Payment")])]
                            ),
                            _c(
                              "div",
                              {
                                class:
                                  "step-button " +
                                  (_vm.view === "preview" ? "active" : ""),
                                on: {
                                  click: function($event) {
                                    return _vm.setView("preview")
                                  }
                                }
                              },
                              [_c("div", [_vm._v("6) Preview")])]
                            )
                          ])
                        : _vm._e(),
                      _vm.tab === "Style"
                        ? _c(
                            "div",
                            { staticClass: "py-3 px-3" },
                            [
                              _c("scheduler-style-config", {
                                attrs: {
                                  meeting: _vm.meeting,
                                  "unique-account-url": _vm.uniqueAccountUrl,
                                  "pod-id": _vm.podId,
                                  "selected-tab": _vm.selectedTab
                                },
                                on: {
                                  "request-save": function($event) {
                                    return _vm.saveMeeting()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "pa-6 column-format align-center show-scrollbar main-form",
                  staticStyle: {
                    width: "100%",
                    height: "100%",
                    "max-height": "100%"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "var(--v-white-base)",
                        width: "100%",
                        "min-width": "300px",
                        "max-width": "1200px"
                      }
                    },
                    [
                      !_vm.sampleMode && !_vm.paidAccount
                        ? _c(
                            "div",
                            { staticClass: "column-format centered mb-1 px-3" },
                            [
                              _c("free-tier-card", {
                                staticStyle: {
                                  height: "90px",
                                  "max-width": "800px"
                                },
                                attrs: {
                                  message:
                                    "Meeting scheduler is not available on the Essentials plan. You can explore this feature, but access to scheduling meetings will be restricted."
                                },
                                on: {
                                  "upgrade-clicked": function($event) {
                                    return _vm.upgradeClicked()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("overview", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.view === "overview",
                            expression: "view === 'overview'"
                          }
                        ],
                        attrs: {
                          meeting: _vm.meeting,
                          "used-names": _vm.usedNames,
                          "unique-account-url": _vm.uniqueAccountUrl,
                          "pod-id": _vm.podId,
                          "zoom-users": _vm.zoomUsers,
                          "sessions-users": _vm.sessionsUsers,
                          "google-users": _vm.googleUsers,
                          "apple-users": _vm.appleUsers,
                          "cal-dav-users": _vm.calDavUsers,
                          "microsoft-users": _vm.microsoftUsers,
                          "sample-mode": _vm.sampleMode,
                          "pipeline-stages": _vm.pipelineStages,
                          "full-meeting-link": _vm.fullMeetingLink
                        }
                      }),
                      _c("availability", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.view === "availability",
                            expression: "view === 'availability'"
                          }
                        ],
                        attrs: {
                          meeting: _vm.meeting,
                          "google-users": _vm.googleUsers,
                          "apple-users": _vm.appleUsers,
                          "cal-dav-users": _vm.calDavUsers,
                          "microsoft-users": _vm.microsoftUsers
                        }
                      }),
                      _c("questions", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.view === "questions",
                            expression: "view === 'questions'"
                          }
                        ],
                        attrs: { meeting: _vm.meeting }
                      }),
                      _c("confirmation", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.view === "confirmation",
                            expression: "view === 'confirmation'"
                          }
                        ],
                        key: "confirmation" + _vm.refreshKey,
                        attrs: {
                          meeting: _vm.meeting,
                          "email-templates": _vm.emailTemplates
                        }
                      }),
                      _c("payment", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.view === "payment",
                            expression: "view === 'payment'"
                          }
                        ],
                        attrs: { meeting: _vm.meeting }
                      }),
                      _vm.view === "preview"
                        ? _c("preview", {
                            ref: "preview",
                            attrs: {
                              meeting: _vm.meeting,
                              tokenMap: _vm.tokenMap(_vm.meeting, true),
                              tokenMapNoFake: _vm.tokenMap(_vm.meeting, false),
                              "unique-account-url": _vm.uniqueAccountUrl,
                              "pod-id": _vm.podId
                            },
                            on: {
                              selectedTab: _vm.handleSelectedTab,
                              "request-save": function($event) {
                                return _vm.saveMeeting()
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm.shareDialog
            ? _c(
                "basic-modal",
                {
                  attrs: { dialog: _vm.shareDialog, persistent: true },
                  on: {
                    close: function($event) {
                      _vm.shareDialog = false
                    }
                  }
                },
                [
                  _c("share-scheduler", {
                    attrs: {
                      "base-url": _vm.baseUrl.substring(
                        0,
                        _vm.baseUrl.length - 1
                      ),
                      "full-link": _vm.fullMeetingLink,
                      "full-link-embed": _vm.fullMeetingLinkEmbed,
                      "internal-link": _vm.internalLink,
                      "meeting-id": _vm.meeting.uniqueMeetingId
                    },
                    on: {
                      close: function($event) {
                        _vm.shareDialog = false
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }