var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pa-0 ma-0",
      staticStyle: { "min-height": "400px" },
      attrs: { fluid: "" }
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("tag-user-field", {
                ref: "tagUserField",
                attrs: { userList: _vm.userList, commentToEdit: null },
                on: {
                  sendComment: function($event) {
                    return _vm.sendComment($event)
                  }
                }
              }),
              _vm.deliverable.events.length
                ? _c(
                    "div",
                    {
                      staticClass: "mt-1 mb-2 pointer font-12 text-left",
                      staticStyle: { color: "var(--v-gray_70-base)" },
                      on: {
                        click: function($event) {
                          _vm.showEvents = !_vm.showEvents
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "", color: "gray_70" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.showEvents ? "$visibilityOff" : "$visibility"
                            ) +
                            " "
                        )
                      ]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.showEvents
                                ? _vm.$t("global.hide")
                                : _vm.$t("global.show")
                            ) +
                            " " +
                            _vm._s(_vm.$t("projects.activity-stream")) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            _vm._l(_vm.sortedActivity, function(comment, index) {
              return _c(
                "div",
                {
                  key: comment.id,
                  class: _vm.canEdit(comment, index) ? "can-edit" : "",
                  staticStyle: { "word-wrap": "anywhere" },
                  on: {
                    mouseover: function($event) {
                      return _vm.setHoverIndex(index)
                    },
                    mouseleave: function($event) {
                      return _vm.setHoverIndex(null)
                    }
                  }
                },
                [
                  comment.events && _vm.showEvents
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "font-12 text-left my-2 brand-semilight row-format align-center"
                        },
                        [
                          _c("div", [
                            _c("span", { staticClass: "brand-medium" }, [
                              _vm._v(_vm._s(comment.user))
                            ]),
                            _vm._v(
                              " " + _vm._s(comment.events.join(" and ")) + " "
                            )
                          ]),
                          _c(
                            "div",
                            { staticStyle: { color: "var(--v-gray_70-base)" } },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "12", color: "gray_70" } },
                                [_vm._v("$clock")]
                              ),
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatForTimeAgo")(comment.timestamp)
                                ) + " "
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  comment.comment
                    ? _c(
                        "div",
                        {
                          class:
                            "mt-2 comment-box " +
                            (comment.privateComment ? "private-comment" : "")
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row-format align-center font-12" },
                            [
                              comment.clientComment && _vm.client
                                ? _c("client-avatar", {
                                    attrs: {
                                      "disable-click": true,
                                      client: _vm.client,
                                      small: ""
                                    }
                                  })
                                : _c("assigned-user", {
                                    attrs: {
                                      "show-name": false,
                                      "assigned-to": parseInt(
                                        comment.authorId,
                                        10
                                      )
                                    }
                                  }),
                              _c("div", { staticClass: "ml-3" }, [
                                _vm._v(" " + _vm._s(comment.author) + " ")
                              ]),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-3 mr-1",
                                  attrs: { size: "14" }
                                },
                                [_vm._v("$clock")]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "var(--v-gray_70-base)"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatForTimeAgo")(
                                          comment.timestamp
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ml-auto row-format align-center gap-2"
                                },
                                [
                                  _vm.canEdit(comment, index)
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "pointer mr-2",
                                              attrs: { small: "" },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.editComment(
                                                    comment,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("edit")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "pointer",
                                              attrs: { small: "" },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.confirmDelete(
                                                    comment
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("delete")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  comment.privateComment
                                    ? _c(
                                        "v-icon",
                                        {
                                          directives: [
                                            {
                                              name: "tippy",
                                              rawName: "v-tippy"
                                            }
                                          ],
                                          attrs: {
                                            content: "Private comment",
                                            small: ""
                                          }
                                        },
                                        [_vm._v("visibility_off")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.editIndex !== index
                            ? _c("div", {
                                staticClass: "pl-9 font-14 comment",
                                style: "width: 100%; text-align: left;",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.formatMarkdown(comment.comment)
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm.editIndex === index
                            ? _c(
                                "div",
                                {
                                  staticClass: "comment pl-9",
                                  staticStyle: {
                                    width: "100%",
                                    "text-align": "left"
                                  }
                                },
                                [
                                  _c("tag-user-field", {
                                    attrs: {
                                      userList: _vm.userList,
                                      commentToEdit: _vm.commentToEdit
                                    },
                                    on: {
                                      sendComment: function($event) {
                                        return _vm.sendComment($event)
                                      },
                                      saveEdit: function($event) {
                                        return _vm.saveEdit($event)
                                      },
                                      cancelEdit: function($event) {
                                        return _vm.cancelEdit($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ],
        1
      ),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDeleteDialog,
          "heading-text": _vm.$t("global.confirm"),
          "yes-text": "Delete",
          "no-text": "Cancel",
          "body-text": _vm.$t("projects.delete-note")
        },
        on: {
          close: function($event) {
            return _vm.cancelDelete()
          },
          confirm: function($event) {
            return _vm.deleteComment()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }