var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "panel-modal", staticStyle: { width: "600px" } },
        [
          _c("div", { staticClass: "panel-modal-header" }, [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2 pointer",
                    attrs: { small: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.savePackage(false)
                      }
                    }
                  },
                  [_vm._v("$chevronRight")]
                ),
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-1 material-symbols-rounded",
                    attrs: { color: "black" }
                  },
                  [_vm._v("folder_special")]
                ),
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "super-action",
                        on: {
                          click: function($event) {
                            return _vm.savePackage(true)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer ml-2",
                        attrs: { size: "20", color: "gray_60" },
                        on: { click: _vm.deletePackage }
                      },
                      [_vm._v("$delete")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "panel-modal-body text-left" }, [
            _c("div", { attrs: { id: "deliverable-detail" } }, [
              _c("input", {
                attrs: {
                  id: "deliverableLink",
                  name: "deliverableLink",
                  type: "hidden"
                }
              }),
              _c(
                "div",
                { staticClass: "column-format gap-2" },
                [
                  _c("v-text-field", {
                    attrs: {
                      autofocus: "",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "Project name"
                    },
                    model: {
                      value: _vm.pkg.name,
                      callback: function($$v) {
                        _vm.$set(_vm.pkg, "name", $$v)
                      },
                      expression: "pkg.name"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "Project type",
                      items: _vm.$store.state.projectTypes,
                      "item-value": "id",
                      "item-text": "name"
                    },
                    model: {
                      value: _vm.pkg.projectTypeId,
                      callback: function($$v) {
                        _vm.$set(_vm.pkg, "projectTypeId", $$v)
                      },
                      expression: "pkg.projectTypeId"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-6 mb-4 row-format align-center gap-3" },
                [
                  _c(
                    "div",
                    {
                      class:
                        "page-link " +
                        (_vm.currentView === "Overview"
                          ? "page-link-active"
                          : ""),
                      on: {
                        click: function($event) {
                          return _vm.setView("Overview")
                        }
                      }
                    },
                    [_c("div", [_vm._v("Overview")])]
                  ),
                  _c(
                    "div",
                    {
                      class:
                        "page-link " +
                        (_vm.currentView === "Tasks" ? "page-link-active" : ""),
                      on: {
                        click: function($event) {
                          return _vm.setView("Tasks")
                        }
                      }
                    },
                    [_c("div", [_vm._v("Tasks")])]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "show-scrollbar",
                  staticStyle: {
                    "max-height": "calc(100vh - 210px)",
                    "overflow-y": "auto"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentView === "Overview",
                          expression: "currentView === 'Overview'"
                        }
                      ],
                      staticStyle: { width: "98%" }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                            }
                          },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "column-format gap-3" },
                            [
                              _c("div", { staticClass: "column-format pt-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "px-2 py-2",
                                    staticStyle: {
                                      border: "1px solid var(--v-gray_30-base)",
                                      "border-radius": "4px",
                                      position: "relative"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-12 text-left font-gray_70 px-1",
                                        staticStyle: {
                                          position: "absolute",
                                          top: "-10px",
                                          "background-color":
                                            "var(--v-white-base)"
                                        }
                                      },
                                      [_vm._v(" Description ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "font-14",
                                        staticStyle: { "padding-left": "2px" }
                                      },
                                      [
                                        _c("editor", {
                                          ref: "Description",
                                          attrs: {
                                            inline: true,
                                            init: _vm.mceConfig,
                                            spellcheck: true
                                          },
                                          model: {
                                            value: _vm.pkg.description,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.pkg,
                                                "description",
                                                $$v
                                              )
                                            },
                                            expression: "pkg.description"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.portalAccessTypes,
                                  "persistent-placeholder": "",
                                  "hide-details": "",
                                  dense: "",
                                  outlined: "",
                                  "item-value": "value",
                                  "item-text": "label",
                                  label: "Client portal access"
                                },
                                model: {
                                  value: _vm.pkg.portalAccess,
                                  callback: function($$v) {
                                    _vm.$set(_vm.pkg, "portalAccess", $$v)
                                  },
                                  expression: "pkg.portalAccess"
                                }
                              }),
                              _vm.pkg.portalAccess === "Full access" ||
                              _vm.pkg.portalAccess === "Read only"
                                ? _c("v-select", {
                                    attrs: {
                                      items: _vm.portalAccessAssignedOnly,
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      dense: "",
                                      outlined: "",
                                      "item-value": "value",
                                      "item-text": "label",
                                      label: "Client task visibility"
                                    },
                                    model: {
                                      value: _vm.pkg.portalAccessAssignedOnly,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.pkg,
                                          "portalAccessAssignedOnly",
                                          $$v
                                        )
                                      },
                                      expression: "pkg.portalAccessAssignedOnly"
                                    }
                                  })
                                : _vm._e(),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.showTimeInPortal,
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  dense: "",
                                  outlined: "",
                                  "item-value": "value",
                                  "item-text": "label",
                                  label: "Show time worked in portal"
                                },
                                model: {
                                  value: _vm.pkg.showTimeWorkedInPortal,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.pkg,
                                      "showTimeWorkedInPortal",
                                      $$v
                                    )
                                  },
                                  expression: "pkg.showTimeWorkedInPortal"
                                }
                              }),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.feeTypes,
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  dense: "",
                                  outlined: "",
                                  "item-value": "value",
                                  "item-text": "label",
                                  label: "Project fee type"
                                },
                                model: {
                                  value: _vm.pkg.feeSchedule.feeType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.pkg.feeSchedule,
                                      "feeType",
                                      $$v
                                    )
                                  },
                                  expression: "pkg.feeSchedule.feeType"
                                }
                              }),
                              _vm.pkg.feeSchedule.feeType !== "Per Item"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row-format align-center gap-2"
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          prefix: _vm.currencySymbol,
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          outlined: "",
                                          oninput:
                                            "if(this.value < 0) this.value = this.value*-1;",
                                          type: "number",
                                          label: "Amount"
                                        },
                                        model: {
                                          value: _vm.pkg.feeSchedule.amount,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pkg.feeSchedule,
                                              "amount",
                                              $$v
                                            )
                                          },
                                          expression: "pkg.feeSchedule.amount"
                                        }
                                      }),
                                      _vm.taxEnabled
                                        ? _c("v-checkbox", {
                                            staticClass: "mt-n1 mb-0",
                                            attrs: {
                                              dense: "",
                                              "hide-details": ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-12 ml-n2"
                                                        },
                                                        [_vm._v("Taxable")]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              false,
                                              973561324
                                            ),
                                            model: {
                                              value:
                                                _vm.pkg.feeSchedule.taxable,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.pkg.feeSchedule,
                                                  "taxable",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "pkg.feeSchedule.taxable"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.pkg.feeSchedule.feeType === "Retainer"
                                ? _c(
                                    "div",
                                    { staticClass: "column-format gap-2" },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.schedule,
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          outlined: "",
                                          label: "Retainer period"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c("span", [
                                                    _vm._v(_vm._s(item))
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2957485812
                                        ),
                                        model: {
                                          value:
                                            _vm.pkg.feeSchedule
                                              .retainerSchedule,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pkg.feeSchedule,
                                              "retainerSchedule",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "pkg.feeSchedule.retainerSchedule"
                                        }
                                      }),
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.retainerTiming,
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          outlined: "",
                                          label: "Retainer timing"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c("span", [
                                                    _vm._v(_vm._s(item))
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2957485812
                                        ),
                                        model: {
                                          value:
                                            _vm.pkg.feeSchedule.retainerTiming,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pkg.feeSchedule,
                                              "retainerTiming",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "pkg.feeSchedule.retainerTiming"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "row-format gap-2" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Hour estimate (min)",
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      dense: "",
                                      outlined: "",
                                      type: "number",
                                      min: "0",
                                      max: "10000"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.validateMinMax("min")
                                      }
                                    },
                                    model: {
                                      value: _vm.pkg.feeSchedule.estimateMin,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.pkg.feeSchedule,
                                          "estimateMin",
                                          $$v
                                        )
                                      },
                                      expression: "pkg.feeSchedule.estimateMin"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Hour estimate (max)",
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      dense: "",
                                      outlined: "",
                                      type: "number",
                                      min: "0",
                                      max: "10000"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.validateMinMax("max")
                                      }
                                    },
                                    model: {
                                      value: _vm.pkg.feeSchedule.estimateMax,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.pkg.feeSchedule,
                                          "estimateMax",
                                          $$v
                                        )
                                      },
                                      expression: "pkg.feeSchedule.estimateMax"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.pkg.feeSchedule.feeType === "Retainer" &&
                              _vm.pkg.feeSchedule.estimateMax
                                ? _c("amount-input", {
                                    attrs: {
                                      prefix: _vm.currencySymbol,
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      dense: "",
                                      outlined: "",
                                      oninput:
                                        "if(this.value < 0) this.value = this.value*-1;",
                                      type: "text",
                                      label: "Retainer overage hourly amount"
                                    },
                                    model: {
                                      value:
                                        _vm.pkg.feeSchedule.retainerOverageRate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.pkg.feeSchedule,
                                          "retainerOverageRate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "pkg.feeSchedule.retainerOverageRate"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentView === "Tasks",
                            expression: "currentView === 'Tasks'"
                          }
                        ],
                        staticStyle: { width: "98%" }
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "row-format gap-2 justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary-action",
                                  on: {
                                    click: function($event) {
                                      return _vm.addNewDeliverable()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-secondary" },
                                    [_vm._v("Add task")]
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "", color: "secondary" }
                                    },
                                    [_vm._v("add")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "draggable",
                                {
                                  staticClass: "column-format gap-2",
                                  attrs: { list: _vm.pkg.deliverables }
                                },
                                _vm._l(_vm.pkg.deliverables, function(
                                  deliverable
                                ) {
                                  return _c(
                                    "deliverable-package-task",
                                    {
                                      key: deliverable.id,
                                      staticClass: "pa-1",
                                      staticStyle: {
                                        border:
                                          "1px solid var(--v-gray_30-base)",
                                        "border-radius": "4px"
                                      },
                                      attrs: {
                                        "hide-icon": true,
                                        deliverable: deliverable
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.editDeliverable(
                                            deliverable
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" >")]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm.pkg.deliverables.length === 0
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "text-center font-gray_70" },
                                  [_vm._v("No tasks")]
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "brand-medium font-18" }, [
      _c("div", [_vm._v("Edit package settings")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }