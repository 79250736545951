var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cardWrapper" }, [
    _vm.deliverable.emptyCard
      ? _c(
          "div",
          {
            staticClass: "blankCard mx-0 my-2 pl-4 pr-0 pt-2",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.$emit("add-new-empty")
              }
            }
          },
          [
            _c("v-icon", { attrs: { size: "34" } }, [_vm._v("$duplicate")]),
            _c("span", { staticClass: "font-grey" }, [
              _vm._v(_vm._s(_vm.$t("projects.add-first-deliverable")))
            ])
          ],
          1
        )
      : _c("div", [
          _c("div", { staticClass: "card pa-4" }, [
            _c("div", { staticClass: "column-format gap-2 flex-grow-1" }, [
              !_vm.singleProjectMode &&
              _vm.deliverable.client &&
              _vm.isVisible("project")
                ? _c(
                    "div",
                    { staticClass: "row-format align-center" },
                    [
                      _vm.deliverable.client
                        ? _c("client-avatar", {
                            attrs: {
                              small: true,
                              client: _vm.deliverable.client
                            }
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "font-12 column-format text-truncate",
                          staticStyle: { "margin-left": "6px" }
                        },
                        [
                          _c("div", { staticClass: "brand-bold" }, [
                            _vm._v(
                              _vm._s(
                                _vm.deliverable.client
                                  ? _vm.deliverable.client.name
                                  : ""
                              )
                            )
                          ]),
                          _vm.projectName
                            ? _c("div", { staticClass: "mt-n1" }, [
                                _vm._v(_vm._s(_vm.projectName))
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "font-14 column-format gap-1" }, [
                _c("div", { staticStyle: { "line-height": "1.1" } }, [
                  _vm._v(_vm._s(_vm.deliverableName))
                ])
              ]),
              _vm.isVisible("status")
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "deliverableStatus row-format align-center",
                        style: "--status-color:" + _vm.status.hexColor
                      },
                      [
                        _c("div", { staticClass: "statusBox" }),
                        _c("div", { staticClass: "text-no-wrap" }, [
                          _vm._v(_vm._s(_vm.status.label))
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "column-format gap-1" },
                [
                  _vm._l(_vm.activeCustomFields, function(field) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.getCustomValue(field.id),
                            expression: "getCustomValue(field.id)"
                          }
                        ],
                        key: field.id,
                        staticClass: "font-12"
                      },
                      [
                        _c("span", { staticClass: "font-gray_60" }, [
                          _vm._v(_vm._s(field.name) + ": ")
                        ]),
                        _c("span", {}, [
                          _vm._v(_vm._s(_vm.getCustomValue(field.id)))
                        ])
                      ]
                    )
                  }),
                  _vm.isVisible("priority")
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "deliverableStatus row-format align-center highlight-hover pr-1",
                          style: "--status-color:" + _vm.priorityColor
                        },
                        [
                          _c("div", [
                            _c("span", { staticClass: "font-gray_60" }, [
                              _vm._v("Priority:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.deliverable.taskPriority))
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.isVisible("rank")
                    ? _c("div", { staticClass: "font-12" }, [
                        _c("div", [
                          _c("span", { staticClass: "font-gray_60" }, [
                            _vm._v("Rank:")
                          ]),
                          _vm._v(" " + _vm._s(_vm.deliverable.priority))
                        ])
                      ])
                    : _vm._e()
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "row-format align-center gap-2" },
                [
                  _vm.deliverable.archived
                    ? _c(
                        "v-icon",
                        {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: { content: "Archived" },
                              expression: "{ content: 'Archived' }"
                            }
                          ],
                          attrs: { size: "20", color: "gray_70" }
                        },
                        [_vm._v("lock")]
                      )
                    : _vm._e(),
                  _vm.deliverable.invoiceId
                    ? _c(
                        "v-icon",
                        {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: {
                                content:
                                  "Included in invoice " +
                                  _vm.deliverable.invoiceNumber
                              },
                              expression:
                                "{ content: `Included in invoice ${deliverable.invoiceNumber}` }"
                            }
                          ],
                          staticClass: "ml-auto",
                          attrs: { color: "success", small: "" }
                        },
                        [_vm._v("attach_money")]
                      )
                    : _vm._e(),
                  _vm.deliverable.assignedToList.length &&
                  _vm.isVisible("assignedTo")
                    ? _c(
                        "div",
                        { staticClass: "row-format align-center" },
                        _vm._l(_vm.deliverable.assignedToList, function(
                          assignedTo,
                          index
                        ) {
                          return _c("assigned-user", {
                            key: assignedTo,
                            style:
                              (index > 0 ? "margin-left: -6px" : "") +
                              "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                              index,
                            attrs: {
                              "show-name": false,
                              small: true,
                              "assigned-to": assignedTo
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  (_vm.deliverable.dueDate || _vm.deliverable.startDate) &&
                  (_vm.isVisible("startDate") || _vm.isVisible("dueDate"))
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "font-12 font-grey row-format align-center"
                        },
                        [_vm._v(" " + _vm._s(_vm.timeline) + " ")]
                      )
                    : _vm._e(),
                  _vm.deliverable.comments.length && _vm.isVisible("comments")
                    ? _c(
                        "div",
                        {
                          staticClass: "ml-auto",
                          staticStyle: { position: "relative" }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "gray_70", size: "20" } },
                            [_vm._v("comment")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "row-format centered brand-bold",
                              staticStyle: {
                                position: "absolute",
                                top: "0",
                                left: "12px",
                                width: "16px",
                                height: "16px",
                                "border-radius": "10px",
                                color: "var(--v-white-base)",
                                "background-color": "var(--v-secondary-base)"
                              }
                            },
                            [
                              _c("div", { staticClass: "font-12" }, [
                                _vm._v(_vm._s(_vm.deliverable.comments.length))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                staticClass: "row-format mt-1",
                staticStyle: { "justify-content": "space-between" }
              },
              [
                _vm.isVisible("toDos")
                  ? _c("div", { staticClass: "row-format align-center" }, [
                      _vm.deliverable.tasks.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "font-12 font-grey pointer row-format align-center",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.showTasks = !_vm.showTasks
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v("check")
                              ]),
                              _c("div", { staticClass: "ml-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getTaskCountLabel(_vm.deliverable.tasks)
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.deliverable.tasks.length === 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "font-12 font-grey pointer row-format align-center",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.addFirstTask()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v("check")
                              ]),
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v("add")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _c("div", [
                  _vm.currentTimer
                    ? _c(
                        "div",
                        { staticClass: "row-format align-center gap-1" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "material-symbols-rounded",
                              attrs: { size: "20", color: "gray_50" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.stopRunningTimer()
                                }
                              }
                            },
                            [_vm._v("stop_circle")]
                          ),
                          _c("div", { staticClass: "font-12 font-gray_70" }, [
                            _vm._v(_vm._s(_vm.$store.state.currentTimerDisplay))
                          ])
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "show-on-hover" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "material-symbols-rounded",
                              attrs: { size: "20", color: "gray_30" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.startRunningTimer()
                                }
                              }
                            },
                            [_vm._v("play_circle")]
                          )
                        ],
                        1
                      )
                ]),
                !_vm.deliverable.parentTaskId
                  ? _c(
                      "div",
                      {
                        staticClass: "row-format align-center",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.showSubtasks = !_vm.showSubtasks
                          }
                        }
                      },
                      [
                        _vm.deliverable.subtasks &&
                        _vm.deliverable.subtasks.length
                          ? _c(
                              "div",
                              {
                                staticClass: "font-12 row-format centered pa-1"
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.deliverable.subtasks.length)
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-icon",
                          {
                            staticClass: "material-symbols-rounded",
                            attrs: {
                              size: "20",
                              color:
                                _vm.deliverable.subtasks &&
                                _vm.deliverable.subtasks.length
                                  ? "black"
                                  : "gray_30"
                            }
                          },
                          [_vm._v("graph_2")]
                        )
                      ],
                      1
                    )
                  : _c("div", [_vm._v(" ")])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTasks && _vm.deliverable.tasks.length > 0,
                    expression: "showTasks && deliverable.tasks.length > 0"
                  }
                ]
              },
              [
                _c("tasks", {
                  ref: "tasks",
                  on: {
                    empty: function($event) {
                      _vm.showTasks = false
                    },
                    change: function($event) {
                      return _vm.updateTasks($event)
                    }
                  },
                  model: {
                    value: _vm.deliverable.tasks,
                    callback: function($$v) {
                      _vm.$set(_vm.deliverable, "tasks", $$v)
                    },
                    expression: "deliverable.tasks"
                  }
                })
              ],
              1
            )
          ]),
          _vm.showSubtasks
            ? _c(
                "div",
                {
                  staticClass: "column-format ml-4 mb-5",
                  staticStyle: {
                    "border-left": "1px solid var(--v-gray_30-base)"
                  }
                },
                [
                  _vm._l(_vm.deliverable.subtasks, function(subtask) {
                    return _c(
                      "div",
                      {
                        key: subtask.id,
                        staticClass: "row-format align-center mt-2",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("edit-subtask", subtask)
                          }
                        }
                      },
                      [
                        _c("div", {
                          staticStyle: {
                            "border-top": "1px solid var(--v-gray_30-base)",
                            height: "1px",
                            width: "18px",
                            "max-width": "18px"
                          }
                        }),
                        _c("kanban-card", {
                          staticStyle: { "flex-grow": "1" },
                          attrs: {
                            deliverable: subtask,
                            "status-list": _vm.statusList,
                            "custom-fields": _vm.customFields,
                            "single-project-mode": true,
                            "visible-fields": _vm.visibleFields
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "row-format align-center mt-2",
                      staticStyle: { "margin-bottom": "-9px" }
                    },
                    [
                      _c("div", {
                        staticStyle: {
                          "border-top": "1px solid var(--v-gray_30-base)",
                          height: "1px",
                          width: "18px",
                          "max-width": "18px"
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "pointer highlight-hover font-12 pl-1",
                          staticStyle: {
                            width: "fit-content",
                            color: "var(--v-gray_50-base)"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("add-subtask")
                            }
                          }
                        },
                        [_vm._v(" + Add subtask ")]
                      )
                    ]
                  )
                ],
                2
              )
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }