var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    { staticClass: "font-14 text-left content-row" },
    [
      _c(
        "td",
        { staticClass: "content", staticStyle: { "max-width": "20px" } },
        [
          _c("div", { staticClass: "column-format align-center px-1" }, [
            _c("input", {
              attrs: { type: "checkbox" },
              domProps: { checked: _vm.checked, value: _vm.deliverable.id },
              on: {
                input: function($event) {
                  return _vm.$emit("toggle-checked")
                }
              }
            })
          ])
        ]
      ),
      _c(
        "td",
        {
          staticClass: "content",
          style: "min-width: " + (_vm.isSubTask ? "150px" : "200px") + ";"
        },
        [
          _c(
            "div",
            { staticClass: "row-format align-center task-name" },
            [
              _vm.deliverable.subtasks
                ? _c(
                    "v-icon",
                    {
                      staticClass: "pointer",
                      attrs: {
                        color: _vm.deliverable.subtasks.length
                          ? "black"
                          : "gray_20"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("toggle-expanded")
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.expanded[_vm.deliverable.id]
                            ? "keyboard_arrow_down"
                            : "chevron_right"
                        )
                      )
                    ]
                  )
                : _vm._e(),
              _vm.deliverable.invoiceId
                ? _c(
                    "v-icon",
                    {
                      directives: [
                        {
                          name: "tippy",
                          rawName: "v-tippy",
                          value: {
                            content:
                              "Included in invoice " +
                              _vm.deliverable.invoiceNumber
                          },
                          expression:
                            "{ content: `Included in invoice ${deliverable.invoiceNumber}` }"
                        }
                      ],
                      attrs: { color: "success", small: "" }
                    },
                    [_vm._v("attach_money")]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "my-1 mx-1 edit-task-name",
                  staticStyle: { "min-width": "20px" },
                  attrs: {
                    id: "name-" + _vm.deliverable.id,
                    contenteditable: "true"
                  },
                  on: {
                    blur: function($event) {
                      return _vm.nameBlurred($event)
                    },
                    focus: function($event) {
                      return _vm.nameFocused()
                    },
                    keydown: _vm.handleKeyDown
                  }
                },
                [_vm._v(" " + _vm._s(_vm.deliverable.name) + " ")]
              ),
              _vm.deliverable.subtasks && _vm.deliverable.subtasks.length
                ? _c(
                    "div",
                    { staticClass: "ml-1 subtask-count row-format centered" },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.deliverable.subtasks.length))
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "ml-auto row-format align-center action-box" },
                [
                  _c("div", [
                    _vm.currentTimer
                      ? _c(
                          "div",
                          { staticClass: "row-format align-center gap-1" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "material-symbols-rounded",
                                attrs: { size: "20", color: "gray_50" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.stopRunningTimer()
                                  }
                                }
                              },
                              [_vm._v("stop_circle")]
                            ),
                            _c("div", { staticClass: "font-12 font-gray_70" }, [
                              _vm._v(
                                _vm._s(_vm.$store.state.currentTimerDisplay)
                              )
                            ])
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "show-on-hover" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "material-symbols-rounded",
                                attrs: { size: "20", color: "gray_30" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.startRunningTimer()
                                  }
                                }
                              },
                              [_vm._v("play_circle")]
                            )
                          ],
                          1
                        )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row-format align-center open-icon font-14 pointer",
                      on: {
                        click: function($event) {
                          return _vm.$emit("edit-deliverable")
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { size: "18", color: "primary" }
                        },
                        [_vm._v("expand_content")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "font-12 brand-medium",
                          staticStyle: { "margin-left": "2px" }
                        },
                        [_vm._v("Open")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      !_vm.singleProjectMode && _vm.isVisible("project")
        ? _c(
            "td",
            { staticClass: "content", staticStyle: { "min-width": "200px" } },
            [
              _c(
                "div",
                { staticClass: "row-format align-center project-name" },
                [
                  _vm.deliverable.client
                    ? _c("client-avatar", {
                        attrs: {
                          client: _vm.deliverable.client,
                          "x-small": true
                        }
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "ml-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.deliverable.project
                            ? _vm.deliverable.project.name
                            : ""
                        ) +
                        " "
                    )
                  ]),
                  _vm.deliverable.project
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "ml-auto row-format align-center open-icon font-14 pointer",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("route-to-project", $event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "material-symbols-rounded",
                              attrs: { size: "18", color: "primary" }
                            },
                            [_vm._v("expand_content")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "font-12 brand-medium",
                              staticStyle: { "margin-left": "2px" }
                            },
                            [_vm._v("Open project")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.isVisible("status")
        ? _c("td", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "row-format align-center gap-2" },
              [
                _c(
                  "v-menu",
                  {
                    attrs: {
                      "close-on-click": true,
                      "close-on-content-click": true
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  {
                                    staticClass:
                                      "deliverableStatus row-format align-center pointer",
                                    style:
                                      "--status-color:" +
                                      _vm.deliverable.status.hexColor
                                  },
                                  on
                                ),
                                [
                                  _c("div", { staticClass: "statusBox" }),
                                  _c("div", { staticClass: "text-no-wrap" }, [
                                    _vm._v(_vm._s(_vm.deliverable.status.label))
                                  ])
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      956763766
                    )
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left pa-3 font-14",
                        staticStyle: {
                          "background-color": "var(--v-white-base)"
                        }
                      },
                      _vm._l(_vm.statusList, function(status) {
                        return _c(
                          "div",
                          {
                            key: status.id,
                            staticClass: "py-1 pointer",
                            on: {
                              click: function($event) {
                                return _vm.$emit("set-status", status)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "deliverableStatus row-format align-center highlight-hover",
                                style: "--status-color:" + status.hexColor
                              },
                              [
                                _c("div", { staticClass: "statusBox" }),
                                _c("div", [_vm._v(_vm._s(status.label))])
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm.deliverable.archived
                  ? _c(
                      "v-icon",
                      {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: { content: "Archived" },
                            expression: "{ content: 'Archived' }"
                          }
                        ],
                        staticClass: "ml-auto",
                        attrs: { size: "20", color: "gray_70" }
                      },
                      [_vm._v("lock")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.isVisible("priority")
        ? _c(
            "td",
            { staticClass: "content" },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-click": true,
                    "close-on-content-click": true,
                    "offset-y": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g(
                                {
                                  staticClass:
                                    "deliverableStatus row-format align-center highlight-hover pr-1 pointer",
                                  style: "--status-color:" + _vm.priorityColor
                                },
                                on
                              ),
                              [
                                _c("div", { staticClass: "statusBox" }),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.deliverable.taskPriority))
                                ])
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    912680834
                  )
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "text-left pa-3 font-14",
                      staticStyle: { "background-color": "var(--v-white-base)" }
                    },
                    _vm._l(_vm.priorityList, function(priority) {
                      return _c(
                        "div",
                        {
                          key: priority,
                          staticClass: "py-1 pointer",
                          on: {
                            click: function($event) {
                              return _vm.$emit("set-task-priority", priority)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row-format align-center highlight-hover"
                            },
                            [_c("div", [_vm._v(_vm._s(priority))])]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isVisible("assignedTo")
        ? _c("td", { staticClass: "content" }, [
            _c(
              "div",
              { staticStyle: { width: "fit-content" } },
              [
                _c(
                  "v-menu",
                  {
                    attrs: {
                      "close-on-click": true,
                      "close-on-content-click": false
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "div",
                                _vm._g({ staticClass: "ml-auto pointer" }, on),
                                [
                                  _vm.deliverable.assignedToList.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "row-format align-center"
                                        },
                                        _vm._l(
                                          _vm.deliverable.assignedToList,
                                          function(assignedTo, index) {
                                            return _c(
                                              "assigned-user",
                                              _vm._g(
                                                {
                                                  key: assignedTo,
                                                  style:
                                                    (index > 0
                                                      ? "margin-left: -6px"
                                                      : "") +
                                                    "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                                                    index,
                                                  attrs: {
                                                    "show-name": false,
                                                    small: true,
                                                    "assigned-to": assignedTo
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _c("div", [_vm._v(" -- ")])
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1605812722
                    )
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left pa-3 font-14",
                        staticStyle: {
                          "background-color": "var(--v-white-base)"
                        }
                      },
                      _vm._l(
                        _vm.usersForProject(_vm.deliverable.projectId),
                        function(user) {
                          return _c(
                            "div",
                            {
                              key: user.userId,
                              staticClass:
                                "row-format align-center text-left py-1 pointer"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.deliverable.assignedToList,
                                    expression: "deliverable.assignedToList"
                                  }
                                ],
                                staticStyle: {
                                  width: "20px",
                                  height: "20px",
                                  "border-radius": "4px"
                                },
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: user.userId,
                                  checked: Array.isArray(
                                    _vm.deliverable.assignedToList
                                  )
                                    ? _vm._i(
                                        _vm.deliverable.assignedToList,
                                        user.userId
                                      ) > -1
                                    : _vm.deliverable.assignedToList
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.deliverable.assignedToList,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = user.userId,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.deliverable,
                                              "assignedToList",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.deliverable,
                                              "assignedToList",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.deliverable,
                                          "assignedToList",
                                          $$c
                                        )
                                      }
                                    },
                                    function($event) {
                                      return _vm.$emit("set-assigned-to")
                                    }
                                  ]
                                }
                              }),
                              user.userId
                                ? _c("assigned-user", {
                                    staticClass: "mx-2",
                                    attrs: {
                                      "assigned-to": user.userId,
                                      "show-name": true
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }
                      ),
                      0
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.isVisible("startDate")
        ? _c(
            "td",
            { staticClass: "content" },
            [
              _c("date-selector", {
                attrs: {
                  micro: true,
                  "micro-font": "font-14",
                  date: _vm.deliverable.startDate,
                  label: "&nbsp;"
                },
                on: {
                  change: function($event) {
                    return _vm.$emit("set-start-date", $event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isVisible("dueDate")
        ? _c(
            "td",
            { staticClass: "content" },
            [
              _c("date-selector", {
                attrs: {
                  micro: true,
                  "micro-font": "font-14",
                  date: _vm.deliverable.dueDate,
                  label: "&nbsp;"
                },
                on: {
                  change: function($event) {
                    return _vm.$emit("set-due-date", $event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.filteredCustomFields, function(field) {
        return _c(
          "td",
          { key: field.id, staticClass: "content" },
          [
            _c("custom-field", {
              attrs: {
                field: field,
                value: _vm.getCustomValue(_vm.deliverable, field.id)
              },
              on: {
                change: function($event) {
                  return _vm.$emit("custom-field-change", $event)
                }
              }
            })
          ],
          1
        )
      }),
      _vm.isVisible("rank")
        ? _c("td", { staticClass: "content" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.deliverable.priority,
                  expression: "deliverable.priority"
                }
              ],
              staticClass: "numberInput",
              attrs: {
                type: "number",
                oninput: "this.value=(parseInt(this.value)||0)",
                step: "1"
              },
              domProps: { value: _vm.deliverable.priority },
              on: {
                change: function($event) {
                  return _vm.$emit("set-priority")
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.deliverable, "priority", $event.target.value)
                }
              }
            })
          ])
        : _vm._e(),
      _vm.isVisible("comments")
        ? _c(
            "td",
            { staticClass: "content" },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-click": true,
                    "close-on-content-click": false
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _vm.deliverable.comments.length
                              ? _c(
                                  "div",
                                  _vm._g(
                                    {
                                      staticClass: "pointer",
                                      staticStyle: { position: "relative" }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "gray_70", size: "20" }
                                      },
                                      [_vm._v("comment")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row-format centered brand-bold",
                                        staticStyle: {
                                          position: "absolute",
                                          top: "0",
                                          left: "12px",
                                          width: "16px",
                                          height: "16px",
                                          "border-radius": "10px",
                                          color: "var(--v-white-base)",
                                          "background-color":
                                            "var(--v-secondary-base)"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "font-12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.deliverable.comments.length
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  _vm._g({ staticClass: "pointer" }, on),
                                  [_vm._v(" ")]
                                )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3144345223
                  )
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pa-4",
                      staticStyle: {
                        "background-color": "var(--v-white-base)",
                        "min-width": "400px",
                        "max-width": "400px",
                        "min-height": "200px"
                      }
                    },
                    [
                      _c("activity", {
                        attrs: {
                          deliverable: _vm.deliverable,
                          project: _vm.deliverable.project,
                          client: _vm.deliverable.client,
                          "user-list":
                            _vm.usersByProject[_vm.deliverable.projectId]
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isVisible("toDos")
        ? _c(
            "td",
            { staticClass: "content" },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-click": true,
                    "close-on-content-click": false
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c("div", _vm._g({ staticClass: "pointer" }, on), [
                              _vm.deliverable.tasks.length
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getTaskCountLabel(
                                          _vm.deliverable.tasks
                                        )
                                      )
                                    )
                                  ])
                                : _c("div", [_vm._v(" ")])
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    156330590
                  )
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pa-4",
                      staticStyle: {
                        "min-width": "300px",
                        "max-width": "400px",
                        "background-color": "var(--v-white-base)"
                      }
                    },
                    [
                      _c("tasks", {
                        attrs: { "detail-view": true },
                        on: {
                          change: function($event) {
                            return _vm.$emit("set-tasks", $event)
                          }
                        },
                        model: {
                          value: _vm.deliverable.tasks,
                          callback: function($$v) {
                            _vm.$set(_vm.deliverable, "tasks", $$v)
                          },
                          expression: "deliverable.tasks"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c("td", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "row-format centered gap-2" },
          [
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-content-click": true,
                  "close-on-click": true,
                  bottom: "",
                  "nudge-bottom": "30"
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(scope) {
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            {
                              staticClass: "pointer highlight-hover",
                              attrs: { size: "18", color: "black" }
                            },
                            scope.on
                          ),
                          [
                            _vm._v(
                              _vm._s(
                                scope.value ? "arrow_drop_up" : "more_horiz"
                              )
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _c("div", { staticClass: "more-menu" }, [
                  _c(
                    "div",
                    {
                      staticClass: "more-menu-item",
                      on: {
                        click: function($event) {
                          return _vm.$emit("edit-deliverable", _vm.deliverable)
                        }
                      }
                    },
                    [_vm._v(" Open ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "more-menu-item",
                      on: {
                        click: function($event) {
                          return _vm.$emit("get-share-link")
                        }
                      }
                    },
                    [_vm._v("Share")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "more-menu-item",
                      on: {
                        click: function($event) {
                          return _vm.$emit("confirm-duplicate")
                        }
                      }
                    },
                    [_vm._v("Duplicate")]
                  ),
                  _vm.deliverable.parentTaskId
                    ? _c(
                        "div",
                        {
                          staticClass: "more-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.$emit("make-task")
                            }
                          }
                        },
                        [_vm._v("Convert to task")]
                      )
                    : _vm._e(),
                  _vm.deliverable.parentTaskId
                    ? _c(
                        "div",
                        {
                          staticClass: "more-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.$emit("move-sub-task")
                            }
                          }
                        },
                        [_vm._v("Move sub-task")]
                      )
                    : _vm._e(),
                  !_vm.deliverable.parentTaskId
                    ? _c(
                        "div",
                        {
                          staticClass: "more-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.$emit("move-sub-task")
                            }
                          }
                        },
                        [_vm._v("Convert to sub-task")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "more-menu-item",
                      on: {
                        click: function($event) {
                          return _vm.$emit("toggle-archive")
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.deliverable.archived ? "Unarchive" : "Archive"
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "more-menu-item",
                      on: {
                        click: function($event) {
                          return _vm.$emit("confirm-delete")
                        }
                      }
                    },
                    [_vm._v(" Delete ")]
                  )
                ])
              ]
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }