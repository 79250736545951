var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "deliverable-table-wrapper" } }, [
    _c("input", {
      attrs: { id: "copyToClip", name: "copyToClip", value: "", type: "hidden" }
    }),
    _c(
      "table",
      {
        staticClass: "deliverable-table table",
        attrs: { id: "deliverable-table" }
      },
      [
        _vm._l(_vm.deliverableGroupings, function(group, index) {
          return [
            _c(
              "tbody",
              { key: index },
              [
                group.groupKey
                  ? _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "group-heading",
                          staticStyle: { "padding-left": "8px!important" },
                          style: index > 0 ? "padding-top: 24px" : "",
                          attrs: {
                            colspan:
                              (_vm.singleProjectMode ? 9 : 10) +
                              _vm.customFields.length
                          }
                        },
                        [_vm._v(" " + _vm._s(group.groupLabel) + " ")]
                      )
                    ])
                  : _vm._e(),
                _c("deliverable-list-header", {
                  attrs: {
                    "is-sub-task": false,
                    "single-project-mode": _vm.singleProjectMode,
                    "custom-fields": _vm.customFields,
                    "visible-fields": _vm.visibleFields
                  },
                  on: {
                    "toggle-checked": function($event) {
                      return _vm.toggleAllChecked(group.deliverables, $event)
                    }
                  }
                }),
                _vm._l(group.deliverables, function(deliverable) {
                  return [
                    _c("deliverable-list-row", {
                      key: deliverable.id,
                      attrs: {
                        deliverable: deliverable,
                        expanded: _vm.expanded,
                        "is-sub-task": false,
                        "single-project-mode": _vm.singleProjectMode,
                        "status-list": _vm.statusList,
                        "custom-fields": _vm.customFields,
                        "all-projects": _vm.allProjects,
                        "selected-tasks": _vm.selectedTaskIds,
                        "visible-fields": _vm.visibleFields
                      },
                      on: {
                        "name-focused": function($event) {
                          return _vm.setActiveLabel(group.groupLabel)
                        },
                        "name-blurred": function($event) {
                          return _vm.setActiveLabel(null)
                        },
                        "toggle-expanded": function($event) {
                          return _vm.toggleExpanded(deliverable.id)
                        },
                        "toggle-checked": function($event) {
                          return _vm.toggleChecked(deliverable)
                        },
                        "set-name": function($event) {
                          return _vm.setName(deliverable, $event)
                        },
                        "edit-deliverable": function($event) {
                          return _vm.editDeliverable(deliverable)
                        },
                        "route-to-project": function($event) {
                          return _vm.routeToProject(deliverable.project, $event)
                        },
                        "set-status": function($event) {
                          return _vm.setStatus(deliverable, $event)
                        },
                        "custom-field-change": function($event) {
                          return _vm.handleCustomFieldChange(
                            deliverable,
                            $event
                          )
                        },
                        "set-priority": function($event) {
                          return _vm.setPriority(deliverable)
                        },
                        "set-task-priority": function($event) {
                          return _vm.setTaskPriority(deliverable, $event)
                        },
                        "set-assigned-to": function($event) {
                          return _vm.setAssignedToList(deliverable)
                        },
                        "set-start-date": function($event) {
                          return _vm.setStartDate(deliverable, $event)
                        },
                        "set-due-date": function($event) {
                          return _vm.setDueDate(deliverable, $event)
                        },
                        "set-tasks": function($event) {
                          return _vm.setTasks(deliverable, $event)
                        },
                        "get-share-link": function($event) {
                          return _vm.getShareLink(deliverable)
                        },
                        "confirm-duplicate": function($event) {
                          return _vm.confirmDuplicate(deliverable)
                        },
                        "toggle-archive": function($event) {
                          return _vm.toggleArchive(deliverable)
                        },
                        "confirm-delete": function($event) {
                          return _vm.confirmDeleteDeliverable(deliverable)
                        },
                        "add-new-task": function($event) {
                          return _vm.addDeliverable(group)
                        },
                        "make-task": function($event) {
                          return _vm.makeMainTask(deliverable)
                        },
                        "move-sub-task": function($event) {
                          return _vm.moveSubTask(deliverable)
                        }
                      }
                    }),
                    _vm.expanded[deliverable.id]
                      ? _c("tr", { key: "expanded-" + deliverable.id }, [
                          _c(
                            "td",
                            {
                              attrs: { colspan: _vm.visibleFields.length + 2 }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "px-5 pt-4 pb-2 row-format centered",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _c(
                                    "table",
                                    { staticClass: "deliverable-table table" },
                                    [
                                      _c("deliverable-list-header", {
                                        attrs: {
                                          "is-sub-task": true,
                                          "single-project-mode": true,
                                          "custom-fields": _vm.customFields,
                                          "visible-fields": _vm.visibleFields
                                        },
                                        on: {
                                          "toggle-checked": function($event) {
                                            return _vm.toggleAllChecked(
                                              deliverable.subtasks,
                                              $event
                                            )
                                          }
                                        }
                                      }),
                                      _vm._l(deliverable.subtasks, function(
                                        subtask
                                      ) {
                                        return [
                                          _c("deliverable-list-row", {
                                            key: subtask.id,
                                            attrs: {
                                              deliverable: subtask,
                                              "is-subtask": true,
                                              "single-project-mode": true,
                                              "status-list": _vm.statusList,
                                              "custom-fields": _vm.customFields,
                                              "all-projects": _vm.allProjects,
                                              "selected-tasks":
                                                _vm.selectedTaskIds,
                                              "visible-fields":
                                                _vm.visibleFields
                                            },
                                            on: {
                                              "name-focused": function($event) {
                                                return _vm.setActiveLabel(
                                                  deliverable.id
                                                )
                                              },
                                              "name-blurred": function($event) {
                                                return _vm.setActiveLabel(null)
                                              },
                                              "toggle-expanded": function(
                                                $event
                                              ) {
                                                return _vm.toggleExpanded(
                                                  subtask.id
                                                )
                                              },
                                              "toggle-checked": function(
                                                $event
                                              ) {
                                                return _vm.toggleChecked(
                                                  subtask
                                                )
                                              },
                                              "set-name": function($event) {
                                                return _vm.setName(
                                                  subtask,
                                                  $event
                                                )
                                              },
                                              "edit-deliverable": function(
                                                $event
                                              ) {
                                                return _vm.editDeliverable(
                                                  subtask
                                                )
                                              },
                                              "route-to-project": function(
                                                $event
                                              ) {
                                                return _vm.routeToProject(
                                                  subtask.project,
                                                  $event
                                                )
                                              },
                                              "set-status": function($event) {
                                                return _vm.setStatus(
                                                  subtask,
                                                  $event
                                                )
                                              },
                                              "custom-field-change": function(
                                                $event
                                              ) {
                                                return _vm.handleCustomFieldChange(
                                                  subtask,
                                                  $event
                                                )
                                              },
                                              "set-priority": function($event) {
                                                return _vm.setPriority(subtask)
                                              },
                                              "set-task-priority": function(
                                                $event
                                              ) {
                                                return _vm.setTaskPriority(
                                                  subtask,
                                                  $event
                                                )
                                              },
                                              "set-assigned-to": function(
                                                $event
                                              ) {
                                                return _vm.setAssignedToList(
                                                  subtask
                                                )
                                              },
                                              "set-start-date": function(
                                                $event
                                              ) {
                                                return _vm.setStartDate(
                                                  subtask,
                                                  $event
                                                )
                                              },
                                              "set-due-date": function($event) {
                                                return _vm.setDueDate(
                                                  subtask,
                                                  $event
                                                )
                                              },
                                              "set-tasks": function($event) {
                                                return _vm.setTasks(
                                                  subtask,
                                                  $event
                                                )
                                              },
                                              "get-share-link": function(
                                                $event
                                              ) {
                                                return _vm.getShareLink(subtask)
                                              },
                                              "confirm-duplicate": function(
                                                $event
                                              ) {
                                                return _vm.confirmDuplicate(
                                                  subtask
                                                )
                                              },
                                              "toggle-archive": function(
                                                $event
                                              ) {
                                                return _vm.toggleArchive(
                                                  subtask
                                                )
                                              },
                                              "confirm-delete": function(
                                                $event
                                              ) {
                                                return _vm.confirmDeleteDeliverable(
                                                  subtask
                                                )
                                              },
                                              "add-new-task": function($event) {
                                                return _vm.addSubTask(
                                                  deliverable
                                                )
                                              },
                                              "make-task": function($event) {
                                                return _vm.makeMainTask(subtask)
                                              },
                                              "move-sub-task": function(
                                                $event
                                              ) {
                                                return _vm.moveSubTask(subtask)
                                              }
                                            }
                                          })
                                        ]
                                      }),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "footer",
                                            staticStyle: { border: "none" },
                                            attrs: { colspan: "8" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pointer mt-1 highlight-hover font-12",
                                                staticStyle: {
                                                  width: "fit-content"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addSubTask(
                                                      deliverable
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm.activeLabel ===
                                                deliverable.id
                                                  ? _c("span", [
                                                      _c("strong", [
                                                        _vm._v("Shift + Enter")
                                                      ]),
                                                      _vm._v(
                                                        " to add a new subtask"
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("+ Add subtask")
                                                    ])
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                }),
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass: "footer",
                      staticStyle: { border: "none" },
                      attrs: { colspan: "10" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pointer mt-1 highlight-hover font-12",
                          staticStyle: { width: "fit-content" },
                          on: {
                            click: function($event) {
                              return _vm.addDeliverable(group)
                            }
                          }
                        },
                        [
                          _vm.activeLabel === group.groupLabel
                            ? _c("span", [
                                _c("strong", [_vm._v("Shift + Enter")]),
                                _vm._v(" to add a new task")
                              ])
                            : _c("span", [_vm._v("+ Add task")])
                        ]
                      )
                    ]
                  )
                ])
              ],
              2
            )
          ]
        })
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "column-format centered" },
      [
        _vm.selectedTaskIds.length
          ? _c("bulk-actions", {
              attrs: {
                tasks: _vm.selectedTasks,
                label: "Tasks",
                "status-list": _vm.statusList,
                "user-list": _vm.fullAccessUsers
              },
              on: {
                "task-updated": function($event) {
                  return _vm.bulkTaskUpdated($event)
                }
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }