var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-6", staticStyle: { width: "100%" } },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Accounting" }
            ]
          }
        },
        [_vm._v(" Reports ")]
      ),
      _c(
        "div",
        { staticClass: "row-format align-center" },
        [
          _vm.filter
            ? _c("report-filter", {
                attrs: { value: _vm.filter },
                on: {
                  updated: function($event) {
                    return _vm.filterUpdated($event)
                  }
                }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "ml-auto row-format align-center gap-2" },
            [
              _c("v-select", {
                staticClass: "standard-input",
                staticStyle: { "max-width": "190px" },
                attrs: {
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  items: ["Month over month", "Cumulative"],
                  "menu-props": { nudgeBottom: 35 }
                },
                model: {
                  value: _vm.chartMode,
                  callback: function($$v) {
                    _vm.chartMode = $$v
                  },
                  expression: "chartMode"
                }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "ml-auto super-action",
                  on: {
                    click: function($event) {
                      return _vm.$refs.profitAndLoss.downloadReport()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "row-format align-center" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { small: "" } },
                        [_vm._v("file_download")]
                      ),
                      _c("div", [_vm._v("Download")])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.earliest && _vm.latest
        ? _c(
            "div",
            { staticClass: "mt-4", staticStyle: { width: "100%" } },
            [
              _c("p-l-report", {
                ref: "profitAndLoss",
                attrs: {
                  earliest: _vm.earliest,
                  latest: _vm.latest,
                  "refresh-key": _vm.refreshKey,
                  "chart-mode": _vm.chartMode
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }