var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Accounting" }
            ]
          }
        },
        [_vm._v(" Vendors ")]
      ),
      _c(
        "div",
        { staticClass: "row-format align-center mb-4" },
        [
          _c("v-text-field", {
            staticClass: "standard-input",
            style: "max-width: 200px;",
            attrs: {
              outlined: "",
              "hide-details": "",
              dense: "",
              placeholder: "Search...",
              color: ""
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-inner",
                fn: function() {
                  return [
                    _c(
                      "span",
                      { staticClass: "material-symbols-rounded font-gray_50" },
                      [_vm._v("search")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.filter,
              callback: function($$v) {
                _vm.filter = $$v
              },
              expression: "filter"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto super-action",
              on: { click: _vm.createVendor }
            },
            [
              _c("v-icon", { attrs: { size: "20" } }, [_vm._v("add")]),
              _vm._v(" Add vendor")
            ],
            1
          )
        ],
        1
      ),
      _vm.vendors
        ? _c("v-data-table", {
            staticClass: "list-view",
            attrs: {
              id: "vendors-table",
              "fixed-header": "",
              headers: _vm.headers,
              items: _vm.filteredVendors,
              "items-per-page": -1,
              "hide-default-footer": true
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "tr",
                        {
                          key: item.id,
                          staticClass: "text-left",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function($event) {
                              return _vm.editVendor(item)
                            }
                          }
                        },
                        [
                          _c("td", [_vm._v(_vm._s(item.name))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.formatVendorContact(item.contact))
                            )
                          ]),
                          _c("td", {
                            domProps: {
                              innerHTML: _vm._s(_vm.formatVendorAddress(item))
                            }
                          }),
                          _c("td", [_vm._v(_vm._s(_vm.formatBalanceDue(item)))])
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3776490886
            )
          })
        : _vm._e(),
      _vm.vendorDetailDialog
        ? _c(
            "basic-modal",
            {
              key: _vm.vendorToEdit.id,
              ref: "editModal",
              attrs: { dialog: _vm.vendorDetailDialog },
              on: {
                close: function($event) {
                  _vm.vendorDetailDialog = false
                }
              }
            },
            [
              _c("vendor-detail", {
                attrs: { vendor: _vm.vendorToEdit, vendors: _vm.vendors },
                on: {
                  created: function($event) {
                    return _vm.handleCreate($event)
                  },
                  updated: function($event) {
                    return _vm.handleUpdate($event)
                  },
                  "delete-vendor-dialog": function($event) {
                    return _vm.handleDeleteVendor($event)
                  },
                  close: function($event) {
                    _vm.vendorDetailDialog = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.deleteVendorDialog,
          icon: "$vendor",
          "heading-text": _vm.$t("vendors.delete-confirm-heading"),
          "body-text": _vm.$t("vendors.delete-confirm-body")
        },
        on: {
          confirm: function($event) {
            _vm.deleteVendor(_vm.deletingVendor)
            _vm.deleteVendorDialog = false
          },
          close: function($event) {
            _vm.deleteVendorDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }