var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pa-0 ma-0 text-left",
      staticStyle: { "align-items": "start", overflow: "hidden" },
      attrs: { fluid: "" }
    },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-col", { attrs: { cols: "12", md: "4" } }, [
            _c(
              "div",
              { staticClass: "pr-4" },
              [
                _c(
                  "div",
                  { staticClass: "row-format align-center" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass:
                          "material-symbols-rounded material-symbols-rounded-thin ml-n1",
                        attrs: { size: "50", color: "black" }
                      },
                      [_vm._v("folder_special")]
                    ),
                    _vm.isAccountFullUser
                      ? _c(
                          "div",
                          { staticClass: "ml-auto row-format gap-2" },
                          [
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  { name: "tippy", rawName: "v-tippy" }
                                ],
                                staticStyle: { height: "40px" },
                                attrs: {
                                  elevation: "0",
                                  color: "white",
                                  content: "Edit project info"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("edit-project")
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "material-symbols-rounded",
                                    staticStyle: {
                                      "border-bottom":
                                        "1px solid var(--v-gray_70-base)"
                                    }
                                  },
                                  [_vm._v("edit")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "column-format gap-3" },
                  [
                    _c("div", { staticClass: "font-14" }, [
                      _c("span", { staticClass: "font-gray_70" }, [
                        _vm._v("Client")
                      ]),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticClass: "row-format align-center gap-1 pointer",
                          on: {
                            click: function($event) {
                              return _vm.routeToClient($event)
                            }
                          }
                        },
                        [
                          _c("client-avatar", {
                            attrs: {
                              small: true,
                              client: _vm.project.client,
                              "disable-click": true
                            }
                          }),
                          _c("div", [_vm._v(_vm._s(_vm.project.client.name))])
                        ],
                        1
                      )
                    ]),
                    _vm.multipleProjectTypes
                      ? _c("div", { staticClass: "font-14" }, [
                          _c("span", { staticClass: "font-gray_70" }, [
                            _vm._v("Project type")
                          ]),
                          _c("br"),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { content: "Change project type" },
                                  expression: "{content:'Change project type'}"
                                }
                              ],
                              staticClass: "pointer update-project",
                              on: {
                                click: function($event) {
                                  return _vm.$emit("update-project-type")
                                }
                              }
                            },
                            [
                              _vm._v(_vm._s(_vm.projectType) + " "),
                              _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "update-icon material-symbols-rounded",
                                  attrs: { size: "20", color: "primary" }
                                },
                                [_vm._v("sync_alt")]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "font-14" }, [
                      _c("span", { staticClass: "font-gray_70" }, [
                        _vm._v("Timeline")
                      ]),
                      _c("br"),
                      _c("span", [_vm._v(_vm._s(_vm.timeline))])
                    ]),
                    _c("div", { staticClass: "font-14" }, [
                      _c("div", { staticClass: "font-gray_70" }, [
                        _vm._v("Project owners")
                      ]),
                      _c("div", { staticStyle: { width: "fit-content" } }, [
                        _vm.project.projectOwners.length
                          ? _c(
                              "div",
                              { staticClass: "row-format align-center" },
                              _vm._l(_vm.project.projectOwners, function(
                                assignedTo,
                                index
                              ) {
                                return _c("assigned-user", {
                                  key: assignedTo,
                                  style:
                                    (index > 0 ? "margin-left: -6px" : "") +
                                    "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                                    index,
                                  attrs: {
                                    "show-name": false,
                                    small: true,
                                    "assigned-to": assignedTo
                                  }
                                })
                              }),
                              1
                            )
                          : _c("div", [_vm._v(" -- ")])
                      ])
                    ]),
                    _vm.$store.getters.hasInvoiceAccess
                      ? _c("div", { staticClass: "font-14" }, [
                          _c("span", { staticClass: "font-gray_70" }, [
                            _vm._v("Fees")
                          ]),
                          _c("br"),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.fees) }
                          }),
                          _vm.feesSecondary
                            ? _c(
                                "span",
                                { staticClass: "font-12 font-gray_70" },
                                [_c("br"), _vm._v(_vm._s(_vm.feesSecondary))]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "font-14" }, [
                      _c("span", { staticClass: "font-gray_70" }, [
                        _vm._v("Portal access")
                      ]),
                      _c("br"),
                      _c("span", [_vm._v(_vm._s(_vm.project.portalAccess))]),
                      _vm._v(" "),
                      _vm.project.portalAccessAssignedOnly
                        ? _c("span", [_vm._v("(Assigned tasks only)")])
                        : _vm._e()
                    ]),
                    _vm._l(_vm.customValues, function(customValue, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "column-format font-14" },
                        [
                          _c("div", { staticClass: "font-gray_70" }, [
                            _vm._v(_vm._s(customValue.label))
                          ]),
                          customValue.isLink
                            ? _c(
                                "div",
                                {
                                  staticClass: "text-link truncate",
                                  on: {
                                    click: function($event) {
                                      return _vm.openWebsite(customValue.value)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(customValue.value) + " ")]
                              )
                            : customValue.type === "Checkbox"
                            ? _c("div", { staticClass: "truncate" }, [
                                _vm._v(
                                  _vm._s(
                                    customValue.value.split("||").join(", ")
                                  )
                                )
                              ])
                            : _c("div", { staticClass: "truncate" }, [
                                _vm._v(_vm._s(customValue.value))
                              ])
                        ]
                      )
                    })
                  ],
                  2
                ),
                _c("div", { staticClass: "text-left font-18 mt-4" }, [
                  _vm._v("Files")
                ]),
                _c("file-attachments", {
                  attrs: {
                    "edit-mode": true,
                    files: _vm.project.files,
                    "file-service": _vm.attachmentService
                  }
                }),
                _c("div", { staticClass: "font-18 text-left mt-4" }, [
                  _vm._v("Collaborators")
                ]),
                _c("project-collaborators", { attrs: { project: _vm.project } })
              ],
              1
            )
          ]),
          _c("v-col", { attrs: { cols: "12", md: "8", align: "center" } }, [
            _c("div", { staticClass: "text-left" }, [
              _c("div", { staticClass: "font-18 text-left mb-2 px-2" }, [
                _vm._v("Your project insights")
              ]),
              _c("div", { staticClass: "row-format" }, [
                _c("div", { staticClass: "col col-6" }, [
                  _c(
                    "div",
                    {
                      staticClass: "insight-box column-format",
                      staticStyle: { height: "100%" }
                    },
                    [
                      _c("div", { staticClass: "pa-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-16 brand-medium font-gray_70 mb-2"
                          },
                          [_vm._v("Task progress")]
                        ),
                        _c(
                          "div",
                          { staticStyle: { height: "115px" } },
                          [
                            _vm.project.deliverables.length
                              ? _c("deliverable-by-status", {
                                  attrs: {
                                    project: _vm.project,
                                    rounded: false,
                                    deliverables: _vm.deliverables
                                  }
                                })
                              : _c("div", {
                                  staticClass: "fill-height fill-width",
                                  staticStyle: {
                                    border: "1px solid var(--v-gray_50-base)",
                                    "border-radius": "4px"
                                  }
                                })
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "font-16 font-gray_90 brand-medium pa-4"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "brand-bold font-gray_90",
                              staticStyle: {
                                "font-size": "48px",
                                "line-height": "20px"
                              }
                            },
                            [_vm._v(_vm._s(_vm.deliverablesDone))]
                          ),
                          _vm._v(
                            " of " +
                              _vm._s(_vm.deliverables.length) +
                              " tasks completed "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pa-4 mt-auto row-format align-center",
                          staticStyle: {
                            "background-color": "var(--v-gray_5-base)",
                            height: "44px",
                            "border-top": "1px solid var(--v-gray_30-base)"
                          }
                        },
                        [
                          _vm.nextDueDate
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row-format align-center fill-width flex-grow-1 pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("view-deliverables")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { size: "12", color: "gray_70" }
                                    },
                                    [_vm._v("$simpleDeliverable")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "font-gray_70 mr-2" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-gray_90 brand-medium"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.nextDueDate.deliverable.name
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" is due on "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-gray_90 brand-medium"
                                        },
                                        [_vm._v(_vm._s(_vm.nextDueDate.dueOn))]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "icon-button ml-auto" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "white", size: "16" }
                                        },
                                        [_vm._v("arrow_forward")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "row-format align-center fill-width flex-grow-1 pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("view-deliverables")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { size: "12", color: "gray_70" }
                                    },
                                    [_vm._v("$simpleDeliverable")]
                                  ),
                                  _c("div", { staticClass: "mr-2" }, [
                                    _vm._v("View tasks")
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "icon-button ml-auto" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "white", size: "16" }
                                        },
                                        [_vm._v("arrow_forward")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ]
                      )
                    ]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "column-format fill-width",
                    staticStyle: { gap: "12px" }
                  },
                  [
                    _vm.$store.getters.hasInvoiceAccess
                      ? _c(
                          "div",
                          { staticClass: "insight-box" },
                          [
                            _c("project-payments", {
                              attrs: { project: _vm.project },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("payment-clicked")
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "insight-box" },
                      [
                        _c("project-time-worked", {
                          attrs: { project: _vm.project },
                          on: {
                            click: function($event) {
                              return _vm.$emit("time-clicked")
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "pl-3 column-format" }, [
                _c("div", { staticClass: "font-18 text-left mb-2 mt-8" }, [
                  _vm._v("Description")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "pa-4",
                    staticStyle: {
                      "background-color": "var(--v-white-base)",
                      border: "1px solid var(--v-gray_30-base)",
                      "border-radius": "4px"
                    }
                  },
                  [
                    _c("editor", {
                      ref: "Description",
                      attrs: {
                        "api-key": _vm.$store.getters.getTinyMceKey,
                        "initial-value": _vm.project.description,
                        inline: true,
                        init: _vm.mceConfig,
                        spellcheck: true
                      },
                      on: {
                        focusout: function($event) {
                          return _vm.handleDescriptionInput()
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }