var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    [
      _c(
        "td",
        {
          class: "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : ""),
          staticStyle: { "max-width": "20px", padding: "0!important" }
        },
        [
          _c("div", { staticClass: "column-format align-center px-1" }, [
            _c("input", {
              attrs: { type: "checkbox" },
              on: {
                input: function($event) {
                  return _vm.$emit("toggle-checked", $event)
                }
              }
            })
          ])
        ]
      ),
      _c(
        "td",
        { class: "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : "") },
        [_c("div", { class: _vm.isSubTask ? "" : "pl-1" }, [_vm._v("Name")])]
      ),
      !_vm.singleProjectMode && _vm.isVisible("project")
        ? _c(
            "td",
            {
              class:
                "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : "")
            },
            [_vm._v("Project")]
          )
        : _vm._e(),
      _vm.isVisible("status")
        ? _c(
            "td",
            {
              class:
                "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : "")
            },
            [_vm._v("Status")]
          )
        : _vm._e(),
      _vm.isVisible("priority")
        ? _c(
            "td",
            {
              class:
                "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : "")
            },
            [_vm._v("Priority")]
          )
        : _vm._e(),
      _vm.isVisible("assignedTo")
        ? _c(
            "td",
            {
              class:
                "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : "")
            },
            [_vm._v("Assigned")]
          )
        : _vm._e(),
      _vm.isVisible("startDate")
        ? _c(
            "td",
            {
              class:
                "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : "")
            },
            [_vm._v("Start")]
          )
        : _vm._e(),
      _vm.isVisible("dueDate")
        ? _c(
            "td",
            {
              class:
                "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : "")
            },
            [_vm._v("Due")]
          )
        : _vm._e(),
      _vm._l(_vm.filteredCustomFields, function(field) {
        return _c(
          "td",
          {
            key: field.id,
            class: "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : "")
          },
          [_vm._v(" " + _vm._s(field.name) + " ")]
        )
      }),
      _vm.isVisible("rank")
        ? _c(
            "td",
            {
              class:
                "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : "")
            },
            [_vm._v("Rank")]
          )
        : _vm._e(),
      _vm.isVisible("comments")
        ? _c(
            "td",
            {
              class:
                "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : "")
            },
            [_vm._v("Comments")]
          )
        : _vm._e(),
      _vm.isVisible("toDos")
        ? _c(
            "td",
            {
              class:
                "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : "")
            },
            [_vm._v("To-dos")]
          )
        : _vm._e(),
      _c(
        "td",
        { class: "heading " + (_vm.$vuetify.theme.dark ? "heading-dark" : "") },
        [_vm._v(" ")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }