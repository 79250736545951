import { render, staticRenderFns } from "./Renderer.vue?vue&type=template&id=2b15de0a&scoped=true&"
import script from "./Renderer.vue?vue&type=script&lang=js&"
export * from "./Renderer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b15de0a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src991023174/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b15de0a')) {
      api.createRecord('2b15de0a', component.options)
    } else {
      api.reload('2b15de0a', component.options)
    }
    module.hot.accept("./Renderer.vue?vue&type=template&id=2b15de0a&scoped=true&", function () {
      api.rerender('2b15de0a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/discovery/formrenderer/Renderer.vue"
export default component.exports