var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pointer v-event-draggable row-format pb-2",
      staticStyle: {
        "align-items": "center",
        "justify-content": "space-between"
      },
      on: {
        mousedown: function($event) {
          $event.stopPropagation()
          return _vm.startDrag(_vm.event)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "row-format align-center gap-1",
          staticStyle: { width: "90%" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.editDeliverable(_vm.event)
            }
          }
        },
        [
          _vm.event.client
            ? _c("client-avatar", {
                attrs: {
                  client: _vm.event.client,
                  "disable-click": true,
                  small: true
                }
              })
            : _vm._e(),
          _vm.event.parentTaskId
            ? _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded",
                  attrs: { color: "gray_70", size: "16" }
                },
                [_vm._v("graph_2")]
              )
            : _vm._e(),
          _c("div", {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: { content: _vm.tippyLabel },
                expression: "{ content: tippyLabel }"
              }
            ],
            staticClass: "eventLabel no-select",
            staticStyle: { "font-size": "12px" },
            domProps: { innerHTML: _vm._s(_vm.event.name) }
          })
        ],
        1
      ),
      _c("div", {
        directives: [
          {
            name: "tippy",
            rawName: "v-tippy",
            value: {
              content: _vm.$store.getters.getDeliverableStatusById(
                _vm.event.statusId
              ).label
            },
            expression:
              "{ content: $store.getters.getDeliverableStatusById(event.statusId).label }"
          }
        ],
        staticClass: "coloredCircle",
        style:
          "--circle-color: " +
          _vm.$store.getters.getDeliverableStatusById(_vm.event.statusId)
            .hexColor
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }