import { render, staticRenderFns } from "./FileInput.vue?vue&type=template&id=61cd3602&scoped=true&"
import script from "./FileInput.vue?vue&type=script&lang=js&"
export * from "./FileInput.vue?vue&type=script&lang=js&"
import style0 from "./FileInput.vue?vue&type=style&index=0&id=61cd3602&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61cd3602",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VChip,VFileInput,VForm})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src991023174/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61cd3602')) {
      api.createRecord('61cd3602', component.options)
    } else {
      api.reload('61cd3602', component.options)
    }
    module.hot.accept("./FileInput.vue?vue&type=template&id=61cd3602&scoped=true&", function () {
      api.rerender('61cd3602', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/templates/invoices/schema/FileInput.vue"
export default component.exports