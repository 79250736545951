var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-format pointer py-2 font-14 deliverable",
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c("div", { staticClass: "deliverable-container row-format" }, [
        _c("div", {
          staticClass: "deliverable header",
          staticStyle: { width: "2px", height: "32px" }
        }),
        _c(
          "div",
          { staticClass: "row-format align-center distribute-items px-2" },
          [
            _c(
              "div",
              { staticClass: "deliverableName brand-medium" },
              [
                _vm._v(_vm._s(_vm.deliverable.name)),
                _vm.deliverable.isRecurring
                  ? _c(
                      "v-icon",
                      {
                        staticStyle: {
                          "padding-left": "4px",
                          "margin-bottom": "1px",
                          "margin-right": "4px"
                        },
                        attrs: { size: "14" }
                      },
                      [_vm._v("autorenew")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "timeline" }, [
              _vm._v(_vm._s(_vm.formattedTimeline(_vm.deliverable.dueDate)))
            ]),
            !_vm.isSubtask
              ? _c("div", { staticClass: "tasks" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$tc(
                        "templates.packages.task-plurals",
                        _vm.deliverable.subtasks.length
                      )
                    )
                  )
                ])
              : _vm._e()
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }