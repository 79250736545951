var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "column-format" },
      [
        _c("v-text-field", {
          staticClass: "standard-input",
          staticStyle: { "max-width": "200px" },
          attrs: {
            outlined: "",
            "hide-details": "",
            dense: "",
            placeholder: "Search..."
          },
          on: { input: _vm.updated },
          scopedSlots: _vm._u([
            {
              key: "prepend-inner",
              fn: function() {
                return [
                  _c(
                    "span",
                    { staticClass: "material-symbols-rounded font-gray_50" },
                    [_vm._v("search")]
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.textFilter,
            callback: function($$v) {
              _vm.textFilter = $$v
            },
            expression: "textFilter"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }