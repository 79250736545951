var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "nudge-top": 28,
            "min-width": "200",
            "max-width": "200",
            bottom: "",
            right: "",
            rounded: "",
            "offset-overflow": "",
            "offset-y": "",
            "close-on-content-click": false,
            "content-class": "filter-menu-class",
            transition: "slide-y-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      {
                        staticClass: "material-symbols-rounded",
                        attrs: { size: "18", color: "gray_70" }
                      },
                      on
                    ),
                    [_vm._v("settings")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _c("div", { staticClass: "column-format" }, [
            _c(
              "div",
              { staticClass: "filter-option-box" },
              [
                _c("div", { staticClass: "text-left brand-medium" }, [
                  _vm._v("Visible fields")
                ]),
                _vm._l(_vm.allFields, function(field) {
                  return _c("v-checkbox", {
                    key: field.value,
                    attrs: {
                      disabled: field.value === "name",
                      value: field.value,
                      label: field.label,
                      "hide-details": "",
                      dense: ""
                    },
                    on: { change: _vm.updated },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                })
              ],
              2
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }