var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "contact-card row-format pa-4",
      on: {
        click: function($event) {
          return _vm.$emit("edit")
        }
      }
    },
    [
      _c("div", { staticClass: "row-format" }, [
        _c(
          "div",
          { staticClass: "column-format text-left gap-2 flex-grow-1" },
          [
            _c("div", { staticClass: "column-format gap-1" }, [
              _vm.contact.firstName || _vm.contact.lastName
                ? _c("div", { staticClass: "font-16 brand-medium" }, [
                    _vm._v(
                      _vm._s(_vm.contact.firstName) +
                        " " +
                        _vm._s(_vm.contact.lastName)
                    )
                  ])
                : _vm._e(),
              _vm.contact.role && _vm.isFieldVisible("role")
                ? _c(
                    "div",
                    {
                      staticClass: "font-12",
                      staticStyle: { "word-wrap": "anywhere" }
                    },
                    [_vm._v(_vm._s(_vm.contact.role))]
                  )
                : _vm._e(),
              _vm.contact.email && _vm.isFieldVisible("email")
                ? _c(
                    "div",
                    {
                      staticClass: "font-12",
                      staticStyle: { "word-wrap": "anywhere" }
                    },
                    [_vm._v(_vm._s(_vm.contact.email))]
                  )
                : _vm._e(),
              _vm.contact.phone && _vm.isFieldVisible("phone")
                ? _c(
                    "div",
                    {
                      staticClass: "font-12",
                      staticStyle: { "word-wrap": "anywhere" }
                    },
                    [_vm._v(_vm._s(_vm.contact.phone))]
                  )
                : _vm._e(),
              _vm.filteredCustomFields.length
                ? _c(
                    "div",
                    { staticClass: "font-12 column-format gap-1" },
                    [
                      _vm._l(_vm.contact.customValues, function(value) {
                        return [
                          _vm.isFieldVisible("Custom." + value.mappingKey)
                            ? _c("div", { key: value.mappingKey }, [
                                _vm._v(
                                  _vm._s(value.fieldName) +
                                    ": " +
                                    _vm._s(value.value)
                                )
                              ])
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                : _vm._e()
            ]),
            _vm.contact.client && _vm.isFieldVisible("client.name")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "font-12 brand-medium font-primary row-format align-center gap-1",
                    on: { click: _vm.routeToClient }
                  },
                  [
                    _c("client-avatar", {
                      attrs: {
                        client: _vm.contact.client,
                        "x-small": true,
                        "disable-click": false
                      }
                    }),
                    _c("div", [_vm._v(_vm._s(_vm.contact.client.name))])
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ]),
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            left: "",
            rounded: "",
            "nudge-bottom": "24",
            "close-on-content-click": true,
            "close-on-click": true
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(scope) {
                return [
                  _c(
                    "div",
                    { staticClass: "ml-auto" },
                    [
                      _c(
                        "v-icon",
                        _vm._g({ attrs: { size: "20" } }, scope.on),
                        [
                          _vm._v(
                            _vm._s(scope.value ? "$arrowUp" : "$moreHorizontal")
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c("div", { staticClass: "more-menu" }, [
            _c(
              "div",
              {
                staticClass: "more-menu-item",
                on: {
                  click: function($event) {
                    return _vm.$emit("edit")
                  }
                }
              },
              [_vm._v("Edit")]
            ),
            _vm.contact.email
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("send-email")
                      }
                    }
                  },
                  [_vm._v("Send Email")]
                )
              : _vm._e(),
            _vm.contact.phone
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("send-text")
                      }
                    }
                  },
                  [_vm._v("Send Text")]
                )
              : _vm._e(),
            _vm.contact.phone
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("make-call")
                      }
                    }
                  },
                  [_vm._v("Make Call")]
                )
              : _vm._e(),
            _vm.contact.email
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("book-meeting")
                      }
                    }
                  },
                  [_vm._v("Book meeting")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "more-menu-item",
                on: {
                  click: function($event) {
                    return _vm.$emit("delete")
                  }
                }
              },
              [_vm._v("Delete")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }