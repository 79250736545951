var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showClientLogos && _vm.logo
        ? _c(
            "v-sheet",
            {
              directives: [{ name: "tippy", rawName: "v-tippy" }],
              class:
                "avatar " + _vm.float + (_vm.disableClick ? "" : " pointer"),
              attrs: {
                content: _vm.client.name,
                height: _vm.height,
                width: _vm.width,
                color: "transparent"
              },
              on: {
                click: function($event) {
                  return _vm.routeToClient($event)
                }
              }
            },
            [
              _c("img", {
                staticStyle: { "border-radius": "4px" },
                attrs: { src: _vm.logo, width: _vm.width }
              })
            ]
          )
        : _c(
            "v-sheet",
            {
              directives: [{ name: "tippy", rawName: "v-tippy" }],
              class:
                "avatar " + _vm.float + (_vm.disableClick ? "" : " pointer"),
              attrs: {
                content: _vm.client.name,
                height: _vm.height,
                width: _vm.width,
                color: _vm.activeColor
              },
              on: {
                click: function($event) {
                  return _vm.routeToClient($event)
                }
              }
            },
            [
              _c("div", { class: _vm.fontClass }, [
                _vm._v(_vm._s(_vm.initials))
              ]),
              _vm.arrow === "down"
                ? _c(
                    "v-icon",
                    { attrs: { size: "16", color: _vm.arrowColor } },
                    [_vm._v("$triangleDown")]
                  )
                : _vm._e(),
              _vm.arrow === "up"
                ? _c(
                    "v-icon",
                    { attrs: { size: "16", color: _vm.arrowColor } },
                    [_vm._v("$triangleUp")]
                  )
                : _vm._e()
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }