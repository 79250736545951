var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customFields
    ? _c("custom-field-table", {
        ref: "Table",
        attrs: { "max-per-card": _vm.maxPerCard },
        on: {
          input: function($event) {
            return _vm.saveFields()
          }
        },
        model: {
          value: _vm.customFields.fields,
          callback: function($$v) {
            _vm.$set(_vm.customFields, "fields", $$v)
          },
          expression: "customFields.fields"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }