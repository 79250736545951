var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "panel-modal", staticStyle: { width: "600px" } },
        [
          _c("div", { staticClass: "panel-modal-header" }, [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2 pointer",
                    attrs: { small: "", color: "gray_90" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("result")
                      }
                    }
                  },
                  [_vm._v("$chevronRight")]
                ),
                _c("div", { staticClass: "brand-medium font-18" }, [
                  _vm._v("Edit project settings")
                ]),
                _c(
                  "div",
                  { staticClass: "ml-auto row-format align-center gap-2" },
                  [
                    _c("pipeline-link-widget", {
                      attrs: {
                        "item-id": _vm.project.id,
                        "item-type": "Project",
                        "client-id": _vm.project.clientId
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "super-action",
                        on: { click: _vm.updateProject }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer material-symbols-rounded",
                        attrs: { size: "20", color: "gray_90" },
                        on: { click: _vm.confirmDeleteProject }
                      },
                      [_vm._v("delete")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "panel-modal-body show-scrollbar" },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "column-format gap-3 mt-2" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          "persistent-placeholder": "",
                          label: _vm.$t("projects.project-name"),
                          rules: [
                            function(v) {
                              return (v && v.trim().length > 0) || "Required."
                            }
                          ]
                        },
                        model: {
                          value: _vm.project.name,
                          callback: function($$v) {
                            _vm.$set(_vm.project, "name", $$v)
                          },
                          expression: "project.name"
                        }
                      }),
                      _c("date-selector", {
                        attrs: {
                          standard: true,
                          date: _vm.project.startDate,
                          label: "Start date"
                        },
                        on: {
                          change: function($event) {
                            _vm.project.startDate = $event
                          }
                        }
                      }),
                      _c("date-selector", {
                        attrs: {
                          standard: true,
                          date: _vm.project.dueDate,
                          label: "Due date"
                        },
                        on: {
                          change: function($event) {
                            _vm.project.dueDate = $event
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "brand-border row-format align-center font-14 pl-3 pointer",
                          staticStyle: {
                            "min-height": "40px",
                            "max-height": "40px",
                            position: "relative"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.projectOwnersMenu = true
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "font-12 px-1",
                              staticStyle: {
                                position: "absolute",
                                "background-color": "var(--v-white-base)",
                                color: "var(--v-gray_60-base)",
                                top: "-10px",
                                left: "8px"
                              }
                            },
                            [_vm._v("Project owners")]
                          ),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-click": true,
                                "close-on-content-click": false,
                                "offset-y": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            { staticClass: "pointer" },
                                            on
                                          ),
                                          [
                                            _vm.project.projectOwners.length
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row-format align-center"
                                                  },
                                                  _vm._l(
                                                    _vm.project.projectOwners,
                                                    function(
                                                      assignedTo,
                                                      index
                                                    ) {
                                                      return _c(
                                                        "assigned-user",
                                                        _vm._g(
                                                          {
                                                            key: assignedTo,
                                                            style:
                                                              (index > 0
                                                                ? "margin-left: -6px"
                                                                : "") +
                                                              "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                                                              index,
                                                            attrs: {
                                                              "assigned-to": assignedTo,
                                                              "show-name": false,
                                                              "empty-label":
                                                                "--"
                                                            }
                                                          },
                                                          on
                                                        )
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row-format align-center text-left"
                                                  },
                                                  [_vm._v("--")]
                                                )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                469816612
                              ),
                              model: {
                                value: _vm.projectOwnersMenu,
                                callback: function($$v) {
                                  _vm.projectOwnersMenu = $$v
                                },
                                expression: "projectOwnersMenu"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-left pa-3 font-14",
                                  staticStyle: {
                                    "background-color": "var(--v-white-base)"
                                  }
                                },
                                _vm._l(_vm.fullUserList, function(user) {
                                  return _c(
                                    "div",
                                    {
                                      key: user.userId,
                                      staticClass:
                                        "row-format align-center text-left py-1 pointer"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.project.projectOwners,
                                            expression: "project.projectOwners"
                                          }
                                        ],
                                        staticStyle: {
                                          width: "20px",
                                          height: "20px",
                                          "border-radius": "4px"
                                        },
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          value: user.userId,
                                          checked: Array.isArray(
                                            _vm.project.projectOwners
                                          )
                                            ? _vm._i(
                                                _vm.project.projectOwners,
                                                user.userId
                                              ) > -1
                                            : _vm.project.projectOwners
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.project.projectOwners,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = user.userId,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.project,
                                                    "projectOwners",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.project,
                                                    "projectOwners",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.project,
                                                "projectOwners",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      user.userId
                                        ? _c("assigned-user", {
                                            staticClass: "mx-2",
                                            attrs: {
                                              "assigned-to": user.userId,
                                              "show-name": true
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.isPaidAccount
                        ? _c("v-select", {
                            attrs: {
                              items: _vm.portalAccessTypes,
                              "persistent-placeholder": "",
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "item-value": "value",
                              "item-text": "label",
                              label: "Client portal access"
                            },
                            model: {
                              value: _vm.project.portalAccess,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "portalAccess", $$v)
                              },
                              expression: "project.portalAccess"
                            }
                          })
                        : _vm._e(),
                      _vm.isPaidAccount &&
                      (_vm.project.portalAccess === "Full access" ||
                        _vm.project.portalAccess === "Read only")
                        ? _c("v-select", {
                            attrs: {
                              items: _vm.portalAccessAssignedOnly,
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              "item-value": "value",
                              "item-text": "label",
                              label: "Client task visibility"
                            },
                            model: {
                              value: _vm.project.portalAccessAssignedOnly,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.project,
                                  "portalAccessAssignedOnly",
                                  $$v
                                )
                              },
                              expression: "project.portalAccessAssignedOnly"
                            }
                          })
                        : _vm._e(),
                      _vm.isPaidAccount
                        ? _c("v-select", {
                            attrs: {
                              items: _vm.showTimeInPortal,
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              "item-value": "value",
                              "item-text": "label",
                              label: "Show time worked in portal"
                            },
                            model: {
                              value: _vm.project.showTimeWorkedInPortal,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.project,
                                  "showTimeWorkedInPortal",
                                  $$v
                                )
                              },
                              expression: "project.showTimeWorkedInPortal"
                            }
                          })
                        : _vm._e(),
                      _c("v-select", {
                        attrs: {
                          items: _vm.feeTypes,
                          "hide-details": "",
                          "persistent-placeholder": "",
                          "item-text": "label",
                          "item-value": "value",
                          dense: "",
                          outlined: "",
                          label: "Project fee type"
                        },
                        model: {
                          value: _vm.project.feeSchedule.feeType,
                          callback: function($$v) {
                            _vm.$set(_vm.project.feeSchedule, "feeType", $$v)
                          },
                          expression: "project.feeSchedule.feeType"
                        }
                      }),
                      _vm.project.feeSchedule.feeType !== "Per Item"
                        ? _c(
                            "div",
                            { staticClass: "row-format" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  prefix: _vm.currencySymbol,
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  dense: "",
                                  outlined: "",
                                  oninput:
                                    "if(this.value < 0) this.value = this.value*-1;",
                                  type: "number",
                                  label: "Amount"
                                },
                                model: {
                                  value: _vm.project.feeSchedule.amount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.project.feeSchedule,
                                      "amount",
                                      $$v
                                    )
                                  },
                                  expression: "project.feeSchedule.amount"
                                }
                              }),
                              _vm.taxEnabled
                                ? _c("v-checkbox", {
                                    staticClass: "ml-2",
                                    attrs: { dense: "", "hide-details": "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "font-14 ml-n2"
                                                },
                                                [_vm._v("Taxable")]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1646965418
                                    ),
                                    model: {
                                      value: _vm.project.feeSchedule.taxable,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.project.feeSchedule,
                                          "taxable",
                                          $$v
                                        )
                                      },
                                      expression: "project.feeSchedule.taxable"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.project.feeSchedule.feeType === "Retainer"
                        ? _c(
                            "div",
                            { staticClass: "column-format gap-3" },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.schedule,
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  dense: "",
                                  outlined: "",
                                  label: "Recurring period"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("span", [_vm._v(_vm._s(item))])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2957485812
                                ),
                                model: {
                                  value:
                                    _vm.project.feeSchedule.retainerSchedule,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.project.feeSchedule,
                                      "retainerSchedule",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "project.feeSchedule.retainerSchedule"
                                }
                              }),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.retainerTiming,
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  dense: "",
                                  outlined: "",
                                  label: "Recurring timing"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("span", [_vm._v(_vm._s(item))])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2957485812
                                ),
                                model: {
                                  value: _vm.project.feeSchedule.retainerTiming,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.project.feeSchedule,
                                      "retainerTiming",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "project.feeSchedule.retainerTiming"
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "row-format gap-2" },
                                [
                                  _c("date-selector", {
                                    staticStyle: { flex: "1 1 0" },
                                    attrs: {
                                      standard: true,
                                      date:
                                        _vm.project.feeSchedule.retainerStart,
                                      label: "Recurring start date"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.project.feeSchedule.retainerStart = $event
                                      }
                                    }
                                  }),
                                  _c("v-select", {
                                    staticStyle: { flex: "1 1 0" },
                                    attrs: {
                                      items: _vm.periods,
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      dense: "",
                                      outlined: "",
                                      "item-text": "label",
                                      "item-value": "value",
                                      label: "Billable periods"
                                    },
                                    model: {
                                      value:
                                        _vm.project.feeSchedule.retainerPeriods,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.project.feeSchedule,
                                          "retainerPeriods",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "project.feeSchedule.retainerPeriods"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "row-format gap-2" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Hour estimate (min)",
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              type: "number",
                              min: "0",
                              max: "10000"
                            },
                            on: {
                              change: function($event) {
                                return _vm.validateMinMax("min")
                              }
                            },
                            model: {
                              value: _vm.project.feeSchedule.estimateMin,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.project.feeSchedule,
                                  "estimateMin",
                                  $$v
                                )
                              },
                              expression: "project.feeSchedule.estimateMin"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Hour estimate (max)",
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              type: "number",
                              min: "0",
                              max: "10000"
                            },
                            on: {
                              change: function($event) {
                                return _vm.validateMinMax("max")
                              }
                            },
                            model: {
                              value: _vm.project.feeSchedule.estimateMax,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.project.feeSchedule,
                                  "estimateMax",
                                  $$v
                                )
                              },
                              expression: "project.feeSchedule.estimateMax"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.project.feeSchedule.feeType === "Retainer"
                        ? _c("v-text-field", {
                            attrs: {
                              prefix: _vm.currencySymbol,
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              oninput:
                                "if(this.value < 0) this.value = this.value*-1;",
                              type: "number",
                              label: "Recurring overage hourly amount"
                            },
                            model: {
                              value:
                                _vm.project.feeSchedule.retainerOverageRate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.project.feeSchedule,
                                  "retainerOverageRate",
                                  $$v
                                )
                              },
                              expression:
                                "project.feeSchedule.retainerOverageRate"
                            }
                          })
                        : _vm._e(),
                      _vm._l(_vm.fields, function(field) {
                        return _c("custom-field", {
                          key: field.id,
                          attrs: {
                            mode: "dense",
                            field: field,
                            value: _vm.getCustomValue(field.id)
                          },
                          on: {
                            change: function($event) {
                              return _vm.handleCustomFieldChange($event)
                            }
                          }
                        })
                      })
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4 mb-1" },
                    [
                      _c("color-selector", {
                        attrs: {
                          value: _vm.project.hexColor,
                          label: "Calendar highlight"
                        },
                        on: {
                          input: function($event) {
                            _vm.project.hexColor = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "column-format ml-1" },
                    [
                      _c("v-switch", {
                        attrs: {
                          inset: "",
                          flat: "",
                          dense: "",
                          "hide-details": "",
                          label: "Set complete"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c("span", { staticClass: "font-gray_80" }, [
                                    _vm._v("Set complete")
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3954510761
                        ),
                        model: {
                          value: _vm.complete,
                          callback: function($$v) {
                            _vm.complete = $$v
                          },
                          expression: "complete"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "font-12 font-gray_80 mt-1 text-left" },
                        [
                          _vm._v(
                            " Completed projects are hidden from project management but will remain accessible from within the client details. "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.createPackageAllowed
                    ? _c(
                        "div",
                        { staticClass: "column-format mt-8" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { elevation: "0" },
                              on: { click: _vm.createDeliverablePackage }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("content_copy")
                              ]),
                              _c("div", [_vm._v("Save project as template")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }