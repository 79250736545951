var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.leftNavExpanded &&
    _vm.currentNavRoute &&
    _vm.$store.state.navReady
    ? _c(
        "div",
        {
          staticClass: "text-left pl-2 pr-2",
          staticStyle: { "margin-top": "2px" },
          attrs: { id: "sub-nav" }
        },
        [
          _c(
            "div",
            {
              staticClass: "row-format align-center",
              staticStyle: {
                width: "100%",
                height: "56px",
                "max-height": "56px",
                "min-height": "56px"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "font-20 brand-medium pl-1",
                  staticStyle: { "line-height": "1.1" }
                },
                [_vm._v(_vm._s(_vm.currentNavRoute.title.label))]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "column-format gap-2 font-14",
              staticStyle: {
                width: "100%",
                "max-width": "100%",
                "margin-top": "20px"
              }
            },
            [
              _c(
                "draggable",
                {
                  staticClass: "column-format gap-2",
                  attrs: { disabled: !_vm.isQuickLink },
                  on: { end: _vm.endDragHandler },
                  model: {
                    value: _vm.children,
                    callback: function($$v) {
                      _vm.children = $$v
                    },
                    expression: "children"
                  }
                },
                _vm._l(_vm.children, function(tab) {
                  return _c(
                    "div",
                    {
                      key: tab.uri,
                      staticClass:
                        "row-format align-center gap-1 sub-item pointer",
                      class: _vm.$route.path.startsWith(tab.uri)
                        ? "sub-item-active"
                        : "",
                      on: {
                        click: function($event) {
                          return _vm.route(tab, $event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "material-symbols-rounded",
                          staticStyle: { "font-size": "16px" }
                        },
                        [_vm._v(_vm._s(tab.icon))]
                      ),
                      _c("div", { staticClass: "label" }, [
                        _vm._v(_vm._s(tab.label))
                      ])
                    ]
                  )
                }),
                0
              ),
              _vm.favorites.length
                ? _c(
                    "div",
                    {
                      staticClass: "row-format align-center gap-1 sub-item",
                      staticStyle: { color: "var(--v-black-base)!important" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "material-symbols-rounded",
                          staticStyle: { "font-size": "16px" }
                        },
                        [_vm._v("star")]
                      ),
                      _c("div", { staticClass: "label" }, [_vm._v("Favorites")])
                    ]
                  )
                : _vm._e(),
              _vm._l(_vm.favorites, function(favorite) {
                return _c(
                  "div",
                  {
                    key: favorite.route,
                    staticClass:
                      "row-format align-center gap-1 sub-item pointer",
                    class: _vm.$route.path.startsWith(favorite.uri)
                      ? "sub-item-active"
                      : "",
                    on: {
                      click: function($event) {
                        return _vm.route(favorite, $event)
                      }
                    }
                  },
                  [
                    _c("client-avatar", {
                      staticStyle: { "margin-left": "-2px" },
                      attrs: {
                        "x-small": "",
                        client: favorite.client,
                        "disable-click": true
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "label truncate",
                        staticStyle: { "max-width": "140px" }
                      },
                      [_vm._v(_vm._s(favorite.label))]
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }