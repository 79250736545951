import { render, staticRenderFns } from "./VendorDetail.vue?vue&type=template&id=3277bca6&"
import script from "./VendorDetail.vue?vue&type=script&lang=js&"
export * from "./VendorDetail.vue?vue&type=script&lang=js&"
import style0 from "./VendorDetail.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VBtn,VCheckbox,VCol,VContainer,VIcon,VRow,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src991023174/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3277bca6')) {
      api.createRecord('3277bca6', component.options)
    } else {
      api.reload('3277bca6', component.options)
    }
    module.hot.accept("./VendorDetail.vue?vue&type=template&id=3277bca6&", function () {
      api.rerender('3277bca6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/accounting/vendors/VendorDetail.vue"
export default component.exports