var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-left column-format font-14",
      staticStyle: { flex: "1 1 0", width: "100%" }
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "column-format gap-2 mt-2" },
        [
          _c(
            "div",
            { staticClass: "row-format gap-2" },
            [
              _c("v-select", {
                attrs: {
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: "Page size",
                  "persistent-placeholder": "",
                  items: _vm.pageSizes,
                  "item-text": "label",
                  "item-value": "value"
                },
                model: {
                  value: _vm.agreement.styles.pageSize,
                  callback: function($$v) {
                    _vm.$set(_vm.agreement.styles, "pageSize", $$v)
                  },
                  expression: "agreement.styles.pageSize"
                }
              }),
              _vm.agreement.styles.pageSize !== "RESPONSIVE"
                ? _c("v-select", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      label: "Orientation",
                      "persistent-placeholder": "",
                      items: _vm.orientation,
                      "item-text": "label",
                      "item-value": "value"
                    },
                    model: {
                      value: _vm.agreement.styles.landscape,
                      callback: function($$v) {
                        _vm.$set(_vm.agreement.styles, "landscape", $$v)
                      },
                      expression: "agreement.styles.landscape"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.agreement.styles.pageSize === "RESPONSIVE"
            ? _c("v-slider", {
                staticClass: "pr-3",
                attrs: {
                  "hide-details": "",
                  dense: "",
                  label: "Max page width",
                  step: 10,
                  min: 200,
                  max: 2048,
                  "thumb-label": "",
                  "persistent-hint": ""
                },
                model: {
                  value: _vm.agreement.styles.maxWidth,
                  callback: function($$v) {
                    _vm.$set(_vm.agreement.styles, "maxWidth", $$v)
                  },
                  expression: "agreement.styles.maxWidth"
                }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "row-format gap-3" },
            [
              _c("color-selector", {
                attrs: { label: "Canvas color" },
                model: {
                  value: _vm.agreement.styles.canvasColor,
                  callback: function($$v) {
                    _vm.$set(_vm.agreement.styles, "canvasColor", $$v)
                  },
                  expression: "agreement.styles.canvasColor"
                }
              }),
              _c("color-selector", {
                attrs: { label: "Page color" },
                model: {
                  value: _vm.agreement.styles.pageColor,
                  callback: function($$v) {
                    _vm.$set(_vm.agreement.styles, "pageColor", $$v)
                  },
                  expression: "agreement.styles.pageColor"
                }
              })
            ],
            1
          ),
          _c("v-switch", {
            staticClass: "mt-0",
            attrs: { "hide-details": "", dense: "", label: "Page shadow" },
            model: {
              value: _vm.agreement.styles.pageShadow,
              callback: function($$v) {
                _vm.$set(_vm.agreement.styles, "pageShadow", $$v)
              },
              expression: "agreement.styles.pageShadow"
            }
          })
        ],
        1
      ),
      _c("hr", {
        staticClass: "my-2",
        staticStyle: {
          "background-color": "var(--v-gray_50-base)",
          height: "1px",
          border: "0"
        }
      }),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "brand-medium row-format pointer",
              on: {
                click: function($event) {
                  _vm.titleExpanded = !_vm.titleExpanded
                }
              }
            },
            [
              _vm._v(" Title Style "),
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded ml-auto",
                  attrs: { color: "black" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.titleExpanded
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"
                    )
                  )
                ]
              )
            ],
            1
          ),
          _vm.titleExpanded
            ? _c("font-style", {
                staticClass: "py-2",
                on: { "font-family-changed": _vm.reloadFonts },
                model: {
                  value: _vm.agreement.styles.h1,
                  callback: function($$v) {
                    _vm.$set(_vm.agreement.styles, "h1", $$v)
                  },
                  expression: "agreement.styles.h1"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("hr", {
        staticClass: "my-2",
        staticStyle: {
          "background-color": "var(--v-gray_50-base)",
          height: "1px",
          border: "0"
        }
      }),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "brand-medium row-format pointer",
              on: {
                click: function($event) {
                  _vm.headingExpanded = !_vm.headingExpanded
                }
              }
            },
            [
              _vm._v(" Heading Style "),
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded ml-auto",
                  attrs: { color: "black" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.headingExpanded
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"
                    )
                  )
                ]
              )
            ],
            1
          ),
          _vm.headingExpanded
            ? _c("font-style", {
                staticClass: "py-2",
                on: { "font-family-changed": _vm.reloadFonts },
                model: {
                  value: _vm.agreement.styles.h2,
                  callback: function($$v) {
                    _vm.$set(_vm.agreement.styles, "h2", $$v)
                  },
                  expression: "agreement.styles.h2"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("hr", {
        staticClass: "my-2",
        staticStyle: {
          "background-color": "var(--v-gray_50-base)",
          height: "1px",
          border: "0"
        }
      }),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "brand-medium row-format pointer",
              on: {
                click: function($event) {
                  _vm.subHeadingExpanded = !_vm.subHeadingExpanded
                }
              }
            },
            [
              _vm._v(" Sub-heading Style "),
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded ml-auto",
                  attrs: { color: "black" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.subHeadingExpanded
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"
                    )
                  )
                ]
              )
            ],
            1
          ),
          _vm.subHeadingExpanded
            ? _c("font-style", {
                staticClass: "py-2",
                on: { "font-family-changed": _vm.reloadFonts },
                model: {
                  value: _vm.agreement.styles.h3,
                  callback: function($$v) {
                    _vm.$set(_vm.agreement.styles, "h3", $$v)
                  },
                  expression: "agreement.styles.h3"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("hr", {
        staticClass: "my-2",
        staticStyle: {
          "background-color": "var(--v-gray_50-base)",
          height: "1px",
          border: "0"
        }
      }),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "brand-medium row-format pointer",
              on: {
                click: function($event) {
                  _vm.paragraphExpanded = !_vm.paragraphExpanded
                }
              }
            },
            [
              _vm._v(" Paragraph Style "),
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded ml-auto",
                  attrs: { color: "black" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.paragraphExpanded
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"
                    )
                  )
                ]
              )
            ],
            1
          ),
          _vm.paragraphExpanded
            ? _c("font-style", {
                staticClass: "py-2",
                on: { "font-family-changed": _vm.reloadFonts },
                model: {
                  value: _vm.agreement.styles.body,
                  callback: function($$v) {
                    _vm.$set(_vm.agreement.styles, "body", $$v)
                  },
                  expression: "agreement.styles.body"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("hr", {
        staticClass: "my-2",
        staticStyle: {
          "background-color": "var(--v-gray_50-base)",
          height: "1px",
          border: "0"
        }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-format pt-1 pb-4 text-left" }, [
      _c("div", { staticClass: "brand-bold font-18" }, [_vm._v("Styles")]),
      _c("div", { staticClass: "font-14 font-gray_80" }, [
        _vm._v("Page settings, fonts, and colors")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }