var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Meetings" }
            ]
          }
        },
        [_vm._v(" Booked meetings ")]
      ),
      _c(
        "div",
        { staticClass: "row-format mb-4 gap-3" },
        [
          _c("v-text-field", {
            staticClass: "standard-input",
            staticStyle: { "max-width": "200px" },
            attrs: {
              outlined: "",
              "hide-details": "",
              dense: "",
              placeholder: "Search..."
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-inner",
                fn: function() {
                  return [
                    _c(
                      "span",
                      { staticClass: "material-symbols-rounded font-gray_50" },
                      [_vm._v("search")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.filter.search,
              callback: function($$v) {
                _vm.$set(_vm.filter, "search", $$v)
              },
              expression: "filter.search"
            }
          }),
          _c("meeting-list-filter", {
            attrs: { slot: "filter" },
            on: {
              "clear-filter": function($event) {
                return _vm.clearFilter()
              }
            },
            slot: "filter",
            model: {
              value: _vm.filter,
              callback: function($$v) {
                _vm.filter = $$v
              },
              expression: "filter"
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        key: _vm.tableKey,
        staticStyle: { cursor: "pointer" },
        attrs: {
          headers: _vm.headers,
          items: _vm.filteredMeetingList,
          "sort-by": "confirmedTime.start",
          "sort-desc": "",
          "disable-pagination": true,
          "hide-default-footer": true
        },
        on: {
          "click:row": function($event) {
            return _vm.openScheduledMeeting($event.id)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.confirmedTime.start",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "span",
                  {
                    class:
                      "" +
                      (item.meetingStatus === "Cancelled" ? "cancelled" : "")
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.DateTime.fromISO(item.confirmedTime.start).toFormat(
                          "ccc"
                        ) +
                          " " +
                          _vm.DateTime.fromISO(
                            item.confirmedTime.start
                          ).toLocaleString(_vm.DateTime.DATETIME_MED)
                      )
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "item.meetingName",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "span",
                  {
                    class:
                      "" +
                      (item.meetingStatus === "Cancelled" ? "cancelled" : "")
                  },
                  [_vm._v(_vm._s(item.meetingName))]
                )
              ]
            }
          },
          {
            key: "item.contact",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "span",
                  {
                    class:
                      "" +
                      (item.meetingStatus === "Cancelled" ? "cancelled" : "")
                  },
                  [
                    _vm._v(
                      _vm._s(item.formData.firstName) +
                        " " +
                        _vm._s(item.formData.lastName)
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "item.client",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm.getClient(item.clientId)
                  ? _c(
                      "div",
                      { staticClass: "row-format align-center" },
                      [
                        _c("client-avatar", {
                          attrs: {
                            small: "",
                            client: _vm.getClient(item.clientId)
                          }
                        }),
                        _c("div", [
                          _vm._v(_vm._s(_vm.getClient(item.clientId).name))
                        ])
                      ],
                      1
                    )
                  : _c("span", [_vm._v(" -- ")])
              ]
            }
          },
          {
            key: "item.action",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("meeting-action-menu", {
                  attrs: { "hide-duplicate": true },
                  on: {
                    view: function($event) {
                      return _vm.openScheduledMeeting(item.id)
                    },
                    delete: function($event) {
                      return _vm.confirmDelete(item)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "no-data",
            fn: function() {
              return [
                !_vm.scheduledMeetings.length
                  ? _c("div", [
                      _vm._v(" There aren't any meetings scheduled yet. ")
                    ])
                  : _c("div", [
                      _vm._v(
                        " There are no meetings that match the filter criteria. "
                      )
                    ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }