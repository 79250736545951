import { render, staticRenderFns } from "./AcceptButtonConfig.vue?vue&type=template&id=62485bc4&scoped=true&"
import script from "./AcceptButtonConfig.vue?vue&type=script&lang=js&"
export * from "./AcceptButtonConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62485bc4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src991023174/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62485bc4')) {
      api.createRecord('62485bc4', component.options)
    } else {
      api.reload('62485bc4', component.options)
    }
    module.hot.accept("./AcceptButtonConfig.vue?vue&type=template&id=62485bc4&scoped=true&", function () {
      api.rerender('62485bc4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/agreements/config/AcceptButtonConfig.vue"
export default component.exports