var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "fill-width", attrs: { id: "project-management" } },
        [
          _vm.isBrandNew
            ? _c(
                "div",
                {
                  staticClass: "row-format centered fill-height",
                  staticStyle: { height: "calc(var(--vh) - 400px)" }
                },
                [
                  _c("empty-view", {
                    attrs: {
                      header: "You’re in control",
                      body:
                        "See the big picture of your projects and deadlines, or get into the details of your tasks. ",
                      cta: "Create your first project",
                      "video-header": "See how it works",
                      "video-body":
                        "See how to create projects and tasks, and then update them as your work progresses.",
                      "video-cta": "Watch the tutorial",
                      "video-id": "S9WWzr80uDY"
                    },
                    on: {
                      "cta-clicked": function($event) {
                        return _vm.addDeliverable(_vm.statusList[0])
                      }
                    }
                  })
                ],
                1
              )
            : _c("div", { staticClass: "fill-width" }, [
                _c(
                  "div",
                  {
                    staticClass: "align-center",
                    attrs: { id: "detail-wrapper" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "row-format align-center flex-wrap gap-3"
                      },
                      [
                        _vm.currentView !== "RECURRING"
                          ? _c(
                              "div",
                              { staticClass: "row-format align-center gap-2" },
                              [
                                _c("v-text-field", {
                                  staticClass: "standard-input",
                                  staticStyle: {
                                    "max-width": "200px",
                                    "background-color": "var(--v-white-base)"
                                  },
                                  attrs: {
                                    outlined: "",
                                    "hide-details": "",
                                    dense: "",
                                    placeholder: "Search...",
                                    color: "gray_30"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prepend-inner",
                                        fn: function() {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "material-symbols-rounded font-gray_50"
                                              },
                                              [_vm._v("search")]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    95475665
                                  ),
                                  model: {
                                    value: _vm.filter.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filter, "name", $$v)
                                    },
                                    expression: "filter.name"
                                  }
                                }),
                                _c("project-filter", {
                                  attrs: {
                                    projects: _vm.projectList,
                                    filter: _vm.filter,
                                    "status-list": _vm.statusList,
                                    "single-project-mode":
                                      _vm.isSingleProjectMode
                                  }
                                }),
                                _c("project-sort", {
                                  attrs: {
                                    filter: _vm.filter,
                                    "single-project-mode":
                                      _vm.isSingleProjectMode
                                  }
                                }),
                                _vm.currentView === "TABLE"
                                  ? _c("project-group", {
                                      attrs: {
                                        filter: _vm.filter,
                                        "custom-fields": _vm.customFields,
                                        "status-list": _vm.statusList,
                                        "project-type-id": _vm.projectTypeId,
                                        "single-project-mode":
                                          _vm.isSingleProjectMode
                                      }
                                    })
                                  : _vm._e(),
                                _c("custom-field-filter", {
                                  attrs: {
                                    fields: _vm.customFields,
                                    filter: _vm.customFieldFilter
                                  },
                                  on: {
                                    changed: function($event) {
                                      return _vm.saveCustomFieldFilters($event)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "font-14 font-gray_70 brand-medium"
                              },
                              [_vm._v("Recurring tasks")]
                            ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-auto row-format align-center gap-3"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row-format align-center gap-2 pr-3",
                                staticStyle: {
                                  "border-right":
                                    "1px solid var(--v-gray_30-base)"
                                }
                              },
                              [
                                _vm.currentView === "TABLE" ||
                                _vm.currentView === "CARD"
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "tippy",
                                            rawName: "v-tippy",
                                            value: {
                                              content: "Change view settings"
                                            },
                                            expression:
                                              "{ content: 'Change view settings' }"
                                          }
                                        ],
                                        staticClass:
                                          "pointer row-format align-center"
                                      },
                                      [
                                        _c("visible-fields", {
                                          attrs: {
                                            "visible-fields":
                                              _vm.visibleTaskFields,
                                            "all-fields": _vm.allTaskFields
                                          },
                                          on: {
                                            updated: function($event) {
                                              return _vm.setVisibleFields(
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: {
                                          content: "Import from template"
                                        },
                                        expression:
                                          "{ content: 'Import from template' }"
                                      }
                                    ],
                                    staticClass:
                                      "pointer row-format align-center",
                                    on: {
                                      click: function($event) {
                                        return _vm.importIntoProject()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "material-symbols-rounded",
                                        attrs: { size: "20", color: "gray_70" }
                                      },
                                      [_vm._v("cloud_download")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "row-format align-center gap-2" },
                              [
                                _vm.project
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "tippy",
                                            rawName: "v-tippy",
                                            value: {
                                              content: "Recurring tasks"
                                            },
                                            expression:
                                              "{ content: 'Recurring tasks' }"
                                          }
                                        ],
                                        staticClass:
                                          "pointer row-format align-center",
                                        on: {
                                          click: function($event) {
                                            return _vm.setView("RECURRING")
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              size: "20",
                                              color:
                                                _vm.currentView === "RECURRING"
                                                  ? "primary"
                                                  : "gray_70"
                                            }
                                          },
                                          [_vm._v("autorenew")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: { content: "Table" },
                                        expression: "{ content: 'Table' }"
                                      }
                                    ],
                                    staticClass:
                                      "pointer row-format align-center",
                                    on: {
                                      click: function($event) {
                                        return _vm.setView("TABLE")
                                      }
                                    }
                                  },
                                  [
                                    _c("h-icon", {
                                      attrs: {
                                        name: "project-table",
                                        size: "20",
                                        color:
                                          "var(--v-" +
                                          (_vm.currentView === "TABLE"
                                            ? "primary"
                                            : "gray_70") +
                                          "-base)"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: { content: "Kanban" },
                                        expression: "{ content: 'Kanban' }"
                                      }
                                    ],
                                    staticClass:
                                      "pointer row-format align-center",
                                    on: {
                                      click: function($event) {
                                        return _vm.setView("CARD")
                                      }
                                    }
                                  },
                                  [
                                    _c("h-icon", {
                                      attrs: {
                                        name: "project-kanban",
                                        size: "20",
                                        color:
                                          "var(--v-" +
                                          (_vm.currentView === "CARD"
                                            ? "primary"
                                            : "gray_70") +
                                          "-base)"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: { content: "Timeline" },
                                        expression: "{ content: 'Timeline' }"
                                      }
                                    ],
                                    staticClass:
                                      "pointer row-format align-center",
                                    on: {
                                      click: function($event) {
                                        return _vm.setView("GANTT")
                                      }
                                    }
                                  },
                                  [
                                    _c("h-icon", {
                                      attrs: {
                                        name: "project-timeline",
                                        size: "20",
                                        color:
                                          "var(--v-" +
                                          (_vm.currentView === "GANTT"
                                            ? "primary"
                                            : "gray_70") +
                                          "-base)"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: { content: "Calendar" },
                                        expression: "{ content: 'Calendar' }"
                                      }
                                    ],
                                    staticClass:
                                      "pointer row-format align-center",
                                    on: {
                                      click: function($event) {
                                        return _vm.setView("CALENDAR")
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "material-symbols-rounded",
                                        attrs: {
                                          size: "24",
                                          color:
                                            "var(--v-" +
                                            (_vm.currentView === "CALENDAR"
                                              ? "primary"
                                              : "gray_70") +
                                            "-base)"
                                        }
                                      },
                                      [_vm._v("event")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            !_vm.isSingleProjectMode
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "super-action",
                                        on: {
                                          click: function($event) {
                                            return _vm.addDeliverable()
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "20" } },
                                          [_vm._v("add")]
                                        ),
                                        _vm._v(" Add task")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    class:
                      (_vm.isSingleProjectMode
                        ? "body-content-project"
                        : "body-content") +
                      " " +
                      _vm.$store.getters.scroll,
                    attrs: { id: "project-container" }
                  },
                  [
                    _vm.currentView === "RECURRING" && _vm.project
                      ? _c("recurring-deliverables", {
                          ref: "Recurring",
                          attrs: { project: _vm.project }
                        })
                      : _vm._e(),
                    _vm.currentView === "CARD"
                      ? _c("project-kanban", {
                          ref: "kanban",
                          attrs: {
                            "current-view": _vm.currentView,
                            deliverables: _vm.deliverablesFiltered,
                            projects: _vm.projectsFiltered,
                            "update-flag": _vm.updateCounter,
                            filter: _vm.filter,
                            "is-collaborator": _vm.isUserCollaborator,
                            "header-height": _vm.headerHeight,
                            "single-project-mode": _vm.isSingleProjectMode,
                            "status-list": _vm.statusList,
                            "custom-fields": _vm.customFields,
                            "project-type-id": _vm.projectTypeId,
                            "project-type": _vm.projectType,
                            "visible-fields": _vm.visibleTaskFields
                          },
                          on: {
                            "add-deliverable": function($event) {
                              return _vm.addDeliverable($event)
                            },
                            "edit-deliverable": function($event) {
                              return _vm.editDeliverable($event)
                            },
                            "update-kanban-props": function($event) {
                              return _vm.updateKanbanProperties($event)
                            },
                            "add-subtask": function($event) {
                              return _vm.addDeliverableDefaults($event)
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.currentView === "TABLE"
                      ? _c("deliverable-list", {
                          ref: "projectList",
                          staticClass: "pb-6",
                          attrs: {
                            "current-view": _vm.currentView,
                            deliverables: _vm.deliverablesFiltered,
                            "all-projects": _vm.projects,
                            projects: _vm.projectsFiltered,
                            "single-project-mode": _vm.isSingleProjectMode,
                            "update-flag": _vm.updateCounter,
                            filter: _vm.filter,
                            "is-collaborator": _vm.isUserCollaborator,
                            "has-projects": _vm.hasProjects,
                            "header-height": _vm.headerHeight,
                            "status-list": _vm.statusList,
                            "custom-fields": _vm.customFields,
                            "project-type-id": _vm.projectTypeId,
                            "project-type": _vm.projectType,
                            "visible-fields": _vm.visibleTaskFields
                          },
                          on: {
                            "add-deliverable-defaults": function($event) {
                              return _vm.addDeliverableInline($event)
                            },
                            "pause-sorting": function($event) {
                              return _vm.pauseIfNewInlineItem($event)
                            },
                            "edit-deliverable": function($event) {
                              return _vm.editDeliverable($event)
                            },
                            "update-kanban-props": function($event) {
                              return _vm.updateKanbanProperties($event)
                            },
                            "add-project-deliverable": function($event) {
                              return _vm.addDeliverableForProject($event)
                            },
                            "edit-project": function($event) {
                              return _vm.editProject($event)
                            },
                            "confirm-delete": function($event) {
                              return _vm.confirmDeleteDeliverable($event, true)
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.currentView === "GANTT"
                      ? _c("gantt-view", {
                          attrs: {
                            visible: _vm.currentView === "GANTT",
                            "header-height": _vm.headerHeight,
                            deliverables: _vm.deliverablesFiltered,
                            "status-list": _vm.statusList,
                            "custom-fields": _vm.customFields,
                            "project-type-id": _vm.projectTypeId,
                            "project-type": _vm.projectType
                          },
                          on: {
                            "edit-deliverable": function($event) {
                              return _vm.editDeliverable($event)
                            },
                            "dates-updated": function($event) {
                              return _vm.datesUpdated($event)
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.currentView === "CALENDAR"
                      ? _c("project-calendar", {
                          attrs: {
                            projects: _vm.projectsFiltered,
                            deliverables: _vm.deliverablesFiltered,
                            "status-list": _vm.statusList,
                            "custom-fields": _vm.customFields,
                            "project-type-id": _vm.projectTypeId,
                            "project-type": _vm.projectType,
                            weekends: true
                          },
                          on: {
                            "edit-deliverable": function($event) {
                              return _vm.editDeliverable($event)
                            },
                            "update-due-date": function($event) {
                              return _vm.updateDueDate($event)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }