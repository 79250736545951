var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", [
        _c("div", { staticClass: "row-format align-start" }, [
          _c("div", { staticClass: "column-format" }, [
            _c("div", { staticClass: "font-gray_80 mb-1" }, [
              _vm._v(_vm._s(_vm.$t("connected-apps.xero.desc")))
            ]),
            _vm.paidAccount
              ? _c(
                  "div",
                  [
                    _vm.isConnected
                      ? _c("connected")
                      : _c(
                          "div",
                          {
                            staticClass: "pointer",
                            on: { click: _vm.initializeOAuth }
                          },
                          [
                            _c("img", {
                              attrs: {
                                alt: "xero-connect",
                                src: "/images/apps/xero-connect.svg"
                              }
                            })
                          ]
                        )
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "font-14 pointer font-primary",
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/subscription")
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "material-symbols-rounded" },
                        [_vm._v("lock")]
                      ),
                      _vm._v(
                        "This integration is not available on your plan. If you would like to connect your Xero account, please upgrade to Pro or Teams."
                      )
                    ],
                    1
                  )
                ])
          ]),
          _vm.isConnected && _vm.paidAccount
            ? _c(
                "div",
                {
                  staticClass: "ml-auto pointer",
                  on: {
                    click: function($event) {
                      _vm.expanded = !_vm.expanded
                    }
                  }
                },
                [
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.expanded ? "$chevronUp" : "$chevronDown"))
                  ])
                ],
                1
              )
            : _vm._e()
        ]),
        _vm.isConnected && _vm.expanded
          ? _c("div", { staticClass: "mt-5" }, [
              _c(
                "div",
                {
                  staticClass: "mb-6 font-14 py-2 px-2",
                  staticStyle: {
                    "background-color": "var(--v-white-base)",
                    "border-radius": "8px"
                  }
                },
                [
                  _c("div", { staticClass: "mb-2" }, [
                    _c("span", { staticClass: "brand-medium" }, [
                      _vm._v("Xero company:")
                    ]),
                    _vm._v(" " + _vm._s(_vm.xero.connection.tenantName))
                  ]),
                  _c("v-autocomplete", {
                    staticClass: "h-outline",
                    attrs: {
                      "hide-details": "",
                      "persistent-placeholder": "",
                      label: "Sales account",
                      items: _vm.salesAccounts,
                      "item-text": "name",
                      "item-value": "accountID",
                      "return-object": ""
                    },
                    on: { change: _vm.saveConfig },
                    model: {
                      value: _vm.xero.connection.salesAccount,
                      callback: function($$v) {
                        _vm.$set(_vm.xero.connection, "salesAccount", $$v)
                      },
                      expression: "xero.connection.salesAccount"
                    }
                  }),
                  _c("v-autocomplete", {
                    staticClass: "h-outline",
                    attrs: {
                      "hide-details": "",
                      "persistent-placeholder": "",
                      label: "Clearing account",
                      items: _vm.clearingAccounts,
                      "item-text": "name",
                      "item-value": "accountID",
                      "return-object": ""
                    },
                    on: { change: _vm.saveConfig },
                    model: {
                      value: _vm.xero.connection.clearingAccount,
                      callback: function($$v) {
                        _vm.$set(_vm.xero.connection, "clearingAccount", $$v)
                      },
                      expression: "xero.connection.clearingAccount"
                    }
                  }),
                  _c("v-autocomplete", {
                    staticClass: "h-outline",
                    attrs: {
                      "hide-details": "",
                      "persistent-placeholder": "",
                      label: "Default sales tax account",
                      items: _vm.taxRates,
                      "item-text": "name",
                      "item-value": "taxType",
                      "return-object": ""
                    },
                    on: { change: _vm.saveConfig },
                    model: {
                      value: _vm.xero.connection.salesTaxAccount,
                      callback: function($$v) {
                        _vm.$set(_vm.xero.connection, "salesTaxAccount", $$v)
                      },
                      expression: "xero.connection.salesTaxAccount"
                    }
                  }),
                  _c("v-autocomplete", {
                    staticClass: "h-outline",
                    attrs: {
                      "hide-details": "",
                      "persistent-placeholder": "",
                      label: "Exempt sales tax account",
                      items: _vm.taxRates,
                      "item-text": "name",
                      "item-value": "taxType",
                      "return-object": ""
                    },
                    on: { change: _vm.saveConfig },
                    model: {
                      value: _vm.xero.connection.exemptTaxAccount,
                      callback: function($$v) {
                        _vm.$set(_vm.xero.connection, "exemptTaxAccount", $$v)
                      },
                      expression: "xero.connection.exemptTaxAccount"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row-format align-center gap-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pointer",
                      on: { click: _vm.confirmDisconnect }
                    },
                    [
                      _c("img", {
                        attrs: {
                          alt: "disconnect-xero",
                          src: "/images/apps/xero-disconnect.svg"
                        }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pointer",
                      on: { click: _vm.initializeOAuth }
                    },
                    [
                      _c("img", {
                        attrs: {
                          alt: "xero-connect",
                          src: "/images/apps/xero-connect.svg"
                        }
                      })
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary-action mr-1",
                      attrs: { text: "" },
                      on: { click: _vm.confirmReset }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("lock_reset")
                      ]),
                      _vm._v("Reset integration")
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }