var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.filter
    ? _c(
        "div",
        { staticClass: "row-format gap-2" },
        [
          _c("v-text-field", {
            staticClass: "standard-input",
            style: "max-width: 200px;",
            attrs: {
              outlined: "",
              "hide-details": "",
              dense: "",
              placeholder: "Search..."
            },
            on: { input: _vm.updated },
            scopedSlots: _vm._u(
              [
                {
                  key: "prepend-inner",
                  fn: function() {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "material-symbols-rounded font-gray_50"
                        },
                        [_vm._v("search")]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              95475665
            ),
            model: {
              value: _vm.filter.search,
              callback: function($$v) {
                _vm.$set(_vm.filter, "search", $$v)
              },
              expression: "filter.search"
            }
          }),
          _c(
            "v-menu",
            {
              staticStyle: { "overflow-y": "auto" },
              attrs: {
                "max-height": "90vh",
                "max-width": "250",
                "nudge-bottom": "34",
                "close-on-content-click": false,
                "close-on-click": true,
                "content-class": "filter-menu-class"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "standard-action",
                              attrs: { elevation: "0", outlined: "", color: "" }
                            },
                            on
                          ),
                          [
                            _c("span", {
                              class:
                                "font-14 " +
                                (!_vm.isDefaultFilter ? "" : "font-gray_60") +
                                " brand-medium",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.isDefaultFilter
                                    ? _vm.filterLabel
                                    : _vm.filterDescription
                                )
                              }
                            }),
                            _c(
                              "v-icon",
                              {
                                staticClass: "mx-1",
                                attrs: {
                                  "x-small": "",
                                  color: !_vm.isDefaultFilter ? "" : "gray_80"
                                }
                              },
                              [_vm._v("$arrowDown")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                128726293
              ),
              model: {
                value: _vm.menuOpen,
                callback: function($$v) {
                  _vm.menuOpen = $$v
                },
                expression: "menuOpen"
              }
            },
            [
              _c(
                "div",
                { staticClass: "column-format open-menu" },
                [
                  _c("div", { staticClass: "filter-option-box" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row-format",
                        on: {
                          click: function($event) {
                            _vm.clientExpanded = !_vm.clientExpanded
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "font-14" }, [
                          _vm._v(_vm._s(_vm.$t("invoice.filter.client")))
                        ]),
                        _c(
                          "v-icon",
                          {
                            staticStyle: {
                              "margin-left": "auto",
                              "margin-top": "1px"
                            },
                            attrs: { small: "" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.clientExpanded ? "$arrowUp" : "$arrowDown"
                              )
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.clientExpanded
                      ? _c(
                          "div",
                          [
                            _c("v-checkbox", {
                              staticClass: "mx-0 my-2 pa-0",
                              attrs: {
                                "hide-details": "",
                                indeterminate: _vm.clientsIndeterminate
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "client-label row-format pointer",
                                            staticStyle: {
                                              "align-content": "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "tree-label" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "calendar.filter.select-deselect"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3960988798
                              ),
                              model: {
                                value: _vm.clientsSelected,
                                callback: function($$v) {
                                  _vm.clientsSelected = $$v
                                },
                                expression: "clientsSelected"
                              }
                            }),
                            _vm._l(_vm.clientList, function(client) {
                              return _c("v-checkbox", {
                                key: client.id,
                                staticClass: "mx-0 my-2 pa-0",
                                attrs: { value: client.id, "hide-details": "" },
                                on: {
                                  change: function($event) {
                                    return _vm.updated()
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "client-label row-format pointer",
                                              staticStyle: {
                                                "align-content": "center"
                                              }
                                            },
                                            [
                                              _c("client-avatar", {
                                                attrs: {
                                                  small: true,
                                                  left: "",
                                                  client: client,
                                                  "disable-click": true
                                                }
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "tree-label" },
                                                [_vm._v(_vm._s(client.name))]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.filter.clients,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filter, "clients", $$v)
                                  },
                                  expression: "filter.clients"
                                }
                              })
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]),
                  _c("filter-option", {
                    attrs: {
                      label: _vm.$t("client.filter.proposal-status"),
                      "option-list": _vm.proposalStatus
                    },
                    on: { change: _vm.updated },
                    model: {
                      value: _vm.filter.proposalStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.filter, "proposalStatus", $$v)
                      },
                      expression: "filter.proposalStatus"
                    }
                  }),
                  _c("filter-option", {
                    attrs: {
                      label: _vm.$t("client.filter.proposal-type"),
                      "option-list": _vm.proposalType
                    },
                    on: { change: _vm.updated },
                    model: {
                      value: _vm.filter.proposalType,
                      callback: function($$v) {
                        _vm.$set(_vm.filter, "proposalType", $$v)
                      },
                      expression: "filter.proposalType"
                    }
                  }),
                  _vm.showDateRange
                    ? _c("div", { staticClass: "filter-option-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "row-format",
                            on: {
                              click: function($event) {
                                _vm.dateExpanded = !_vm.dateExpanded
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "font-14" }, [
                              _vm._v(_vm._s(_vm.$t("client.filter.date-range")))
                            ]),
                            _c(
                              "v-icon",
                              {
                                staticStyle: {
                                  "margin-left": "auto",
                                  "margin-top": "1px"
                                },
                                attrs: { small: "" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.dateExpanded ? "$arrowUp" : "$arrowDown"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.dateExpanded
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    on: {
                                      change: function($event) {
                                        return _vm.updated()
                                      }
                                    },
                                    model: {
                                      value: _vm.filter.dateSpecifier,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filter,
                                          "dateSpecifier",
                                          $$v
                                        )
                                      },
                                      expression: "filter.dateSpecifier"
                                    }
                                  },
                                  _vm._l(_vm.dateOptions, function(option) {
                                    return _c("v-radio", {
                                      key: option.value,
                                      attrs: { value: option.value },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-14 brand-semilight",
                                                    staticStyle: {
                                                      "font-size": "14px"
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(option.text))]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  }),
                                  1
                                ),
                                _vm.showEarliest
                                  ? _c(
                                      "div",
                                      {},
                                      [
                                        _c("date-selector", {
                                          attrs: {
                                            "content-class": "my-5",
                                            date: _vm.filter.earliest,
                                            label: "Earliest"
                                          },
                                          on: {
                                            change: function($event) {
                                              _vm.filter.earliest = $event
                                              _vm.updated()
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showLatest
                                  ? _c(
                                      "div",
                                      {},
                                      [
                                        _c("date-selector", {
                                          attrs: {
                                            "content-class": "my-5",
                                            date: _vm.filter.latest,
                                            label: "Latest"
                                          },
                                          on: {
                                            change: function($event) {
                                              _vm.filter.latest = $event
                                              _vm.updated()
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mx-3 mt-3 mb-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "filter-clear mb-2",
                          attrs: {
                            text: "",
                            block: "",
                            color: "secondary",
                            elevation: "0"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clearFilter()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("$clear")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("timetrack.filter.clear-filter"))
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "super-action",
                          attrs: {
                            color: "primary",
                            block: "",
                            elevation: "0"
                          },
                          on: {
                            click: function($event) {
                              _vm.menuOpen = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("global.done")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }