var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { position: "relative" },
      attrs: { id: "comment-wrapper-" + _vm.uuid }
    },
    [
      _c("v-textarea", {
        style:
          "font-size: 14px; " +
          (_vm.privateComment
            ? "background-color: var(--v-yellow_10-base);"
            : ""),
        attrs: {
          id: "comment-" + _vm.uuid,
          dense: "",
          "hide-details": "",
          rows: "1",
          "auto-grow": "",
          outlined: "",
          placeholder: _vm.$t("projects.comment-placeholder"),
          spellcheck: true
        },
        on: {
          focus: function($event) {
            _vm.focusIn = true
          },
          blur: function($event) {
            _vm.focusIn = false
          },
          keydown: _vm.handleKeydown
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "row-format gap-2 align-center" },
                  [
                    _c(
                      "v-icon",
                      {
                        directives: [{ name: "tippy", rawName: "v-tippy" }],
                        staticClass: "pointer material-symbols-rounded",
                        attrs: {
                          content: _vm.privateComment
                            ? "Private comment"
                            : "Public comment",
                          color: "gray_70"
                        },
                        on: {
                          click: function($event) {
                            _vm.privateComment = !_vm.privateComment
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.privateComment ? "visibility_off" : "visibility"
                          )
                        )
                      ]
                    ),
                    !_vm.commentToEdit
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "pointer material-symbols-rounded",
                            attrs: { color: "gray_70" },
                            nativeOn: {
                              click: function($event) {
                                _vm.newComment && _vm.newComment.length
                                  ? _vm.sendComment()
                                  : null
                              }
                            }
                          },
                          [_vm._v("comment ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.newComment,
          callback: function($$v) {
            _vm.newComment = $$v
          },
          expression: "newComment"
        }
      }),
      _vm.commentToEdit
        ? _c(
            "div",
            { staticClass: "row-format mt-2" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secondary-action mr-1",
                  on: {
                    click: function($event) {
                      return _vm.cancelEdit()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "primary-action ml-1",
                  staticStyle: { "min-width": "100px" },
                  on: {
                    click: function($event) {
                      return _vm.saveEdit()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }