var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-2",
      staticStyle: {
        "background-color": "var(--v-white-base)",
        "max-width": "220px"
      }
    },
    [
      _c(
        "div",
        { staticClass: "row-format gap-3 flex-wrap" },
        _vm._l(_vm.pages, function(page) {
          return _c(
            "div",
            {
              key: page,
              class:
                "page-link " +
                (_vm.currentPage === page ? "page-link-active" : ""),
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.setView(page)
                }
              }
            },
            [_c("div", [_vm._v(_vm._s(page))])]
          )
        }),
        0
      ),
      _vm.currentPage === "Shift dates"
        ? _c(
            "div",
            { staticClass: "row-format py-2 gap-2" },
            [
              _c("v-text-field", {
                attrs: {
                  type: "numeric",
                  "hide-details": "",
                  dense: "",
                  outlined: ""
                },
                model: {
                  value: _vm.increment,
                  callback: function($$v) {
                    _vm.increment = $$v
                  },
                  expression: "increment"
                }
              }),
              _c("v-select", {
                attrs: {
                  items: ["Days", "Weeks", "Months"],
                  "hide-details": "",
                  dense: "",
                  outlined: ""
                },
                model: {
                  value: _vm.period,
                  callback: function($$v) {
                    _vm.period = $$v
                  },
                  expression: "period"
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "row-format py-2 gap-2" },
            [
              _c("date-selector", {
                attrs: {
                  standard: true,
                  date: _vm.currentDate,
                  "show-tip": false
                },
                on: {
                  change: function($event) {
                    _vm.currentDate = $event
                  }
                }
              })
            ],
            1
          ),
      _c(
        "v-btn",
        {
          staticClass: "mt-2",
          attrs: { block: "", small: "", color: "primary" },
          on: { click: _vm.updateDate }
        },
        [_vm._v("Update")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }