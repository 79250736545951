var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-4" },
    [
      _c("v-autocomplete", {
        attrs: {
          disabled: _vm.emailTemplates.length === 0,
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Confirmation email template",
          items: _vm.emailTemplates,
          "item-value": "id",
          "item-text": "name",
          clearable: ""
        },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.confirmationEmailTemplate,
          callback: function($$v) {
            _vm.confirmationEmailTemplate = $$v
          },
          expression: "confirmationEmailTemplate"
        }
      }),
      _c("v-select", {
        attrs: {
          disabled: _vm.pipelineStages.length === 0,
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Add submission to pipeline",
          items: _vm.pipelineStages,
          "item-value": "id",
          "item-text": "label",
          clearable: ""
        },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", {
                  style:
                    "width: 20px; height: 20px; border-radius: 4px; background-color: " +
                    item.hexColor
                }),
                _c("div", { staticClass: "ml-2" }, [_vm._v(_vm._s(item.label))])
              ]
            }
          },
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", {
                  style:
                    "width: 20px; height: 20px; border-radius: 4px; background-color: " +
                    item.hexColor
                }),
                _c("div", { staticClass: "ml-2" }, [_vm._v(_vm._s(item.label))])
              ]
            }
          }
        ]),
        model: {
          value: _vm.pipelineStageId,
          callback: function($$v) {
            _vm.pipelineStageId = $$v
          },
          expression: "pipelineStageId"
        }
      }),
      _c("v-select", {
        attrs: {
          disabled: _vm.ticketTypes.length === 0,
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Create ticket from public form",
          items: _vm.ticketTypes,
          "item-value": "id",
          "item-text": "type",
          clearable: ""
        },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.ticketTypeId,
          callback: function($$v) {
            _vm.ticketTypeId = $$v
          },
          expression: "ticketTypeId"
        }
      }),
      _c("v-text-field", {
        attrs: {
          label: "Redirect URL (after submit)",
          placeholder: "https://www.yourwebsite.com/confirmation",
          "persistent-placeholder": "",
          "hide-details": "",
          dense: "",
          outlined: ""
        },
        on: {
          input: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.confirmationRedirect,
          callback: function($$v) {
            _vm.confirmationRedirect = $$v
          },
          expression: "confirmationRedirect"
        }
      }),
      _c(
        "div",
        { style: "font-family: " + _vm.template.font },
        [
          _c("editor", {
            ref: "textEditor",
            attrs: {
              "api-key": _vm.$store.getters.getTinyMceKey,
              "initial-value": _vm.confirmationText,
              inline: false,
              init: _vm.mceConfigText,
              spellcheck: true
            },
            on: { input: _vm.handleTextInput }
          })
        ],
        1
      ),
      _c("v-switch", {
        directives: [
          {
            name: "tippy",
            rawName: "v-tippy",
            value: {
              content:
                "If your form is gathering sensitive information, you can disable this to prevent Moxie from sending the answers via email"
            },
            expression:
              "{content: 'If your form is gathering sensitive information, you can disable this to prevent Moxie from sending the answers via email'}"
          }
        ],
        staticClass: "mt-n2",
        attrs: {
          label: "Send results in notification email",
          "hide-details": "",
          dense: ""
        },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.sendResultsInEmail,
          callback: function($$v) {
            _vm.sendResultsInEmail = $$v
          },
          expression: "sendResultsInEmail"
        }
      }),
      _c("v-switch", {
        directives: [
          {
            name: "tippy",
            rawName: "v-tippy",
            value: {
              content:
                "Determines whether the form is currently accessible via the public link."
            },
            expression:
              "{content: 'Determines whether the form is currently accessible via the public link.'}"
          }
        ],
        staticClass: "mt-n2",
        attrs: { "hide-details": "", dense: "", label: "Link active" },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.active,
          callback: function($$v) {
            _vm.active = $$v
          },
          expression: "active"
        }
      }),
      _c("v-switch", {
        directives: [
          {
            name: "tippy",
            rawName: "v-tippy",
            value: {
              content:
                "Require Google ReCaptcha when embedding form on external websites."
            },
            expression:
              "{content: 'Require Google ReCaptcha when embedding form on external websites.'}"
          }
        ],
        staticClass: "mt-n2",
        attrs: { "hide-details": "", dense: "", label: "Embedded ReCaptcha" },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.reCaptchaEnabled,
          callback: function($$v) {
            _vm.reCaptchaEnabled = $$v
          },
          expression: "reCaptchaEnabled"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }