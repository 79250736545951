var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Project management" }
            ],
            compact: true
          }
        },
        [
          _c(
            "div",
            { staticClass: "row-format gap-2" },
            [
              _c("div", { staticStyle: { "font-weight": "400" } }, [
                _vm._v("Tasks")
              ]),
              _c("div", { staticStyle: { "font-weight": "400" } }, [
                _vm._v("/")
              ]),
              _c(
                "v-menu",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "div",
                            _vm._g(
                              {
                                directives: [
                                  {
                                    name: "tippy",
                                    rawName: "v-tippy",
                                    value: { content: "Select project type" },
                                    expression:
                                      "{content: 'Select project type'}"
                                  }
                                ],
                                staticClass: "pointer font-primary"
                              },
                              on
                            ),
                            [_vm._v(_vm._s(_vm.currentProjectType))]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    { staticClass: "more-menu" },
                    _vm._l(_vm.projectTypes, function(projectType) {
                      return _c(
                        "div",
                        {
                          key: projectType.id,
                          staticClass: "more-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.setProjectType(projectType)
                            }
                          }
                        },
                        [_vm._v(_vm._s(projectType.name))]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "column-format fill-height pb-0 px-0" },
        [
          _vm.isReady
            ? _c("project-management", {
                attrs: { "project-type-id": _vm.projectTypeId }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }