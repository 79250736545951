var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format", attrs: { id: "project-filter" } },
    [
      _c(
        "v-menu",
        {
          staticStyle: { "overflow-y": "auto" },
          attrs: {
            "max-height": "90vh",
            "max-width": "250",
            "nudge-bottom": "34",
            "close-on-content-click": false,
            "close-on-click": true,
            "content-class": "filter-menu-class"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "standard-action",
                        attrs: { elevation: "0", outlined: "", color: "" }
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          class:
                            "font-14 " +
                            (!_vm.isDefaultFilter ? "" : "font-gray_60") +
                            " brand-medium"
                        },
                        [_vm._v(_vm._s(_vm.filterLabel))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: !_vm.isDefaultFilter ? "" : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c("div", { staticClass: "column-format" }, [
            _c("div", { staticClass: "filter-option-box" }, [
              _c(
                "div",
                [
                  _c(
                    "v-radio-group",
                    {
                      model: {
                        value: _vm.filter.dateRange,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "dateRange", $$v)
                        },
                        expression: "filter.dateRange"
                      }
                    },
                    _vm._l(_vm.dateOptions, function(option) {
                      return _c("v-radio", {
                        key: option.value,
                        attrs: { value: option.value },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "font-14 brand-semilight",
                                      staticStyle: { "font-size": "14px" }
                                    },
                                    [_vm._v(_vm._s(option.text))]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    1
                  ),
                  _vm.showEarliest
                    ? _c(
                        "div",
                        {},
                        [
                          _c("date-selector", {
                            attrs: {
                              "content-class": "my-5",
                              date: _vm.filter.earliest,
                              label: "Earliest"
                            },
                            on: {
                              change: function($event) {
                                _vm.filter.earliest = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showLatest
                    ? _c(
                        "div",
                        {},
                        [
                          _c("date-selector", {
                            attrs: {
                              "content-class": "my-5",
                              date: _vm.filter.latest,
                              label: "Latest"
                            },
                            on: {
                              change: function($event) {
                                _vm.filter.latest = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "mx-3 mt-3 mb-2" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "filter-clear mb-2",
                    attrs: {
                      text: "",
                      block: "",
                      color: "secondary",
                      elevation: "0"
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("$clear")]),
                    _vm._v(" " + _vm._s(_vm.$t("projects.filter.clear-filter")))
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        _vm.menu = false
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("global.done")))]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }