var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "nudge-bottom": 34,
            "max-width": "200",
            "close-on-content-click": false,
            "content-class": "filter-menu-class",
            transition: "slide-y-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "standard-action",
                        attrs: { elevation: "0", outlined: "", color: "" }
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          class:
                            "font-14 " +
                            (_vm.isFilterActive ? "" : "font-gray_60") +
                            " brand-medium"
                        },
                        [_vm._v(_vm._s(_vm.filterLabel))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: _vm.isFilterActive ? "" : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _c("div", { staticClass: "column-format" }, [
            _c("div", { staticClass: "filter-option-box" }, [
              _c("div", { staticClass: "row-format" }, [
                _c("div", { staticClass: "font-14" }, [_vm._v("Status")])
              ]),
              _c(
                "div",
                { staticClass: "my-2" },
                [
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "small-radio",
                      attrs: { "hide-details": "", dense: "" },
                      on: { change: _vm.updated },
                      model: {
                        value: _vm.filter.status,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "status", $$v)
                        },
                        expression: "filter.status"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: { label: "Active", value: "active" }
                      }),
                      _c("v-radio", {
                        attrs: { label: "Archived", value: "archive" }
                      }),
                      _c("v-radio", {
                        attrs: { label: "Favorites", value: "favorite" }
                      }),
                      _c("v-radio", { attrs: { label: "All", value: "all" } })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "filter-option-box" }, [
              _c("div", { staticClass: "row-format" }, [
                _c("div", { staticClass: "font-14" }, [_vm._v("Record type")])
              ]),
              _c(
                "div",
                { staticClass: "my-2" },
                [
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "small-radio",
                      attrs: { "hide-details": "", dense: "" },
                      on: { change: _vm.updated },
                      model: {
                        value: _vm.filter.clientType,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "clientType", $$v)
                        },
                        expression: "filter.clientType"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: { label: "Client", value: "Client" }
                      }),
                      _c("v-radio", {
                        attrs: { label: "Prospect", value: "Prospect" }
                      }),
                      _c("v-radio", { attrs: { label: "All", value: "all" } })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "mx-3 mb-3" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-2",
                    staticStyle: { "font-weight": "500" },
                    attrs: {
                      text: "",
                      block: "",
                      color: "secondary",
                      elevation: "0"
                    },
                    on: {
                      click: function($event) {
                        return _vm.clearFilter()
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("$clear")]),
                    _vm._v(" Clear Filters")
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action mt-1",
                    attrs: { color: "primary", block: "" },
                    on: {
                      click: function($event) {
                        _vm.showMenu = false
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("global.done")))]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }